import GooglePMaxSlideOutPanel from "../../features/google-pmax-campaign/GooglePMaxSlideOutPanel";
import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import { Box, Chip, Snackbar, Alert } from "@mui/material";
import PMaxChartContainer from "features/google-pmax-campaign/charts-and-table/chartContainer";
import TableContainer from "features/google-pmax-campaign/charts-and-table/TableContainer";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "store/slices/pMax/pMax";
import {
  updateLevel,
  updateHotelId,
  updateGroupId,
} from "store/slices/account/accountSlice";
import axios from "axios";
import HotelsFilterMenu from "components/hotelFilterMenu";
import {
  APP_GOOGLE_PMAX_API_ENDPOINT,
  APP_HOTELS_API_ENDPOINT,
} from "constants/api_urls";
import { useNavigate, useParams } from "react-router-dom";
import CampaignFilterMenu from "components/CampaignFilterMenu";
import PageTitles from "../../components/PageTitles";
import HoverButton from "../../components/HoverButton";
import {getUserToken} from "../../utils/functions/userToken";

const PMaxCampaignManager = () => {
  const token = getUserToken() || "";
  const platform = useSelector((state) => state?.pMax?.campaign);
  const hotelId = useSelector((state) => state?.account?.hotel_id);
    const {
        account_id = "",
        ["is_account_writable"]: isAccountWritable,
    } = useSelector((state) => state?.account?.account || {});
  const hotelButtonRef = useRef(null);
  const campaignButtonRef = useRef(null);
  const [openCampaignModal, setOpenCampaignModal] = useState(false);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [hotelFilters, setHotelFilters] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [campaignFilters, setCampaignFilters] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [editCampaignId, setEditCampaignId] = useState();
  const { idaccount } = useParams();
  const [currentAccountId,setCurrentAccountId] = useState(account_id);

  const handleCreateCampaign = () => {
    if (hotelId?.length ===1) {
     return navigate(`/pmax-campaign-manager/create/${idaccount}/${hotelId?.[0]}`);
    } else {
      setToastMessage("Please select one hotel.");
      setToastType("error");
      setShowToast(true);
    }
  };

  const triggerAlert = (type, msg) => {
    setToastType(type);
    setToastMessage(msg);
    setShowToast(true);
  };

  const togglePanel = () => {
    setPanelOpen((prev) => !prev);
  };

  useEffect(() => {
        if(account_id && hotelList.length && campaignList.length){
          setCurrentAccountId(prev => {
            if(prev !== account_id){
              dispatch(updateGroupId([]));
              dispatch(updateHotelId(hotelList.map(h => h?.hotel_id)));
              dispatch(updateCampaign(campaignList));
            }
            return account_id;
          })
        }
      }, [account_id, hotelList,campaignList]);

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      axios
        .get(
          `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/filters/campaign-list/?account_id=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const campaigns = res?.data?.campaign_list?.map((campaign) => ({
            id: campaign?.campaign_id,
            label: campaign?.campaign_name,
          }));

          setCampaignList(campaigns || []);
        });

      axios
        .get(
          `${APP_HOTELS_API_ENDPOINT}/api/hotels/list?id_account=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setHotelList(res?.data?.data || []);
          if (res?.data?.data.length > 0&& !hotelId.length) {
            dispatch(
              updateHotelId(res?.data?.data.map((h) => h?.["hotel_id"]))
            );
          }
        });
    }
  }, [account_id]);

  useEffect(() => {
    dispatch(
      updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
    );
  }, []);

  return (
    <Box className="metasearch">
      <DefaultLayout page={isPanelOpen ? null : "PMax"}>
        <Box
          sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "18px",
          }}
        >
          <PageTitles pageTitle="PMax" pageSubTitle="" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "8px",
                }}
            >
                {isAccountWritable &&(
                    <HoverButton text="New Campaign" onClick={handleCreateCampaign}/>
                )}

                <DatePicker />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: 2,
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      hotelId.length === 0
                        ? "Hotels"
                        : (selectedHotelOrGroupType === "hotels" &&
                            hotelId.length === hotelList.length) ||
                          (selectedHotelOrGroupType === "groups" &&
                            hotelId.length === groupList.length)
                        ? selectedHotelOrGroupType === "hotels"
                          ? "All Hotels"
                          : "All Groups"
                        : hotelId.length === 1
                        ? hotelList?.find(
                            (hotel) =>
                              hotel.hotel_id === hotelId?.[0]
                          )?.hotel_name
                        : `${hotelId.length} ${
                            selectedHotelOrGroupType === "hotels"
                              ? "hotels"
                              : "groups"
                          } selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      cursor: "pointer",
                      height: "26px",
                      minWidth:"120px",
                      //border: hotelId.length > 0 ? "1px solid #BAB7B7" : "1px solid grey",
                      border:"1px solid #BAB7B7",
                        ":hover": {
                            cursor: "pointer",
                            border: "1px solid #000000",
                        },
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenCampaignModal(true);
                  }}
                  ref={campaignButtonRef}
                >
                  <Chip
                    label={
                      platform.length === 0
                        ? "Campaigns"
                        : platform.length === 1
                        ? platform[0].label
                        : platform.length === campaignList.length
                        ? "All Campaigns"
                        : `${platform.length} campaigns selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "26px",
                      minWidth:"120px",
                      cursor: "pointer",
                      //border: platform.length > 0 ? "1px solid #BAB7B7" : "1px solid grey",
                      border:"1px solid #BAB7B7",
                        ":hover": {
                            cursor: "pointer",
                            border: "1px solid #000000",
                        },
                    }}
                  />
                </div>
                {openCampaignModal && (
                  <CampaignFilterMenu
                    anchorEl={campaignButtonRef.current}
                    open={openCampaignModal}
                    options={campaignList?.map((po) =>
                      platform.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    filterType={"Campaigns"}
                    selectAllTitle={"Campaigns"}
                    onApply={(selected) => {
                      setCampaignFilters(selected);
                      dispatch(updateCampaign(selected));
                    }}
                    onClose={() => {
                      setOpenCampaignModal(false);
                    }}
                  />
                )}
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotelId.find((hf) => hf === ho.hotel_id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    showGroups={false}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    isSelectAllRequired
                    onApply={(selected) => {

                        setHotelFilters(selected);
                        if (selectedHotelOrGroupType === "hotels") {
                          dispatch(
                            updateHotelId(
                              selected.map((item) => item?.["hotel_id"])
                            )
                          );
                        } else {
                          dispatch(
                            updateGroupId(selected.map((item) => item?.["id"]))
                          );
                        }

                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                      dispatch(
                        updateLevel(type === "hotels" ? "HOTEL" : "GROUP")
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
        <Box sx={{}} ref={scrollContainerRef}>
          <div
            style={{
                height: "228px",
              display: isChartCollapsed ? "none" : "block",
            }}
          >
            <PMaxChartContainer backgroundColor="transparent" />
          </div>
          <TableContainer
            hotelList={hotelList}
            isAccountWritable={isAccountWritable}
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
                return;
              }
            }}
            onEditCampaign={(id) => {
              setEditCampaignId(id);
              setPanelOpen(true);
            }}
          />
        </Box>{" "}
        <GooglePMaxSlideOutPanel
          isOpen={isPanelOpen}
          onClose={togglePanel}
          idAccount={idaccount}
          idHotel={hotelFilters?.[0]?.hotel_id}
          idCampaign={editCampaignId}
          triggerAlert={triggerAlert}
        />
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default PMaxCampaignManager;
