import React, {useState} from "react";
import {Button, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {styled} from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#61D5A9",
    color: "#fff",
    borderRadius: "50%",
    width: "auto",
    height: "40px",
    transition: "all 0.1s ease",
    overflow: "hidden",
    textTransform: "none",
    boxShadow: "none",
    minWidth: "40px !important",
    "&:hover": {
        backgroundColor: "#61D5A9",
        borderRadius: "20px",
    },
}));

const HoverButton = ({text, onClick}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <StyledButton
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
                justifyContent: hovered ? "flex-start" : "center",
                padding: hovered ? "0 10px" : "0",
            }}
            title={text}
            onClick={onClick}
        >
            {/* Icon */}
            <AddIcon
                sx={{
                    transition: "margin-right 0.3s ease",
                    marginRight: hovered ? "10px" : "0",
                    color: '#373F41',
                }}
            />
            {/* Text */}
            <Typography
                variant="body2"
                sx={{
                    transition: "opacity 0.3s ease",
                    display: hovered ? "block" : "none",
                    whiteSpace: "nowrap",
                    color: '#373F41',
                    marginTop: '2px'
                }}
            >
                {text}
            </Typography>
        </StyledButton>
    );
};

export default HoverButton;
