import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../../constants/api_urls";
import {getUserToken} from "../../../../../utils/functions/userToken";

export const validateImage = async (category, url) => {
    switch (category) {
        case 'landscape':
            return await validateLandscapeImg(url);
        case 'square':
            return await validateSquareImg(url);
        case 'portrait':
            return await validatePortraitImg(url);
        case 'logo':
            return await validateLogoImg(url);
        case 'landscapeLogo':
            return await validateLandscapeLogoImg(url);
        default:
            return { success: false, error: 'Unknown category' };
    }
};

export const validateLandscapeImg = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-marketing-image/`,
            { assetContent: assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateSquareImg = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-square-marketing-image/`,
            { assetContent: assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validatePortraitImg = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-portrait-marketing-image/`,
            { assetContent: assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateLogoImg = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-logo-image/`,
            { assetContent: assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateLandscapeLogoImg = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-landscape-logo-image/`,
            { assetContent: assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};