import React from "react";
import { Navigate } from "react-router-dom";
import { getUserToken } from "./utils/functions/userToken";

const PrivateRoute = ({ element }) => {
    const isAuthenticated = !!getUserToken();

    return isAuthenticated ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;