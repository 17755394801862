import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import { getUserToken } from "../../utils/functions/userToken";
import axios from "axios";
import { APP_ACCOUNT_API_ENDPOINT } from "../../constants/api_urls";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAccount } from "../../store/slices/account/accountSlice";

const TransitionPage = () => {
    const jwtToken = getUserToken();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        axios
            .get(`${APP_ACCOUNT_API_ENDPOINT}/api/accounts/list`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            })
            .then((res) => {
                setAccounts(res.data.data || []);
            })
            .catch((err) => {
                console.error("Error fetching accounts:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [jwtToken]);

    useEffect(() => {
        if (!loading && accounts.length > 0) {
            const favoriteAccount =
                accounts.find((account) => account.is_favorite) ||
                accounts[accounts.length - 1];
            dispatch(updateAccount(favoriteAccount));
            navigate(`/home/${favoriteAccount.account_id}`);
        }
    }, [accounts, loading, navigate, dispatch]);

    return (
        <DefaultLayout>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: "18px" }}>
                <PageTitles pageTitle="Digital Marketing Platform" />
            </Box>

            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        color: "white"
                    }}
                >
                    <CircularProgress style={{ color: "#61D5A9" }} />
                </Box>
            )}

            {!loading && accounts.length === 0 && (
                <Typography sx={{ mt: 4, textAlign: "center" }}>
                    No account available, please contact support.
                </Typography>
            )}

            {!loading && accounts.length > 0 && (
                <Typography sx={{ mt: 4, textAlign: "center" }}>
                    Accounts Loaded Successfully
                </Typography>
            )}
        </DefaultLayout>
    );
};

export default TransitionPage;
