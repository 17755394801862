import "./keywordsReporting.scss";
import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import { Box, Chip, Snackbar, Alert } from "@mui/material";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "store/slices/searchAds/searchAds";
import { updateGroupId, updateHotelId, updateLevel } from "store/slices/account/accountSlice";
import axios from "axios";
import HotelsFilterMenu from "components/hotelFilterMenu";
import {
  APP_GOOGLE_SEM_API_ENDPOINT,
  APP_HOTELS_API_ENDPOINT,
} from "constants/api_urls";
import { useNavigate } from "react-router-dom";
import EditCampaignPanel from "features/google-search-ads-campaign/EditCampaignPanel";
import CampaignFilterMenu from "components/CampaignFilterMenu";
import PageTitles from "../../components/PageTitles";
import KeywordsChartContainer from "features/searchads/keywordReporting/chartContainer";
import KeywordsTableContainer from "features/searchads/keywordReporting/TableContainer";
import HoverButton from "../../components/HoverButton";
import {getUserToken} from "../../utils/functions/userToken";

const KeywordsReportingPage = () => {
  const token = getUserToken() || "";
  const platform = useSelector((state) => state?.searchAds?.campaign);
  const hotelId = useSelector((state) => state?.account?.hotel_id);
    const {
        account_id = "",
        ["is_account_writable"]: isAccountWritable,
    } = useSelector((state) => state?.account?.account || {});
  const hotelButtonRef = useRef(null);
  const campaignButtonRef = useRef(null);
  const [openCampaignModal, setOpenCampaignModal] = useState(false);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [hotelFilters, setHotelFilters] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [campaignFilters, setCampaignFilters] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [editCampaignId, setEditCampaignId] = useState();
  const [currentAccountId,setCurrentAccountId] = useState(account_id);

  const handleCreateCampaign = () => {
    if (hotelFilters.length===1) {
      navigate(`/search-ads-campaign/create/${account_id}/${hotelId?.[0]}`);
    } else {
      setToastMessage("Please select one hotel.");
      setToastType("error");
      setShowToast(true);
    }
  };

  const togglePanel = () => {
    setPanelOpen((prev) => !prev);
  };

  useEffect(() => {
          if(account_id && hotelList.length && campaignList.length){
            setCurrentAccountId(prev => {
              if(prev !== account_id){
                dispatch(updateGroupId([]));
                dispatch(updateHotelId(hotelList.map(h => h?.hotel_id)));
                dispatch(updateCampaign(campaignList));
              }
              return account_id;
            })
          }
        }, [account_id, hotelList,campaignList]);

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      axios
        .get(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/filters/campaign-list/?account_id=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const campaigns = res?.data?.campaign_list?.map((campaign) => ({
            id: campaign?.campaign_id,
            label: campaign?.campaign_name,
          }));

          setCampaignList(campaigns || []);
        });

      axios
        .get(
          `${APP_HOTELS_API_ENDPOINT}/api/hotels/list?id_account=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setHotelList(res?.data?.data || []);
          if (res?.data?.data.length > 0&& !hotelId.length) {
            dispatch(
              updateHotelId(
                res?.data?.data.map((h) => h?.["hotel_id"])
              )
            );
          }
        });

      // axios
      //   .get(
      //     `${APP_HOTELS_API_ENDPOINT}/api/group/list?id_account=${account_id}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     setGroupList(res?.data?.group_list || []);
      //   });
    }
  }, [account_id]);

  useEffect(() => {
    dispatch(
      updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
    );
  }, []);

  return (
    <div className="metasearch">
      <DefaultLayout page={isPanelOpen ? null : "SearchAdsPage"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "18px",
          }}
        >
          <PageTitles pageTitle="Search Ads" pageSubTitle="Keywords" />
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
              {isAccountWritable &&(
                  <HoverButton text="New Campaign" onClick={handleCreateCampaign}/>
              )}
              <DatePicker />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: 2,
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      hotelId.length === 0
                        ? "Hotels"
                        : (selectedHotelOrGroupType === "hotels" &&
                            hotelId.length === hotelList.length) ||
                          (selectedHotelOrGroupType === "groups" &&
                            hotelId.length === groupList.length)
                        ? selectedHotelOrGroupType === "hotels"
                          ? "All Hotels"
                          : "All Groups"
                        : hotelId.length === 1
                        ? hotelList?.find(
                            (hotel) =>
                              hotel.hotel_id === hotelId?.[0]
                          )?.hotel_name
                        : `${hotelId.length} ${
                            selectedHotelOrGroupType === "hotels"
                              ? "hotels"
                              : "groups"
                          } selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      cursor: "pointer",
                      height: "26px",
                      minWidth:"120px",
                      //border: hotelId.length > 0 ? "1px solid #BAB7B7" : "1px solid grey",
                      border:"1px solid #BAB7B7",
                        ":hover": {
                            cursor: "pointer",
                            border: "1px solid #000000",
                        },
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenCampaignModal(true);
                  }}
                  ref={campaignButtonRef}
                >
                  <Chip
                    label={
                      platform.length === 0
                        ? "Campaigns"
                        : platform.length === 1
                        ? platform[0].label
                        : platform.length === campaignList.length
                        ? "All Campaigns"
                        : `${platform.length} campaigns selected`
                    }
                    sx={{
                      backgroundColor: "inherit",
                      fontFamily: "inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "26px",
                      minWidth:"120px",
                      cursor: "pointer",
                      // border: platform.length > 0 ? "1px solid #BAB7B7" : "1px solid grey",
                      border:"1px solid #BAB7B7",
                        ":hover": {
                            cursor: "pointer",
                            border: "1px solid #000000",
                        },
                    }}
                  />
                </div>
                {openCampaignModal && (
                  <CampaignFilterMenu
                    anchorEl={campaignButtonRef.current}
                    open={openCampaignModal}
                    options={campaignList?.map((po) =>
                      platform.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    filterType={"Campaigns"}
                    selectAllTitle={"Campaigns"}
                    onApply={(selected) => {
                      setCampaignFilters(selected);
                      dispatch(updateCampaign(selected));
                    }}
                    onClose={() => {
                      setOpenCampaignModal(false);
                    }}
                  />
                )}
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotelId.find((hf) => hf === ho.hotel_id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    showGroups={false}
                    isSelectAllRequired
                    onApply={(selected) => {

                        setHotelFilters(selected);
                        if (selectedHotelOrGroupType === "hotels") {
                          dispatch(
                            updateHotelId(
                              selected.map((item) => item?.["hotel_id"])
                            )
                          );
                        } else {
                          dispatch(
                            updateGroupId(selected.map((item) => item?.["id"]))
                          );
                        }

                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                      dispatch(
                        updateLevel(type === "hotels" ? "HOTEL" : "GROUP")
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
        <Box sx={{}} ref={scrollContainerRef}>
          <div
            style={{
              display: isChartCollapsed ? "none" : "block",
            }}
          >
            <KeywordsChartContainer backgroundColor="transparent" />
          </div>
          <KeywordsTableContainer
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            isAccountWritable={isAccountWritable}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
                return;
              }
            }}
            onEditCampaign={(id) => {
              setEditCampaignId(id);
              setPanelOpen(true);
            }}
          />
        </Box>{" "}
        <EditCampaignPanel campaignId={editCampaignId} isOpen={isPanelOpen} onClose={togglePanel} />
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default KeywordsReportingPage;
