import { Grid, Tooltip } from "@mui/material";
import Table from "components/Table";
import GoogleAds from "assets/images/GoogleHotelAds.svg";
import TripAdvisor from "assets/images/TripAdvisor.svg";
import Trivago from "assets/images/Trivago.svg";
import SkyScanner from "assets/images/SkyScanner.svg";
import Kayak from "assets/images/Kayak.svg";
import BingAds from "assets/images/BingAds.svg";
import {useCallback, useEffect, useRef, useState} from "react";
import ReportingTextInput from "components/Reporting/ReportingTextInput/index";
import BudgetCapModal from "../budgetCapModal";
import TableValueEditor from "../tableValueEditor";
import {
  channelIds,
  ChannelImageMapper,
  getTableReportURLByPlatfrom,
} from "../chartContainer/utils";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import "./TableContainer.scss";
import BidPopover from "../BidPopover/BidPopover";
import { platformDesignationOptions, platformOptions } from "mock/filterOptions";
import { updatePlatform, updateSegmentList } from "store/slices/metaSearch/metaSearch";
import {
  updateHotelId,
} from "store/slices/account/accountSlice";
import { enqueueSnackbar } from "notistack";
import {getUserToken} from "../../../utils/functions/userToken";

const TableCellImage = ({ channelId, cell }) => {
  const ChannelImageMapper = {
    googleHotelAds: GoogleAds,
    tripadvisor: TripAdvisor,
    trivago: Trivago,
    skyscanner: SkyScanner,
    kayak: Kayak,
    bingAds: BingAds,
  };
  if (Object.keys(ChannelImageMapper).find((ch) => ch === channelId)) {
    return <img src={ChannelImageMapper[channelId]} alt="" />;
  }
  return channelId;
};
const TableContainer = ({
  hotelList = [],
  isChartCollapsed,
  setIsChartCollapsed,
  onEditClick,
  selectedPlatform,
  isAccountWritable,
  noBiddingOption= false,
}) => {
  const dispatch = useDispatch()
  const [openMonthlyBudgetCapModal, setOpenMonthlyBudgetCapModal] =
    useState(false); //test1
  const [selectedBudgetRow, setSelectedBudgetRow] = useState();
  const [bidEditModal, setbidEditModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = getUserToken() || "";
  const {
    account_id = "",
  } = useSelector((state) => state?.account?.account || {});
  const {
    level = "",
    hotel_id = [],
    group_id = [],
  } = useSelector(state => state?.account)
  const { currentDateRange = {}, comparitionDateRange = {}, compareWith } = useSelector(
    (state) => state?.calender
  );
  const {
    segment_list,
    platform = [],
  } = useSelector((state) => state?.metaSearch);

  const loadTableData = useCallback(async () => {
    const params = {
      account_id,
      level,
      "segment_list[]": segment_list?.length ? segment_list : [],
      start_date: format(
        currentDateRange?.startDate || new Date(),
        "yyyy-MM-dd"
      ),
      end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
      ...(comparitionDateRange?.startDate ? {compare_start_date: 
         format(comparitionDateRange?.startDate, "yyyy-MM-dd")
        }: {}),
        ...(comparitionDateRange?.endDate ? {compare_end_date: 
          format(comparitionDateRange?.endDate, "yyyy-MM-dd")
         }: {}),
      ...(level === "HOTEL"
        ? { "hotel_id[]": hotel_id }
        : { "group_id[]": group_id }),
      page_number: pageIndex,
      number_of_rows: pageSize,
      "sort_asc[]": sortingState
        ?.filter((sort) => !sort?.desc)
        ?.map((s) => s?.id),
      "sort_desc[]": sortingState
        ?.filter((sort) => sort?.desc)
        ?.map((s) => s?.id),
        ...(platform?.length > 1 ? (platform.every((pl) => pl.label.toLowerCase().includes("google"))
        ? {
            "google_channel[]": platform?.map((p) => channelIds?.[p?.id]),
          }
        : {
            "meta_channel[]": platform?.map((p) => channelIds?.[p?.id]),
          }): {}),
    };

    if (account_id) {
      setLoading(true);
      await axios
        .post(
          `${getTableReportURLByPlatfrom(platform)}`, createSearchParams(
            params
          ),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          setIsPaginationEnabled(data?.paginator_enabled);
          setPaginationObject(data);
          const sortedColumns = [
            ...data?.columns
              ?.filter(
                (col) => col?.type !== "METRICS" && col?.type === "SEGMENT"
              )
              ?.map((c) => ({ ...c})),
            ...data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type !== "SEGMENT"
            ),
            ...data?.columns?.filter((col) => col?.type === "METRICS"),
          ];
          const mappedColumns = sortedColumns?.map((col, index) => {
            let renderCell = ({ row, cell }) => {
              let style = {};
              if (index === 0) {
                style.marginLeft = '10px';
              }
              if (row.id === "0" && cell.column.id === "0") {
                style = {
                  ...style,
                  fontFamily: "Inter, sans-serif",
                  fontSize: "12px",
                  fontWeight: "600",
                };
              }
              return (
                  compareWith && col?.type !== "SEGMENT" ? <Tooltip
                      title={
                        col.type === "BUDGET_SPENT"
                            ? row?.original?.budget_remaining
                            : `${row?.original?.[
                                `${cell.column?.columnDef?.accessorKey}_compare`
                                ]} ${row?.original?.[`${cell.column?.columnDef?.accessorKey}_compare_percent`]}%`
                      }
                  >
                    <span style={style}>{cell.getValue()}</span>
                  </Tooltip>: <span style={style}>{cell.getValue()}</span>
              );
            };

            if(col?.id === "channel_code"){
              renderCell = ({row, cell}) => {
                return row?.id !== "0" ? <span className="clickable-element" style={{
                  cursor: "pointer", display: "flex", alignItems: "center", marginLeft: index === 0 ? '10px' : '0'
                }} onClick={() => {
                  const selectedPlatform = [...platformOptions, ...platformDesignationOptions].find(p => p.channel_id === row?.original?.channel_code_id)
                  dispatch(updatePlatform([selectedPlatform]))
                  dispatch(updateSegmentList([level === 'HOTEL'? "HOTELS": "GROUPS"]))
                }}>{cell.getValue()}</span>: <span style={{
                  marginLeft: index === 0 ? "10px" : "0",
              }}>{cell.getValue()}</span>
              }
            }

            if(col?.id === "idhotel"){
              renderCell = ({row, cell}) => {
                return row?.id !== "0" ? <span className="clickable-element" style={{
                  cursor: "pointer", marginLeft: index === 0 ? '10px' : '0'
                }} onClick={() => {
                  const selectedHotel = hotelList.find(h => h.hotel_id === Number(row?.original?.idhotel_id))?.hotel_id
                  dispatch(updateHotelId([selectedHotel]))
                  dispatch(updateSegmentList(["MARKET"]))
                }}>{cell.getValue()}</span>: <span style={{
                  marginLeft: index === 0 ? "10px" : "0",
              }}>{cell.getValue()}</span>
              }
            }

            if(col?.id === "id_group"){
              renderCell = ({row, cell}) => {
                return row?.id !== "0" ? <span className="clickable-element" style={{
                  cursor: "pointer", marginLeft: index === 0 ? '10px' : '0'
                }} onClick={() => {
                  const selectedHotel = hotelList.find(h => h.hotel_id === Number(row?.original?.idhotel_id))?.hotel_id
                  dispatch(updateHotelId([selectedHotel]))
                  dispatch(updateSegmentList(["HOTELS"]))
                }}>{cell.getValue()}</span>: <span style={{
                  marginLeft: index === 0 ? "10px" : "0",
              }}>{cell.getValue()}</span>
              }
            }

            if (col?.type === "BUDGET") {
              renderCell = ({ row }) => {
                return (
                  <>
                    {row?.original?.budget?.budget_editable && (
                        <span key={row.id} style={{ display: 'flex', alignItems: 'center', justifyContent: "end", marginLeft: index === 0 ? '10px' : '0' }}>
                        <ReportingTextInput
                            numberUnit={""}
                            sx={{
                              width: "69px",
                              fontSize: "12px",
                              fontFamily: "Roboto",
                              textAlign: "right",
                              backgroundColor: row?.original?.budget?.budget_editable
                                  ? ""
                                  : "lightgray",
                              paddingRight: "1px",
                              display: "flex",
                            }}
                            value={row?.original?.budget?.budget_value}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedBudgetRow(row?.original);
                              setOpenMonthlyBudgetCapModal(true);
                            }}
                        />
                      </span>
                    )}
                  </>
                );
              };
            }

            if (col?.type  === "BID") {
              renderCell = ({ row, cell }) => {
                const updateBidData = row?.original?.[cell?.column?.columnDef?.accessorKey];
                //@TODO Arshad please refractor this for total row
                return row.id !== "0" && (
                  <BidPopover bid={updateBidData} onLoadTable={loadTableData} />
                );
              };
            }
            return {
              id: index.toString(),
              accessorKey: col.id,
              Header: <Tooltip title={col?.description}><span>{col?.label}</span></Tooltip>,
              header: col?.label,
              Cell: renderCell,
              isSelected: true,
              type: col?.type,
            };
          });

          const formatRows = (row, id) => {
            const metricsRows = Object.keys(row?.metrics_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.metrics_list?.[curr]?.value ?? "-",
                  [`${curr}_compare`]:row?.metrics_list?.[curr]?.compared_value,
                  [`${curr}_compare_percent`]: row?.metrics_list?.[curr]?.compare_percent
                };
              },
              {}
            );

            const segmentsRows = Object.keys(row?.segment_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.segment_list?.[curr]?.label ?? "-",
                  [curr + "_id"]: row?.segment_list?.[curr]?.value ?? "-",
                  ...(Object.keys(row?.segment_list).includes("channel_code")
                    ? {
                        channel_code:
                          ChannelImageMapper[
                            row?.segment_list?.["channel_code"]?.value
                          ],
                      }
                    : {}),
                };
              },
              {}
            );

            const bidRows = Object.keys(row || {}).filter(key => row[key]?.type === "BID").reduce((acc,curr) => {
              acc[curr] = row?.[curr] || {}
              return acc;
            },{})

            const infoRows = Object.keys(row?.info_list || {}).reduce((acc,curr) => {
              const infoObject = row?.info_list?.[curr];
              return {
                ...acc,
                [curr]: data?.[infoObject?.attribute]?.[infoObject?.value]?.[infoObject?.info_path]
              }
            }, {})

            return {
              id,
              ...segmentsRows,
              ...infoRows,
              ...metricsRows,
              ...bidRows,
              budget: row?.budget,
              budget_spent: row?.budget_spent?.budget_spent,
              budget_remaining: row?.budget_spent?.budget_remaining,
            };
          };

          const rowsToDisplay = data?.row_list?.map((row, ind) =>
            formatRows(row, ind + 1)
          );

          setColumns(mappedColumns);
          setLoading(false);
          setSelectedColumns(mappedColumns);
          setTableData([
            {
              [mappedColumns?.[0]?.accessorKey]: "TOTAL",
              ...formatRows(data?.total_row, "0"),
            },
            ...rowsToDisplay,
          ]);
        }).catch(err => {
          console.error(err);
          enqueueSnackbar({
            message: "An error occured",
            variant: "error"
          })
        });
    }
  }, [
    JSON.stringify(platform),
    token,
    account_id,
    JSON.stringify(currentDateRange),
    JSON.stringify(comparitionDateRange),
    level,
    JSON.stringify(sortingState),
    JSON.stringify(segment_list),
    pageSize,
    pageIndex,
    JSON.stringify(hotel_id),
    JSON.stringify(group_id),
  ]);

  useEffect(() => {
    loadTableData();
  }, [
    JSON.stringify(platform),
    token,
    account_id,
    JSON.stringify(currentDateRange),
    JSON.stringify(comparitionDateRange),
    level,
    pageSize,
    pageIndex,
    JSON.stringify(sortingState),
    JSON.stringify(segment_list),
    JSON.stringify(hotel_id),
    JSON.stringify(group_id),
    compareWith
  ]);

  //@TODO pour la taille de la table
  const getMaxHeight = () => {
    if (isChartCollapsed) {
      return isPaginationEnabled
          ? "calc(100vh - 215px)"
          : "calc(100vh - 182px)";
    } else {
      return isPaginationEnabled
          ? "calc(100vh - 443px )"
          : "505px";
    }
  };

  return (
    <>
      {bidEditModal && (
        <TableValueEditor
          open={bidEditModal}
          bid={selectedBudgetRow?.bid}
          onClose={() => setbidEditModal(false)}
        />
      )}
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%", marginLeft: "4px" }}>
        <Table
          selectedPlatform={platform}
          page="metaSearch"
          isAccountWritable={isAccountWritable}
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          selectedSegments={segment_list}
          paginationData={paginationObject}
          isPaginationEnabled={isPaginationEnabled}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={getMaxHeight}
            onEditClick={() => {
            onEditClick();
          }}
          handlePagination={(pagination) => {
            setPageIndex(pagination?.pageIndex);
            setPageSize(pagination?.pageSize);
          }}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          noBiddingOption={noBiddingOption}
          handleSorting={(sorting) => {
            setSortingState(sorting);
          }}
          isLoading={loading}
        />
      </Grid>
      {openMonthlyBudgetCapModal && (
        <BudgetCapModal
          open={openMonthlyBudgetCapModal}
          budget={selectedBudgetRow?.budget}
          onClose={() => {
            setSelectedBudgetRow(null);
            setOpenMonthlyBudgetCapModal(false);
          }}
          onLoadTable={loadTableData}
        />
      )}
    </>
  );
};

export default TableContainer;
