import "./multipliers.scss";
import { useSelector } from "react-redux";
import TrivagoMultipliers from "../trivago/multipliers";
import TripAdvisorMultipliers from "../tripadvisor/multipliers";
import TripAdvisorSPMultipliers from "../tripadvisorSponsorPlacement/multipliers";
import BingMultipliers from "../bing/multipliers";
import GHAMultipliers from "../GHA/multipliers";
import GHAPPAMultipliers from "../GHAPPA/multipliers";

const Multipliers = () => {
  const platform = useSelector((state) => state?.metaSearch?.platform);

  switch (platform?.[0]?.id) {
    case "tripadvisor":
      return <TripAdvisorMultipliers />;
    case "trivago":
      return <TrivagoMultipliers />;
    case "tripadvisorSponsoredPlacement":
      return <TripAdvisorSPMultipliers />;
    case "bing":
      return <BingMultipliers />;
    case "googlehotelads":
      return <GHAMultipliers />;
    case "googleHotelAdsPropertyPromotionAds":
      return <GHAPPAMultipliers />;
    default:
      return null;
  }
};
export default Multipliers;
