import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactCacheBuster from "react-cache-buster";

import Login from "./components/Login/Login";
import ResetPassword from "./components/Login/ResetPassword/ResetPassword";
import Homepage from "./pages/homepage";
import NewHomepage from "./pages/newHomepage";
import MetaSearch from "./pages/metasearch";
import LostPassword from "./components/Login/LostPassword";
import AfterEmailSent from "./components/Login/ResetPassword/AfterEmailSent";
import BiddingCenter from "pages/biddingCenter/BiddingCenterPage";
import Groups from "pages/groups";
import BiddingOfflinePage from "pages/bidddingOffline";
import Reservations from "./pages/reservations";
import SignUpSemPage from "pages/productSignUpPage/SignUpSemPage";
import SignUpPmaxPage from "pages/productSignUpPage/SignUpPmaxPage";
import AlertCenterPage from "pages/alertCenter/AlertCenterPage";
import SubUsersManagement from "./pages/subUsersManagement";
import SearchAdsPage from "./pages/searchadspage";
import ReportCenter from "./pages/reportCenter";
import SamplePage from "./pages/samplePages";
import SearchAdsCampaignCreator from "./pages/searchAdsCampaign/searchAdsCampaignCreator";
import PMaxCampaignManager from "./pages/pMaxCampaignManager";
import PMaxCampaignCreator from "./pages/pMaxCampaignCreator";
import KeywordsReportingPage from "pages/keywordsReporting";
import SearchTermReportingPage from "pages/searchTermReporting";
import TransitionPage from "./pages/transitionPage";
import TestTranslationsPage from "./pages/testTranslation/index";

import PrivateRoute from "./PrivateRoute";

import {REACT_APP_VERSION} from "./constants/api_urls";
import ZendeskChat from "./components/ZendeskChat";

function App() {
    const isProduction = process.env.NODE_ENV === "production";
    return (
        <ReactCacheBuster
            currentVersion={REACT_APP_VERSION}
            isEnabled={isProduction}
            isVerboseMode={false}
            metaFileDirectory={'.'}
        >
            <div>
                <ZendeskChat />
                <Router>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/email-sent" element={<AfterEmailSent />}/>
                        <Route path="/lost-password" element={<LostPassword />} />

                        <Route path="/transition" element={<PrivateRoute element={<TransitionPage />} />}/>
                        <Route path="/home/:idaccount" element={<PrivateRoute element={<NewHomepage />} />}/>
                        <Route path="/old-home/" element={<PrivateRoute element={<Homepage />} />}/>
                        <Route path="/metasearch/:idaccount" element={<PrivateRoute element={<MetaSearch />} />}/>
                        <Route path="/groups/:idaccount" element={<PrivateRoute element={<Groups />} />}/>
                        <Route path="/bidding-offline/:idaccount" element={<PrivateRoute element={<BiddingOfflinePage />} />}/>
                        <Route path="/reservations/:idaccount" element={<PrivateRoute element={<Reservations />} />}/>
                        <Route path="/searchads/:idaccount" element={<PrivateRoute element={<SearchAdsPage />} />}/>
                        <Route path="/sign-up-sem/:idaccount" element={<PrivateRoute element={<SignUpSemPage />} />}/>
                        <Route path="/sign-up-pmax/:idaccount" element={<PrivateRoute element={<SignUpPmaxPage />} />}/>
                        <Route path="/alert-center/:idaccount" element={<PrivateRoute element={<AlertCenterPage />} />}/>
                        <Route path="/bidding-center/:idaccount" element={<PrivateRoute element={<BiddingCenter />} />}/>
                        <Route path="/sub-users-management" element={<PrivateRoute element={<SubUsersManagement />} />}/>
                        <Route path="/report-center/:idaccount" element={<PrivateRoute element={<ReportCenter />} />}/>
                        <Route path="/sample-page" element={<PrivateRoute element={<SamplePage />} />}/>
                        <Route path="/search-ads-campaign/create/:idaccount/:idhotel" element={<PrivateRoute element={<SearchAdsCampaignCreator />} />}/>
                        <Route path="/pmax-campaign-manager/:idaccount" element={<PrivateRoute element={<PMaxCampaignManager />} />}/>
                        <Route path="/pmax-campaign-manager/create/:idaccount/:idhotel" element={<PrivateRoute element={<PMaxCampaignCreator />} />}/>
                        <Route path="/keywords-reporting/:idaccount" element={<PrivateRoute element={<KeywordsReportingPage />} />}/>
                        <Route path="/search-terms-reporting/:idaccount" element={<PrivateRoute element={<SearchTermReportingPage />} />}/>
                        <Route path="/test-translations/" element={<PrivateRoute element={<TestTranslationsPage />} />}/>
                    </Routes>
                </Router>
            </div>
        </ReactCacheBuster>
    );
}

export default App;