export const isEmpty = (value) =>
    !value ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0);


export const validateBiddingOptionsPayload = (updateBidPayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.bidStrategy = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type);
    errors.bidAmount = !isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) &&
        !['NO_BID'].includes(updateBidPayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidPayload?.bidding_strategy?.bidding_value);

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};


export const validateBiddingRulesPayload = (updateBidPayload,updateBidMobilePayload,updateBidTabletPayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);

    errors.DESKTOP = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidTabletPayload?.bidding_strategy?.bidding_type);
    errors.MOBILE  = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidTabletPayload?.bidding_strategy?.bidding_type);
    errors.TABLET  = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidTabletPayload?.bidding_strategy?.bidding_type);

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};


export const validateMultipliersPayload = (updateBidPayload, multiplierPayload, activeButton, setErrors) => {
    const errors = {};
    errors.hotelId = isEmpty(updateBidPayload?.hotel_id);
    errors.marketId = isEmpty(updateBidPayload?.market_id);

    const isEmptyMultiplier = multiplierPayload.length === 0;
    const hasMissingValues =
        (activeButton === "DEVICE" || activeButton === "CHECKIN_DAY" || activeButton === "DATE_TYPE") &&
        multiplierPayload.filter((p) => p?.bidding_strategy?.multiplier_value).length === 0;

    if (isEmptyMultiplier || hasMissingValues) {
        switch (activeButton) {
            case "DAYS_TO_ARRIVAL":
                errors.days = true;
                errors.bidMultiplier = true;
                break;

            case "LENGTH_OF_STAY":
                errors.numberOfNights = true;
                errors.multiplierValue = true;
                break;

            case "DEVICE":
                errors.DESKTOP = true;
                errors.MOBILE = true;
                errors.TABLET = true;
                break;

            case "CHECKIN_DAY":
                errors.monday = true;
                errors.tuesday = true;
                errors.wednesday = true;
                errors.thursday = true;
                errors.friday = true;
                errors.saturday = true;
                errors.sunday = true;
                break;

            case "DATE_TYPE":
                errors.default = true;
                errors.userSelected = true;
                break;

            default:
                break;
        }
    }

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};
