import {Box, Checkbox} from "@mui/material";
import SearchTableInputComponent from "components/SearchTableInput/SearchTableInput";
import {useCallback, useState} from "react";
import CampaignTableWithPagination from "../CampaignTableWithPagination/CampaignTableWithPagination";
import "./CampaignSelection.scss";
import CustomButtonWithIconComponent from "components/CustomButtonWithIcon/Buttons";

const CampaignSelection = ({
  row,
  campaigns,
  onAddBudget,
  onRemoveCampaign,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermMovable, setSearchTermMovable] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsMovable, setSelectedRowsMovable] = useState([]);

  console.log(selectedRows, selectedRowsMovable, "Selected ROws");

  const columns = useCallback((key) => [
    {
      accessorKey: "campaign_name",
      header: "Campaign Name",
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      Cell: ({ row }) => (
        <span style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
              key={`select-${row.original?.campaign_id}`}
              checked={
                key === "campaigns_list"
                    ? selectedRows.includes(row.original?.campaign_id)
                    : selectedRowsMovable.includes(row.original?.campaign_id)
              }
              onChange={(e) => {
                if (key === "campaigns_list") {
                  setSelectedRows((prev) =>
                      e.target.checked
                          ? [...prev, row.original?.campaign_id]
                          : prev.filter((id) => id !== row.original?.campaign_id)
                  );
                } else {
                  setSelectedRowsMovable((prev) =>
                      e.target.checked
                          ? [...prev, row.original?.campaign_id]
                          : prev.filter((id) => id !== row.original?.campaign_id)
                  );
                }
              }}
              sx={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}
              color="primary"
          />

          {row?.original?.campaign_name}
        </span>
      ),
    },
    {
      accessorKey: "campaign_type",
      header: "Campaign Type",
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "current_budget_name",
      header: "Budget Name",
      muiTableBodyCellProps: {
        align: "right",
      },
      muiTableHeadCellProps: {
        align: "right",
      },
    },
  ]);

  const filteredCampaignRows = campaigns?.campaign_list?.filter(
    (campaign) =>
      campaign?.campaign_name && campaign?.campaign_name?.includes(searchTerm)
  );

  const filteredCampaignMovableRows = campaigns?.campaign_movable?.filter(
    (campaign) =>
      campaign?.campaign_name && campaign?.campaign_name?.includes(searchTerm)
  );

  const addToBudget = () => {
    const finalCampaign = [
      ...campaigns?.campaign_list,
      ...campaigns?.campaign_movable?.filter((campaign) =>
        selectedRowsMovable.includes(campaign?.campaign_id)
      ),
    ];

    onAddBudget(finalCampaign?.map((campaign) => campaign?.campaign_id));
  };

  const removeBudget = () => {
    console.log(
      campaigns?.campaign_list?.filter(
        (campaign) => !selectedRows.includes(campaign?.campaign_id)
      ),
      "Filtered"
    );
    const finalCampaign = [
      ...campaigns?.campaign_list?.filter(
        (campaign) => !selectedRows.includes(campaign?.campaign_id)
      ),
    ];

    onRemoveCampaign(finalCampaign?.map((campaign) => campaign?.campaign_id));
  };

  console.log(row, "Row");

  return (
    <Box display="flex" flexDirection="column" gap={"20px"}>
      <div>
        <Box className="groupTableHeaderTop">
          <h3>Campaigns in Shared Budget : {row?.shared_budget_name}</h3>
          <SearchTableInputComponent
            placeholder={"Search"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <CampaignTableWithPagination
            columns={columns("campaigns_list")}
            filteredRows={filteredCampaignRows}
            selectedRows={selectedRows}
          />
          <CustomButtonWithIconComponent
            color="#b0acac"
            hoverColor="#12794F"
            titleColor="#373F41"
            hoverTitleColor={"white"}
            width={"120px"}
            height={"24px"}
            borderRadius="20px"
            textStyle={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.52px",
              textAlign: "center",
            }}
            disabled={!selectedRows.length}
            onClick={removeBudget}
          >
            Remove
          </CustomButtonWithIconComponent>
        </Box>
      </div>
      <div>
        <Box className="groupTableHeaderTop">
          <h3>Select Campaigns to add to Shared Budget</h3>
          <SearchTableInputComponent
            placeholder={"Search"}
            value={searchTermMovable}
            onChange={(e) => setSearchTermMovable(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <CampaignTableWithPagination
            columns={columns("campaigns_movable")}
            filteredRows={filteredCampaignMovableRows}
            selectedRows={selectedRowsMovable}
          />
          <CustomButtonWithIconComponent
            color="#b0acac"
            hoverColor="#12794F"
            titleColor="#373F41"
            hoverTitleColor={"white"}
            width={"120px"}
            height={"24px"}
            borderRadius="20px"
            textStyle={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.52px",
              textAlign: "center",
            }}
            onClick={addToBudget}
          >
            Add to Budget
          </CustomButtonWithIconComponent>
        </Box>
      </div>
    </Box>
  );
};

export default CampaignSelection;
