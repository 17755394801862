import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Table, TableBody, TableCell, TableHead, TableRow,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./bidPopover.scss";
import CustomButton from "components/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { api_urls_charts } from "../chartContainer/utils";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { MoreHoriz } from "@mui/icons-material";
import { format } from "date-fns";
import { getUserToken } from "utils/functions/userToken";

const BidPopover = ({ bid, onLoadTable }) => {
  const { platform = [] } = useSelector((state) => state?.metaSearch);
  const token = getUserToken() || "";
  const bidHistoryURL = `${api_urls_charts[platform?.[0]?.id]}${
      bid?.bid_history_path
  }`;
  const updateBidPath = `${api_urls_charts[platform?.[0]?.id]}${
      bid?.bid_value_edit_path
  }`;
  const [biddingValue, setBiddingValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [bidEditModal, setbidEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (bidEditModal) {
      axios
          .get(bidHistoryURL, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const data = res?.data;
            setColumns(data?.columns_list);
            setTableData(data?.history_list);
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }, [bid, bidEditModal]);

  const handleApply = () => {
    axios
        .put(
            updateBidPath,
            {
              bidding_value: Number(biddingValue),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        )
        .then((res) => {
          enqueueSnackbar({
            message: "Bid value updated successfully",
            variant: "success",
          });
          setBiddingValue("");
          setbidEditModal(false);
          onLoadTable?.();
        })
        .catch((err) => {
          console.error(err?.message);
          enqueueSnackbar({
            message: err.response?.data?.[0]?.errorMessage || "Something went wrong",
            variant: "error",
          });
        });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return format(date, "dd/MM/yyyy - hh:mm a");
  };

  return (
      <>
      <span>
        <span
            className={"bid-cell"}
            onClick={(e) => {
              e.stopPropagation();
              setbidEditModal(true);
              setAnchorEl(e.currentTarget);
            }}
        >
          {bid?.bid_label || <MoreHoriz/>}
        </span>
      </span>
        <Popover
            open={bidEditModal}
            anchorEl={anchorEl}
            onClose={() => {
              setbidEditModal(false);
            }}
            PaperProps={{
              sx: {
                position: "relative",
                width: "600px",
                maxHeight: "400px",
                height: "fit-content",
                boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                paddingBottom: "12px",
              },
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
        >
          <DialogTitle
              sx={{
                fontSize: "12px",
                fontFamily: "Inter, sans-serif",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 24px 0px",
              }}
          >
            <span className="dialogTitle">{bid?.bid_edit_in_place_title}</span>
            <span>
            {" "}
              <IconWrapper
                  onClick={() => {
                    setbidEditModal(false);
                  }}
                  icon={close}
              />{" "}
          </span>
          </DialogTitle>
          <DialogContent>
            {bid?.bid_editable && (
                <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                >
                  <TextInput
                      sx={{
                        fontSize: "12px !important",
                        fontFamily: "Inter",
                        width: "120px",
                        height: "24px",
                        borderRadius: "10px",
                        padding: "4px",
                        flexShrink: 0,
                      }}
                      adornmentColor="black"
                      value={biddingValue}
                      numberUnit={bid?.bid_symbol || ""}
                      placeholder="Enter value"
                      onChange={(e) => {
                        setBiddingValue(e.target.value);
                      }}
                  />
                  <DialogActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flexShrink: 0,
                        marginLeft: "10px",
                      }}
                  >
                    <CustomButton
                        sx={{ width: "80px", height: "24px" }}
                        title="Apply"
                        variant={"filled"}
                        onClick={handleApply}
                    />
                  </DialogActions>
                </Box>
            )}
            <Table className="bid-table">
              <TableHead>
                <TableRow>
                  {columns
                      ?.filter((c) => c?.column_id !== "status")
                      ?.map((col) => (
                          <TableCell key={col?.column_id} sx={{ fontWeight: 'bold' }}>
                            {col?.column_label}
                          </TableCell>
                      ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(0, 8).map((item, rowIndex) => {
                  const row = item?.history_data;
                  return (
                      <TableRow key={rowIndex}>
                        {columns
                            ?.filter((c) => c?.column_id !== "status")
                            ?.map((col) => (
                                <TableCell sx={{ whiteSpace: 'nowrap' }} key={col?.column_id}>
                                  {col?.column_type === "DATE"
                                      ? formatDate(row?.[col.column_id])
                                      : col?.column_type === "VALUE"
                                          ? `${row?.[col.column_id]} ${bid?.bid_symbol || ""}`
                                          : row?.[col.column_id]}
                                </TableCell>
                            ))}
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
        </Popover>
      </>
  );
};
export default BidPopover;
