import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign: [],
  segment_list: [],
};
export const pMaxSlice = createSlice({
  name: "pMaxSlice",
  initialState: initialState,
  reducers: {
    updateCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
    resetPMax: () => initialState
  },
});

export const {
  updateCampaign,
  updateSegmentList,
  resetPMax
} = pMaxSlice.actions;
export default pMaxSlice.reducer;
