import React, {useEffect, useState} from 'react';
import EditIcon from '../../assets/images/edit.svg';
import Icons from "components/DmpIcons/icons";
import './AlertActionMenu.scss';
import {APP_ALERT_CENTER_API_ENDPOINT} from '../../constants/api_urls';
import DeleteConfirmationModal from './DeleteConfirmationModal/DeleteConfirmationModal';
import { getUserToken } from "../../utils/functions/userToken";

const AlertActionMenu = ({ show, position, alertData, onDelete, onEdit, onClose, onPause, menuRef }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [menuVisible, setMenuVisible] = useState(show);

    // Synchronize state with props
    useEffect(() => {
        setMenuVisible(show);
    }, [show]);

    // Reset state when menu is closed
    useEffect(() => {
        if (!menuVisible && !isModalOpen) {
            setIsModalOpen(false);
            setError(null);
            setIsLoading(false);
        }
    }, [menuVisible, isModalOpen]);

    if (!menuVisible && !isModalOpen) return null;

    const jwtToken = getUserToken();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setError(null);
    };

    const handleEditAction = () => {
        onEdit(alertData);
        if (onClose) onClose(); // Informer le parent que le menu doit être fermé
    };

    const handleDeleteAction = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center/update-status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: alertData.id, status: 'DISABLED' }),
            });

            if (response.ok) {
                const result = await response.json();
                closeModal();
                setMenuVisible(false); // Fermer le menu localement
                if (onDelete) onDelete(result); // Vous pouvez aussi appeler onClose ici si nécessaire
                if (onClose) onClose(); // Informer le parent que le menu doit être fermé
            } else {
                const errorText = await response.text();
                console.error('Delete failed:', errorText);
                setError('La suppression a échoué. Veuillez réessayer.');
            }
        } catch (err) {
            console.error('Error during delete API call:', err);
            setError('Une erreur est survenue. Veuillez réessayer.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePauseAction = async () => {
        try {
            const newStatus = alertData.status.toUpperCase() === 'PAUSED' ? 'ENABLED' : 'PAUSED';
            const response = await fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center/update-status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: alertData.id, status: newStatus }),
            });

            if (response.ok) {
                const result = await response.json();
                setMenuVisible(false); // Fermer le menu localement
                if (onPause) onPause(result);
                if (onDelete) onDelete(result); // Utiliser onDelete pour rafraîchir les données si nécessaire
                if (onClose) onClose(); // Informer le parent que le menu doit être fermé
            } else {
                console.error('Status update failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during status update API call:', error);
        }
    }

    return (
        <div className="alert-action-menu" ref={menuRef} style={{ top: position.top, left: position.left }}>
            <ul>
                <li onClick={handleEditAction}>
                    <img src={EditIcon} alt="Edit" className="icon" />
                    Edit
                </li>
                <li onClick={handlePauseAction}>
                    <img src={alertData.status === 'Paused' ? Icons.ActionsIconsSVG.ResumeIconSVG : Icons.ActionsIconsSVG.PauseIconSVG} alt="Pause" className="icon" />
                    {alertData.status === 'Paused' ? "Resume" : "Pause"}
                </li>
                <li onClick={openModal}>
                    <img src={Icons.ActionsIconsSVG.DeleteIconSVG} alt="Delete" className="icon" />
                    Delete
                </li>
            </ul>
            <DeleteConfirmationModal
                open={isModalOpen}
                onClose={() => { closeModal(); }}
                onConfirm={() => { handleDeleteAction(); }}
                alertName={alertData.alert_name}
                loading={isLoading}
                error={error}
            />
        </div>
    );
};

export default AlertActionMenu;
