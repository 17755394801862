import {
  MenuItem,
  Select,
  FormControl,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import { SettingsIcon } from "../../../../../assets/images/Icons";
import "./select_menu.scss";
import { KeyboardArrowDown } from "@mui/icons-material";

const SelectMenu = ({ value, options, action, handleChange }) => {
  return (
    <>
      <FormControl className={"chartOnSearchAds"}>
        <Select
          disableUnderline
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          IconComponent={(props) => (
            <KeyboardArrowDown sx={{ color: "black !important" }} {...props} />
          )}
          value={value}
          variant="standard"
          MenuProps={{
            PaperProps: { sx: { borderRadius: "10px" } },
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign:'center',
            fontSize: "12px",
            fontWeight: 400,
            width:'110%',
            fontFamily: "Inter, sans-serif",
            "&:hover": {
              backgroundColor: "#E9E9E9",
              borderRadius: "10px",
            },
            "& .MuiSelect-select": {
              "&:focus": {
                backgroundColor: "#E9E9E9",
                borderRadius: "10px",
              },
            },
          }}
          renderValue={(o) => {
            return options?.find((op) => op.id === o)
              ? options?.find((op) => op.id === o)?.label
              : "No value";
          }}
        >
          <MenuItem disabled sx={{ marginTop: "5px" }} className="menuItem">
            {" "}
            <Typography sx={{ marginRight: "55px", fontSize: 12 }}>
              Change Metric
            </Typography>{" "}
            <SettingsIcon />{" "}
          </MenuItem>

          {options?.map((option) => {
            return (
              <MenuItem className="menuItem" value={option?.id}>
                {" "}
                {option?.label}{" "}
              </MenuItem>
            );
          })}
          <Divider
            sx={{ backgroundColor: "#CCC", width: "80%", marginInline: "auto" }}
          ></Divider>
          <Box
            sx={{
              paddingLeft: "20px !important",
              marginBlock: 0,
              justifyContent: "flex-start",
            }}
            disableGutters
          >
            {action}
          </Box>
        </Select>
      </FormControl>
    </>
  );
};
export default SelectMenu;
