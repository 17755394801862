import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function CustomDrawerComponent({ isLoading, errorMessage = null }) {
    if (!isLoading && !errorMessage) return null;

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#F5F5F5",
                opacity: 0.5,
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                color: "white"
            }}
        >
            {isLoading && !errorMessage && <CircularProgress style={{ color: "#61D5A9" }} />}
            {errorMessage && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ErrorOutlineIcon style={{ marginRight: 8 }} />
                    <Typography variant="h6">{errorMessage}</Typography>
                </Box>
            )}
        </Box>
    );
}

export default CustomDrawerComponent;