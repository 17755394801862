import React, {useEffect, useState} from "react";
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    MenuItem,
} from "@mui/material";
import GOOGLE_PMAX_LANGUAGE_CRITERION from "../../../constants/search-ads-criterion/languageCriterion";
import {validateLanguage} from "../../Utils/validators/campaignSettingsValidators";
import CustomFormControl from "../../../../alert-center/Dropdown/CustomFormControl";

const LanguageSelection = ({ selectedLanguage, setSelectedLanguage, errors }) => {
    const primaryLanguageIds = [11, 15, 43, 16, 24];
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        const validateSelectedLanguage = async () => {
            if (selectedLanguage) {
                const result = await validateLanguage(selectedLanguage);

                if (!result.success) {
                    setAlertMessage(
                        result.error || `The selected language (${selectedLanguage}) is invalid.`
                    );
                }
            } else {
                setAlertMessage("");
            }
        };

        validateSelectedLanguage();
    }, [selectedLanguage]);

    const primaryLanguages = GOOGLE_PMAX_LANGUAGE_CRITERION.filter((lang) =>
        primaryLanguageIds.includes(lang.id_lang)
    );

    const otherLanguages = GOOGLE_PMAX_LANGUAGE_CRITERION.filter(
        (lang) => !primaryLanguageIds.includes(lang.id_lang)
    );

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setSelectedLanguage(value);
    };

    const handleSelectChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <Box className="languageSelection">
            <Box display="flex" alignItems="center">
                <Typography className="sectionTitle" fontWeight="bold">
                    Select Your Target Audience Language
                </Typography>
                {alertMessage && (
                    <Typography className="text-error-section">
                        {alertMessage}
                    </Typography>
                )}
                {errors.campaignLang && (
                    <Typography className="text-error-section">
                        {errors.campaignLang}
                    </Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center" paddingLeft={3} gap={2}>
                <Box>
                    {primaryLanguages.map((language) => (
                        <FormControlLabel
                            key={language.id_lang}
                            control={
                                <Checkbox
                                    size="small"
                                    sx={{
                                        color: "#BAB7B7",
                                        "&.Mui-checked": {
                                            color: "#61D5A9",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            height: "14px",
                                            width: "14px",
                                        },
                                    }}
                                    checked={selectedLanguage === language.id_lang.toString()}
                                    onChange={handleCheckboxChange}
                                    value={language.id_lang}
                                />
                            }
                            label={language.language_name}
                        />
                    ))}
                </Box>

                <CustomFormControl
                    className="languageSelect"
                    sx={{ width: "auto", minWidth: "125px" }}
                    value={selectedLanguage}
                    onChange={handleSelectChange}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                            return (
                                <span style={{
                                    fontSize: "12px",
                                    color: "#bab7b7"
                                }}>Other</span>
                            );
                        }
                        const language = GOOGLE_PMAX_LANGUAGE_CRITERION.find(
                            (lang) => lang.id_lang.toString() === selected.toString()
                        );

                        return language ? language.language_name : selected;
                    }}
                >
                    <MenuItem value="" disabled>
             <span style={{
                 fontSize: "12px",
                 color: "#bab7b7"
             }}>Other</span>
                    </MenuItem>
                    {otherLanguages.map((language) => (
                        <MenuItem key={language.id_lang} value={language.id_lang}>
                 <span style={{
                     fontSize: "12px",
                     color: "#373F41"
                 }}>{language.language_name}</span>
                        </MenuItem>
                    ))}
                </CustomFormControl>
            </Box>
        </Box>
    );
};

export default LanguageSelection;
