import GroupBase from "./GroupsBase";

class PerformanceGroup extends GroupBase {
    constructor() {
        super({
            name: "Performance",
            description: "",
        });
    }
}

export default PerformanceGroup;
