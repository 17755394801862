import BiddingOffline from "features/bidding-offline";
import DefaultLayout from "layout/defaultLayout";
import React from "react";
import PageTitles from "../../components/PageTitles";

const BiddingOfflinePage = () => {
  return (
    <DefaultLayout page={"metaSearch"}>
      <div className={"bidding-offline"}>
        <PageTitles pageTitle="Metasearch" pageSubTitle="Bidding offline" />
        <div style={{ paddingInline: "16px", paddingTop: "14px" }}>
          <BiddingOffline />
        </div>
      </div>
    </DefaultLayout>
  );
};
export default BiddingOfflinePage;
