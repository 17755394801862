// CreateBudgetPanel.jsx

import React from "react";
import { Box, Typography, IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CampaignAccordion from "features/google-search-ads-campaign/googleSearchAdsFormComponents/Campaign/CampaignAccordion";
import CreateBudgetForm from "../SharedBudgetPanel/CreateBudgetForm/CreateBudgetForm";
import "./CreateBudgetPanel.scss";

const CreateBudgetPanel = ({
                               isOpen,
                               onClose,
                               data = {},
                               payload,
                               setPayload,
                               onSave,
                               showToast,
                               setShowToast,
                               toastMessage,
                               toastType,
                           }) => {
    return (
        <>
            <Box className={`createBudgetPanel ${isOpen ? "open" : ""}`}>
                <Box
                    className="header"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={onClose} className="closeButton">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="body1" className="title">
                            Create Shared Budget
                        </Typography>
                    </Box>
                </Box>

                <Box className="content">
                    <CampaignAccordion
                        title="CREATE SHARED BUDGET"
                        disabled={false}
                        expanded={true}
                        onSave={onSave}
                        isSaveButton={true}
                        isCancelButton={true}
                        onCancel={onClose}
                    >
                        <CreateBudgetForm
                            data={data?.shared_budget_hotel_list}
                            handleFieldChange={(field, val) => {
                                setPayload((prev) => ({ ...prev, [field]: val }));
                            }}
                            payload={payload}
                        />
                    </CampaignAccordion>
                </Box>
            </Box>

            <Snackbar
                open={showToast}
                autoHideDuration={7000}
                onClose={() => setShowToast(false)}
            >
                <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CreateBudgetPanel;
