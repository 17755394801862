import React, {useState} from 'react';
import { MaterialReactTable } from 'material-react-table';
import './HotelsTable.scss';
import CustomPagination from "../../../../components/CustomPagination";
import {Checkbox} from "@mui/material";
const HotelsTable = ({ filteredHotels, selectedHotels, handleCheckboxChange, showGroupName, isThirdComponent }) => {
    // Define the columns with different sizes for the third component
    const columns = [
        {
            accessorKey: 'id',
            header: 'Hotel ID',
            size: isThirdComponent ? 20 : 50, // Smaller size for the third component
        },
        {
            accessorKey: 'name',
            header: 'Hotel Name',
            size: isThirdComponent ? 50 : 100, // Smaller size for the third component
        },
        {
            accessorKey: 'country',
            header: 'Country',
            size: isThirdComponent ? 30 : 50, // Smaller size for the third component
        },
    ];

    // Conditionally add the "Group Name" column if needed
    if (showGroupName) {
        columns.push({
            accessorKey: 'groupName',
            header: 'Group Name',
            size: isThirdComponent ? 50 : 100, // Adjust the size of the Group Name column
            Cell: ({ cell }) => cell.getValue() || 'Ungrouped',
        });
    }

    // Add the custom checkbox column
    const checkboxColumn = {
        id: 'select',
        header: '',
        enableColumnActions: false,
        enableSorting: false,
        enableFilters: false,
        size: isThirdComponent ? 15 : 20, // Further reduced size for the checkbox column
        Cell: ({ row }) => (
            <Checkbox
                checked={selectedHotels.includes(row.original.id)}
                onChange={() => handleCheckboxChange(row.original.id)}
                sx={{
                    "& .MuiSvgIcon-root": {
                        fontSize: 16,
                    },
                }}
            />
        ),
    };

    // Prepend the checkbox column to the beginning
    columns.unshift(checkboxColumn);

    const [rowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const handleChangePage = (event, newPage) => {setPage(newPage);};
    // Calculate paginated data
    const hotelsCount = filteredHotels.length;
    const pageCount = Math.ceil(hotelsCount / rowsPerPage);
    const paginatedHotels = filteredHotels.slice((page - 1) * rowsPerPage,page * rowsPerPage);

    return (
        <div className="custom-hotels-table-container">
            <MaterialReactTable
                columns={columns}
                data={paginatedHotels}
                enablePagination={false}
                enableSorting={true}
                enableColumnVisibility={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                muiTableContainerProps={{ sx: { boxShadow: 'none', border: 'none' } }}
                muiTablePaperProps={{ sx: { boxShadow: 'none', overflow: 'hidden' } }}
                muiTableHeadCellProps={{
                    sx: {
                        textAlign: 'left',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        verticalAlign: 'middle',
                        padding: '0 16px',
                        boxShadow: 'none',
                        borderBottom: '1px solid #e0e0e0',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontFamily: 'Roboto',
                        textAlign: 'left',
                        width: '150px',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '40px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '45px',
                        padding: '0 16px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        "& td": {
                            position: "static",
                            zIndex: "auto",
                        },
                        '&:hover': {
                            backgroundColor: "#FAFAFA !important"
                        },
                    },
                }}
            />
            {/* Pagination Component */}
            <CustomPagination
                pageCount={pageCount}
                page={page}
                handleChangePage={handleChangePage}
            />
        </div>
    );
};

export default HotelsTable;
