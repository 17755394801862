import { Menu, MenuItem, Box } from "@mui/material";
import CustomCheckbox from "../../../../components/Checkbox";
import { useStyles } from "./useStyles";
import { useState } from "react";

const GraphOptions = ({ open, anchorEl, onClose, onChange, defaultActive }) => {
  const { classes } = useStyles();
  const options = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
  ];
  const [activeOption, setActiveOption] = useState(defaultActive);
  return (
    <Menu
      closeAfterTransition={false}
      PaperProps={{ sx: { borderRadius: "10px" } }}
      open={open}
      anchorEl={anchorEl.current}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box>
          <MenuItem className={classes.header}> Graph options</MenuItem>
          {options?.map((option) => {
            return (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={classes.menuItem}
              >
                <CustomCheckbox
                  size={10}
                  fontSize={12}
                  title={option?.label}
                  checked={activeOption === option?.value}
                  onChange={() => {
                    setActiveOption(option?.value);
                    onChange(option?.value);
                  }}
                />
              </MenuItem>
            );
          })}
        </Box>
      </Box>
    </Menu>
  );
};
export default GraphOptions;
