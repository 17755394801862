import React from 'react';
import { Menu, MenuItem, Divider } from '@mui/material';

const ActionMenu = ({
    anchorEl,
    handleCloseMenu,
    handleDeleteGroup,
    handleGroupSettings,
    handleExportCsv,
    handleUploadCsv
}) => (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem style={{ fontSize:'12px',fontWeight:"500"}} onClick={handleUploadCsv}>Upload CSV</MenuItem> {/* Upload CSV Option */}
        <MenuItem style={{ fontSize:'12px',fontWeight:"500"}} onClick={handleExportCsv}>Export CSV</MenuItem> {/* Export CSV option */}
        <MenuItem style={{ fontSize:'12px',fontWeight:"500"}} onClick={handleGroupSettings}>Group Settings</MenuItem> {/* Group Settings Option */}
        <Divider />
        <MenuItem onClick={handleDeleteGroup} style={{fontSize: '12px', color: 'red', fontWeight: "500"}}>Delete Group</MenuItem>
    </Menu>
);

export default ActionMenu;
