export const DeviceOptions = [
    { key: "BING DEVICE OVERVIEW", value: "BING" },
    { key: "GHA FBL DEVICE OVERVIEW", value: "GHA_FBL" },
    { key: "GHA DEVICE OVERVIEW", value: "GHA_META" },
    { key: "GHA PPA DEVICE OVERVIEW", value: "GHA_PPA" },
    { key: "KAYAK DEVICE OVERVIEW", value: "KAYAK" },
    { key: "SKYSCANNER DEVICE OVERVIEW", value: "SKYSCANNER" },
    { key: "TRIPADVISOR DEVICE OVERVIEW", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP DEVICE OVERVIEW", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO DEVICE OVERVIEW", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL DEVICE OVERVIEW", value: "TRIVAGO_SL" },
    { key: "PMAX DEVICE OVERVIEW", value: "PMAX" },
    { key: "GHA SEM DEVICE OVERVIEW", value: "GHA_SEM" },
];

export const TopHotelsOptions = [
    { key: "BING TOP PERFORMING HOTELS", value: "BING" },
    { key: "GHA FBL TOP PERFORMING HOTELS", value: "GHA_FBL" },
    { key: "GHA TOP PERFORMING HOTELS", value: "GHA_META" },
    { key: "GHA PPA TOP PERFORMING HOTELS", value: "GHA_PPA" },
    { key: "KAYAK TOP PERFORMING HOTELS", value: "KAYAK" },
    { key: "SKYSCANNER TOP PERFORMING HOTELS", value: "SKYSCANNER" },
    { key: "TRIPADVISOR TOP PERFORMING HOTELS", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP TOP PERFORMING HOTELS", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO TOP PERFORMING HOTELS", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL TOP PERFORMING HOTELS", value: "TRIVAGO_SL" },
    { key: "PMAX TOP PERFORMING HOTELS", value: "PMAX" },
    { key: "GHA SEM TOP PERFORMING HOTELS", value: "GHA_SEM" },
];

export const TopMarketsOptions = [
    { key: "BING TOP MARKETS", value: "BING" },
    { key: "GHA FBL TOP MARKETS", value: "GHA_FBL" },
    { key: "GHA TOP MARKETS", value: "GHA_META" },
    { key: "GHA PPA TOP MARKETS", value: "GHA_PPA" },
    { key: "KAYAK TOP MARKETS", value: "KAYAK" },
    { key: "SKYSCANNER TOP MARKETS", value: "SKYSCANNER" },
    { key: "TRIPADVISOR TOP MARKETS", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP TOP MARKETS", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO TOP MARKETS", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL TOP MARKETS", value: "TRIVAGO_SL" },
    { key: "PMAX TOP MARKETS", value: "PMAX" },
    { key: "GHA SEM TOP MARKETS", value: "GHA_SEM" },
];
