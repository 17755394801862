import { CloseOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CustomCheckbox from "components/Checkbox";
import { useEffect, useState } from "react";

const ColumnSelectionMenu = ({ open, handleClose, onApply, columns }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(columns);
  }, [columns]);

  const getTitleForHeader = () => {
    const keys = Object.keys(getCategorizedColumns());
    return keys.length > 0 ? keys[0] : "";
  };

  const getCategorizedColumns = () => {
    const excludedColumns = ["BID", "BUDGET", "SEGMENT", "BUDGET_SPENT", "INFO"];
    const distinctCategories = new Set(
      selected
        .filter((c) => !excludedColumns.includes(c.type))
        .map((col) => col.type)
    );

    const displayColumns = selected.filter((cl) =>
      Array.from(distinctCategories).includes(cl.type)
    );

    return Array.from(distinctCategories).reduce((acc, curr) => {
      acc[curr] = displayColumns.filter((dcol) => dcol.type === curr);

      return acc;
    }, {});
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="m"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            // height: 350,
          },
        }}
      >
        <div>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
              paddingInline: "14px",
            }}
          >
            <div>&nbsp;</div>
            <div
              style={{ justifySelf: "flex-end" }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseOutlined sx={{ color: "#BAB7B7", fontSize: "14px" }} />
            </div>
          </DialogTitle>
          <DialogContent sx={{ paddingInline: "14px", paddingBlock: 0 }}>
            <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {(() => { 
                const categorizedColumns = Object.values(getCategorizedColumns()).flat();
                const chunkSize = 10;
                const chunks = [];
                // Split the columns into chunks of 10
                for (let i = 0; i < categorizedColumns.length; i += chunkSize) {
                  chunks.push(categorizedColumns.slice(i, i + chunkSize));
                }

                return chunks.map((chunk, index) => {
                  const isChunkSelectAllChecked = chunk.every((item) => item.isSelected);

                  const toggleChunkSelectAll = () => {
                    const updatedSelected = selected.map((item) => {
                      if (chunk.some((col) => col.accessorKey === item.accessorKey)) {
                        return { ...item, isSelected: !isChunkSelectAllChecked };
                      }
                      return item;
                    });
                    setSelected(updatedSelected);
                  };

                  return (
                    <div key={`chunk-${index}`} style={{ margin: "0 20px" }}>
                      <span>
                        <CustomCheckbox
                          size={"small"}
                          checked={isChunkSelectAllChecked}
                          onChange={toggleChunkSelectAll}
                          title={getTitleForHeader()}
                          labelStyles={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        /> 
                      </span>
                      <ul
                        style={{
                          listStyleType: "none",
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          height: "250px",
                          marginBlock: 0,
                          padding: 0,
                        }}
                      >
                        {chunk.map((item) => (
                          <li
                            key={item.accessorKey}
                            style={{
                              borderLeft: "1px solid #CADEDD",
                              paddingLeft: "10px",
                              height: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span>
                                <CustomCheckbox
                                  size={"small"}
                                  checked={item?.isSelected}
                                  title={item?.header}
                                  onChange={() => {
                                    setSelected(
                                      selected?.map((i) => {
                                        return {
                                          ...i,
                                          isSelected:
                                            i?.accessorKey === item.accessorKey
                                              ? !i?.isSelected
                                              : i?.isSelected,
                                        };
                                      })
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                });
              })()}
            </div>
          </DialogContentText>

          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "14px",
              paddingBlock: "15px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "black",
                backgroundColor: "#61D5A9",
                borderRadius: "20px",
                fontSize: "10px",
                width: "100px",
                paddingInline: "8px",
              }}
              onClick={() => {
                onApply(selected);
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
export default ColumnSelectionMenu;
