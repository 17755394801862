const biddingOptionsList = [
  {
    id: "No Bid",
    label: "No Bid",
    value: "NO_BID",
  },
  {
    id: "Manual Bid",
    label: "Manual Bid",
    value: "MANUAL_BID",
  },
  {
    id: "Auto Bid",
    label: "Auto Bid",
    value: "AUTO_BID",
  },
  
];

export const tripadvisorBiddingOptionsFormFields = [
  {
    id: "bidding_type_desktop",
    label: "Bid strategy (Desktop)",
    type: "select",
    placeholder: "Select a strategy",
    options: biddingOptionsList,
  },
  {
    id: "bidding_amount_desktop",
    label: "Bid Amount (Desktop)",
    type: "textField",
    placeholder: "Please enter a value",
    option: [],
  },
  {
    id: "bidding_type_mobile",
    label: "Bid strategy (Mobile)",
    type: "select",
    placeholder: "Select a strategy",
    options: biddingOptionsList,
  },
  {
    id: "bidding_amount_mobile",
    label: "Bid Amount (Mobile)",
    type: "textField",
    placeholder: "Please enter a value",
  },
  {
    id: "bidding_upper_limit_desktop",
    value: "bidding_upper_limit",
    label: "Upper Limit (Desktop)",
    type: "textField",
    subType: "number",
    numberUnit: "$",
    placeholder: "Please enter a value",
  },
  {
    id: "bidding_upper_limit_mobile",
    value: "bidding_upper_limit",
    label: "Upper Limit (Mobile)",
    type: "textField",
    subType: "number",
    numberUnit: "$",
    placeholder: "Please enter a value",
  },
];
export const COMMONFORMFIELDS = [
  {
    id: "hotel",
    label: "Hotel",
    type: "multiSelect",
    placeholder: "Select a hotel",
  },

  {
    id: "market",
    label: "Market",
    type: "multiSelect",
    placeholder: "Select a value",
  },
];
