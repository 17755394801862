import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./budgetCapModal.scss";
import CustomButton from "components/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { APP_METASEARCH_BUDGET_API_ENDPOINT } from "constants/api_urls";
import { useSnackbar } from "notistack";
import {getUserToken} from "../../../utils/functions/userToken";
const months = [
  {
    month_name: "JAN",
    month_index: 1,
  },
  {
    month_name: "FEB",
    month_index: 2,
  },
  {
    month_name: "MAR",
    month_index: 3,
  },
  {
    month_name: "APR",
    month_index: 4,
  },
  {
    month_name: "MAY",
    month_index: 5,
  },
  {
    month_name: "JUN",
    month_index: 6,
  },
  {
    month_name: "JUL",
    month_index: 7,
  },
  {
    month_name: "AUG",
    month_index: 8,
  },
  {
    month_name: "SEP",
    month_index: 9,
  },
  {
    month_name: "OCT",
    month_index: 10,
  },
  {
    month_name: "NOV",
    month_index: 11,
  },
  {
    month_name: "DEC",
    month_index: 12,
  },
];
const BudgetCapModal = ({ open, onClose, budget, onLoadTable }) => {
  const token = getUserToken() || "";
  const API_URL =
    APP_METASEARCH_BUDGET_API_ENDPOINT + budget?.budget_value_edit_path;

  const { enqueueSnackbar } = useSnackbar();

  const [payload, setPayload] = useState([]);
  const [budgetByMonth, setBudgetByMonth] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const initialPayload = {
      month: 0,
      budget: Number(budget?.budget_by_month?.current_budget) || 0,
      check_only_validation: true,
    };
    const allPayloads = months.map((month) => ({
      ...initialPayload,
      month: month.month_index,
      budget:
        Number(budget?.budget_by_month?.[`month_${month.month_index}`]) || 0,
    }));
    setBudgetByMonth(budget?.budget_by_month);
    setPayload([initialPayload, ...allPayloads]);
  }, [budget]);

  const handleApply = async () => {
    setLoading(true);
    try {
      const allRequests = payload.map((pl) =>
          axios.put(
              `${API_URL}&${createSearchParams(pl)}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
          )
      );

      const results = await Promise.allSettled(allRequests);
      console.log(results, "Results");
      const failedRequests = results.filter((r) => r.status === "rejected");

      if (failedRequests.length) {
        const rawMessage =
            failedRequests[0].reason?.response?.data?.[0]?.error_message ||
            "Something went wrong";

        const formattedMessage = rawMessage.replace(
            "{{ currency }}",
            budget?.budget_symbol || ""
        );

        enqueueSnackbar(formattedMessage, { variant: "error" });
      } else {
        const updateRequests = payload.map((pl) => {
          const updatePayload = { ...pl };
          delete updatePayload?.check_only_validation;
          return axios.put(
              `${API_URL}&${createSearchParams(updatePayload)}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
          );
        });

        await Promise.allSettled(updateRequests);
        onLoadTable();
        enqueueSnackbar("Budget Updated.", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during API calls:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        className={"budget-cap-modal-campaign-manager"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "420px",
            height: "400px",
            borderRadius: "10px",
          },
        }}
      >
        {loading && (
          <div className="loader-overlay">
            <CircularProgress />
          </div>
      )}
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="dialogTitle">{budget?.title}</span>
          <span>
            {" "}
            <IconWrapper onClick={onClose} icon={close} />{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="budget-info">
            Minimum budget required: {budget?.minimum_budget_value + budget?.budget_symbol}
          </div>
          <TextInput
            sx={{
              width: "140px",
              marginBottom: "24px",
              fontSize: "12px",
              fontFamily: "Inter",
            }}
            value={Number(budgetByMonth?.[`current_budget`]) || ""}
            placeholder="Enter value"
            numberUnit={budget?.budget_symbol || ""}
            min={Number(budget?.minimum_budget_value)}
            onChange={(e) => {
              const value = e.target.value;
              setBudgetByMonth((prev) => ({
                ...prev,
                current_budget: value,
              }));
              const initialPayload = {
                month: 0,
                budget: value,
                check_only_validation: true,
              };

              setPayload((prev) => {
                const index = prev.findIndex((p) => p.month === 0);
                if (index !== -1) {
                  prev.splice(index, 1, {
                    ...prev[index],
                    budget: Number(value),
                  });

                  return prev;
                } else {
                  return prev.concat(initialPayload);
                }
              });
            }}
          />
          <div className="budgetSubTitle">{"MONTHLY BUDGET (" + budget?.budget_symbol + ")"}</div>
          <div className="monthsGrid">
            {months.map((month) => {
              return (
                <div
                  key={`month_${month.month_index}_${month.month_name}`}
                  className="months"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "24px",
                  }}
                >
                  <div className="monthLabel">{month?.month_name}</div>
                  <TextInput
                    sx={{
                      width: "70px",
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                    min={Number(budget?.minimum_budget_value)}
                    value={
                      Number(budgetByMonth?.[`month_${month.month_index}`]) ||
                      ""
                    }
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value) {
                        if (
                          Number(value) < Number(budget?.minimum_budget_value)
                        ) {
                          enqueueSnackbar({
                            message: `Value should be greater than or equal to ${budget?.minimum_budget_value} for ${month.month_name}`,
                            variant: "error",
                          });
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBudgetByMonth((prev) => ({
                        ...prev,
                        [`month_${month.month_index}`]: value,
                      }));
                      const initialPayload = {
                        month: month.month_index,
                        budget: value,
                        check_only_validation: true,
                      };

                      setPayload((prev) => {
                        const index = prev.findIndex(
                          (p) => p.month === month.month_index
                        );
                        if (index !== -1) {
                          prev.splice(index, 1, {
                            ...prev[index],
                            budget: Number(value),
                          });

                          return prev;
                        } else {
                          return prev.concat(initialPayload);
                        }
                      });
                    }}
                    numberUnit=""
                  />
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          <CustomButton
            title="Apply"
            variant={"filled"}
            onClick={handleApply}
          />
          <CustomButton title="Cancel" variant={"outlined"} onClick={onClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default BudgetCapModal;
