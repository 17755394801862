import React, {useState} from "react";
import { Typography, Box, Grid } from "@mui/material";
import Section from "./Templates/Section";
import "./PMaxAdsCreativeAccordion.scss";
import {
    validateHeadline,
    validateDescription,
    validateBusinessName,
    validateFinaleUrl,
    validateLongHeadline
} from "../Utils/validators/adsCreativeValidators";
import {
    generateId
} from "../Utils/suggestions";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const PMaxAdsCreativeAccordion = ({
                                      headlines,
                                      setHeadlines,
                                      descriptions,
                                      setDescriptions,
                                      finalURL,
                                      setFinalURL,
                                      businessName,
                                      setBusinessName,
                                      longHeadlines,
                                      setLongHeadlines,
                                      errors = {}
                                  }) => {
    const [headlineStatus, setHeadlineStatus] = useState({});
    const [descriptionStatus, setDescriptionStatus] = useState({});
    const [businessNameStatus, setBusinessNameStatus] = useState(null);
    const [finalURLStatus, setFinalURLStatus] = useState(null);
    const [longHeadlineStatus, setLongHeadlineStatus] = useState({});

    const addDescription = () => setDescriptions([...descriptions, { id: generateId(), text: '' }]);
    const addHeadline = () => setHeadlines([...headlines, { id: generateId(), text: '' }]);
    const addLongHeadlines = () => setLongHeadlines([...longHeadlines, { id: generateId(), text: '' }]);

    const handleInputChange = (setter) => (id, key, value) => {
        setter((items) => items.map((item) =>
            item.id === id ? { ...item, [key]: value } : item
        ));
    };

    const handleRemove = (setter) => (id) => {
        setter((items) => items.filter((item) => item.id !== id));
    };

    const handleHeadlineBlur = async (id, text) => {
        if (!text.trim()) return;

        const result = await validateHeadline(text);
        setHeadlineStatus((prev) => ({
            ...prev,
            [id]: result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid headline" },
        }));
    };

    const handleDescriptionBlur = async (id, text) => {
        if (!text.trim()) return;

        const result = await validateDescription(text);
        setDescriptionStatus((prev) => ({
            ...prev,
            [id]: result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid description" },
        }));
    };

    const handleBusinessNameBlur = async (text) => {
        if (!text.trim()) return;

        const result = await validateBusinessName(text);
        setBusinessNameStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid business name" }
        );
    };

    const handleFinalURLBlur = async (text) => {
        if (!text.trim()) return;

        const result = await validateFinaleUrl(text);
        setFinalURLStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid final URL" }
        );
    };

    const handleLongHeadlineBlur = async (id, text) => {
        if (!text.trim()) return;

        const result = await validateLongHeadline(text);
        setLongHeadlineStatus((prev) => ({
            ...prev,
            [id]: result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid long headline" },
        }));
    };

    return (
        <Box className="pMaxAdsCreativeContainer">
            <Box className="property-section">
                <Box display="flex" alignItems="center" className="header-box">
                    <Typography variant="subtitle1">PROPERTY</Typography>
                </Box>
                <Box display="flex" gap={2} mt={1}>
                    <Box sx={{display: "flex", width: "100%"}} >
                        <CustomOutlinedInput
                            placeholder="Final URL"
                            value={finalURL}
                            onChange={(e) => setFinalURL(e.target.value)}
                            onBlur={() => handleFinalURLBlur(finalURL)}
                            className="no-border-focus"
                            fullWidth
                            minWidth="225px"
                            error={Boolean(errors.finalUrl) || (finalURLStatus && finalURLStatus.success === false)}
                            success={finalURLStatus && finalURLStatus.success === true}
                            helperText={
                                errors.finalUrl
                                    ? errors.finalUrl
                                    : finalURLStatus
                                        ? finalURLStatus.error || "Final URL"
                                        : "Final URL"
                            }
                        />
                        <Box sx={{width: "26px"}}/>
                    </Box>
                    <Box sx={{display: "flex", width: "100%"}} >
                        <CustomOutlinedInput
                            placeholder="Business Name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            onBlur={() => handleBusinessNameBlur(businessName)}
                            className="no-border-focus"
                            fullWidth
                            minWidth="225px"
                            error={Boolean(errors.businessName) || (businessNameStatus && businessNameStatus.success === false)}
                            success={businessNameStatus && businessNameStatus.success === true}
                            helperText={
                                errors.businessName
                                    ? errors.businessName
                                    : businessNameStatus
                                        ? businessNameStatus.error || "Business Name"
                                        : "Business Name"
                            }

                        />
                        <Box sx={{width: "26px"}}/>
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={2} alignItems="center">
                <Section
                    title="HEADLINE"
                    items={headlines}
                    addItem={addHeadline}
                    handleInputChange={handleInputChange(setHeadlines)}
                    handleRemove={handleRemove(setHeadlines)}
                    maxLengthInput={35}
                    onBlur={handleHeadlineBlur}
                    status={headlineStatus}
                    errorMessage={errors.headline}
                />
                <Section
                    title="DESCRIPTION"
                    items={descriptions}
                    addItem={addDescription}
                    handleInputChange={handleInputChange(setDescriptions)}
                    handleRemove={handleRemove(setDescriptions)}
                    maxLengthInput={90}
                    onBlur={handleDescriptionBlur}
                    status={descriptionStatus}
                    errorMessage={errors.description}
                />
                <Section
                    title="LONG HEADLINE"
                    items={longHeadlines}
                    addItem={addLongHeadlines}
                    handleInputChange={handleInputChange(setLongHeadlines)}
                    handleRemove={handleRemove(setLongHeadlines)}
                    maxLengthInput={90}
                    onBlur={handleLongHeadlineBlur}
                    status={longHeadlineStatus}
                    errorMessage={errors.longHeadline}
                />
            </Grid>
        </Box>
    );
};

export default PMaxAdsCreativeAccordion;
