import React from "react";
import { Box, Grid, Select } from "@mui/material";
import {BarChart, Bar, Tooltip, ResponsiveContainer, XAxis, YAxis, Cell} from "recharts";
import MenuItem from "@mui/material/MenuItem";
import { TopHotelsAvailableMetrics } from "../../Utils/AvailableMetrics";

function TopHotelsChart({ chartData, lastOption, setLastOption, currencySymbol, maxValue}) {
    const getSelectedValue = () => {
        const key = `${lastOption}_kpi`;
        return TopHotelsAvailableMetrics[key] ? key : "";
    };

    const greyData = chartData.map((entry) => ({
        name: entry.name,
        greyValue:  entry.maxValue
    }));

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const hotelName = payload[0]?.payload?.name;
            const hotelData = chartData.find(entry => entry.name === hotelName);

            if (hotelData) {
                const shouldShowCurrency = ["cost", "generated", "cpc"].includes(lastOption);
                return (
                    <div className="custom-tooltip">
                        <p>{`${hotelData.name} : ${shouldShowCurrency ? currencySymbol : ""}${hotelData.value}`}</p>
                    </div>
                );
            }
        }
        return null;
    };
    return (
        <Grid item xs={4} className="top-hotels-chart-column">
            {/* Dropdown */}
            <Grid item xs={12}>
                <Box className="top-hotels-dropdown-container">
                    <Select
                        id="metricDropdown"
                        value={getSelectedValue()}
                        onChange={(e) => setLastOption(e.target.value.replace("_kpi", ""))}
                        variant="outlined"
                        displayEmpty
                        sx={{
                            color: "black",
                            fontSize: "12px",
                            padding: "0",
                            border: "none",
                            borderRadius: "4px",
                            height: "28px",
                            fontWeight: 600,
                            background: "transparent",
                            display: "flex",
                            flexDirection: "row-reverse",
                            "& .MuiSelect-icon": {
                                color: "#BAB7B7",
                                position: "absolute",
                                left: "8px",
                                top: "50%",
                                transform: "translateY(-50%)",
                            },
                            "& .MuiSelect-select": {
                                padding: "0 8px 0 32px",
                                display: "flex",
                                alignItems: "center",
                            },
                            "& fieldset": { border: "none" },
                        }}
                    >
                        {Object.entries(TopHotelsAvailableMetrics).map(([key, label]) => (
                            <MenuItem
                                key={key}
                                value={key}
                                sx={{
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    lineHeight: "1.2",
                                    color: "black"
                                }}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Grid>

            {/* Bar Chart */}
            <Grid item xs={12}>
                <Box className="top-hotels-chart-container">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={chartData}
                            barGap={-23}
                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                        >
                            {/* Hidden Axes */}
                            <XAxis hide />
                            <YAxis hide allowDataOverflow={true} domain={[0, maxValue]} />

                            <Tooltip
                                cursor={{ fill: "transparent" }}
                                content={<CustomTooltip />}
                                wrapperStyle={{ pointerEvents: "auto" }}
                            />

                            {/* Dynamic Data Bars with Tooltip */}
                            <Bar
                                radius={[5, 5, 0, 0]}
                                dataKey="greyValue"
                                data={greyData}
                                barSize={23}

                                fill="#E0E0E0"
                                isAnimationActive={false}
                            />

                            {/* Colored Bars (draw after grey bars) */}
                            <Bar
                                dataKey="value"
                                barSize={23}
                                style={{ zIndex: 10 }}
                            >
                                {chartData.map((entry, index) => (
                                    <Cell
                                        key={`cell-colored-${index}`}
                                        fill={entry.fill || "#82ca9d"}
                                        radius={index === 0 ? [5, 5, 0, 0] : [0, 0, 0, 0]} // Only the first column has rounded radius
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </Grid>
    );
}

export default TopHotelsChart;