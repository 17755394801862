import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../../constants/api_urls";
import {getUserToken} from "../../../../../utils/functions/userToken";

export const validateHeadline = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-headline/`,
            { assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateLongHeadline = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-long-headline/`,
            { assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateDescription = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-description/`,
            { assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateBusinessName = async (assetContent) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-business-name/`,
            { assetContent },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateFinaleUrl = async (text) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/ads-creative/validate-one-final-url/`,
            { url: text },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};