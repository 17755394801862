import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign: [],
  segment_list: [],
  keywordsReportingSegmentList: [],
  searchTermReportingSegmentList: []
};
export const searchAdsSlice = createSlice({
  name: "searchAdsSlice",
  initialState: initialState,
  reducers: {
    updateCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
    updateKeywordsReportingSegmentList: (state,action) => {
      state.keywordsReportingSegmentList = action.payload
    },
    updateSearchTermReportingSegmentList: (state,action) => {
      state.searchTermReportingSegmentList = action.payload
    },
    resetSearchAds: () => initialState
  },
});

export const {
  updateCampaign,
  updateSegmentList,
  updateKeywordsReportingSegmentList,
  updateSearchTermReportingSegmentList,
  resetSearchAds
} = searchAdsSlice.actions;
export default searchAdsSlice.reducer;
