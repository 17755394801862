import axios from 'axios';
import { getUserToken } from "../../../utils/functions/userToken";

export const fetchService = async (
  API_URL,
  API_METHOD = 'GET',
  API_BODY = null
) => {

  const token = getUserToken();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const axiosMethods = {
    GET: () => axios.get(API_URL, config),
    POST: () => axios.post(API_URL, API_BODY, config),
    PATCH: () => axios.patch(API_URL, API_BODY, config),
    PUT: () => axios.put(API_URL, API_BODY, config),
    DELETE: () => axios.delete(API_URL, config),
  };
  
  try {
    const axiosMethod = axiosMethods[API_METHOD] || axiosMethods['GET'];
    const response = await axiosMethod();
    return handleResponse(response);

  } catch (error) {
    return handleException(error);
  }
};

 const processErrors = (error) => {
  if (Array.isArray(error)) {
    return error.map((err) => ({
      code: err.code || 'UNKNOWN_ERROR',
      message: err.message || 'An error occurred',
      ...err,
    }));
  }
  return [
    {
      code: error?.code || 'UNKNOWN_ERROR',
      message: error?.message || 'An error occurred',
    },
  ];
};

  const handleResponse = (response) => {
    const { status_code, data, error } = response.data;

    if (status_code === 200) {
      return {
        success: true,
        status_code,
        message: data?.message || 'Success',
        data: data || {},
      };
    }

    return {
      success: false,
      status_code,
      data: {},
      errors: processErrors(error),
    };
  };

const handleException = (error) => {
  if (error.response) {
    const { status, data } = error.response;

    if (status === 401) {
      return {
        success: false,
        status_code: status,
        data: {},
        errors: [{ code: 'UNAUTHORIZED', message: 'Unauthorized access' }],
      };
    }

    return {
      success: false,
      status_code: status,
      data: {},
      errors: processErrors(data?.error || [{ message: data?.message || 'Server error occurred' }]),
    };
  }

  return {
    success: false,
    status_code: 502,
    data: {},
    errors: [{ code: 'NETWORK_ERROR', message: 'Network or server error occurred' }],
  };
};
