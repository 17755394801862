import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select,
    MenuItem, Stack, Typography, CircularProgress
} from '@mui/material';
import {APP_RESERVATIONS_API_ENDPOINT} from "constants/api_urls";
import {RESERVATION_COLUMNS, TOOLTIP_TEXT} from "features/reservations/reservationColumns";
import {BOOKING_STATUS_OPTIONS} from "features/reservations/reservationFilterOptions";
import ResSearch from "../ResSearch";
import {useState} from "react";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import {formatDateToISO} from "features/reservations/reservationDateRange";
import Snackbar from '@mui/material/Snackbar';
import {Alert} from "@mui/lab";
import dayjs from 'dayjs';
import './resTable.scss';
import CustomPagination from "../../../components/CustomPagination";
import { getUserToken } from "../../../utils/functions/userToken";
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";
import Icons from "../../../components/DmpIcons/icons";
import DateInputWithCustomIcon from "../../../components/DateInputWithCustomIcon/DateInputWithCustomIcon";

export default function ReservationsTable({
                                              loading,
                                              idAccount,
                                              accountCurrency,
                                              handleSearch,
                                              fetchData,
                                              rows,
                                              page,
                                              resetPage
                                          }) {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name_hotel'); // default sorting by "Hotel Name"
    const [openModal, setOpenModal] = useState(false); // Manage modal visibility
    const [editingField, setEditingField] = useState(null); // Track which field is being edited
    const [editingFieldLabel, setEditingFieldLabel] = useState(null); // Track which field is being edited
    const [fieldValue, setFieldValue] = useState(''); // Track new value
    const [codeResa, setCodeResa] = useState(''); // State for code_resa
    const [currency, setCurrency] = useState(''); // State for code_resa
    const [idUser, setIdUser] = useState(''); // State for id_user
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const totalPages = Math.max(Math.ceil(rows.length / rowsPerPage) - 1, 1);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        resetPage(newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    // Handle opening the modal
    const handleOpenModal = (row, field, label, value) => {
        // Only allow modal to open if the row is not disabled
        if (row.line_disabled) {
            return;
        }

        setEditingField(field);
        setEditingFieldLabel(label);
        setFieldValue(value); // Set the current value of the field
        setCodeResa(row.code_resa); // Set the code_resa value
        setCurrency(row.currency); // Set the currency
        setIdUser(row.id_user); // Set the id_user value
        setOpenModal(true);
    };

    const handleFieldChange = (e) => {
        const newValue = e.target.value; // Get the new value from the text field
        setFieldValue(newValue); // Update the field value
        setIdUser(idUser); // Set the id_user value
        setCodeResa(codeResa); // Set the code_resa value
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSaveModal = async () => {
        const token = getUserToken();
        if (!token) {
            return;
        }
        let url;
        const params = {
            id_account: idAccount,
            id_user: idUser,
            code_resa: codeResa,
        };

        switch (editingField) {
            case 'amount':
                params.amount = fieldValue;
                url = `${APP_RESERVATIONS_API_ENDPOINT}/update-amount`;
                break;
            case 'check_in_date':
                params.check_in_date = formatDateToISO(fieldValue);
                url = `${APP_RESERVATIONS_API_ENDPOINT}/update-check-in`;
                break;
            case 'check_out_date':
                params.check_out_date = formatDateToISO(fieldValue);
                url = `${APP_RESERVATIONS_API_ENDPOINT}/update-check-out`;
                break;
            case 'booking_status':
                params.booking_status = fieldValue;
                url = `${APP_RESERVATIONS_API_ENDPOINT}/update-status`;
                break;
            default:
                console.error('Unknown field:', editingField);
                return;
        }

        try {
            await axios.put(url, null, {
                headers: {Authorization: `Bearer ${token}`,}, params: params,
            });
            //--To Load the refreshed Data
            fetchData();
            setOpenModal(false);
            setSeverity('success');
            setSnackbarMessage('Updated successfully!');
            setOpenSnackbar(true);
        } catch (error) {
            const errorMessage = error.response?.data?.error_message || 'Failed to update. Please try again.';
            setSeverity('error');
            setSnackbarMessage(errorMessage);
            setOpenSnackbar(true);
        }
    };

    const getStatusLabel = (value) => {
        const status = BOOKING_STATUS_OPTIONS.find((option) => option.value === value);
        return status ? status.label : value; // Return label if found, otherwise return the key itself
    };

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle null or undefined values
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const sortedRows = rows.slice().sort(getComparator(order, orderBy));
    // Calculate the total number of nights
    const lengthOfStay = sortedRows.reduce((sum, reservation) => sum + (reservation.nights || 0), 0);
    const totalAmount = parseFloat((sortedRows.reduce((sum, reservation) => sum + (reservation.amount_account_currency || 0), 0)).toFixed(2));
    const handleDateChange = (newValue) => {
        setFieldValue(newValue);
    };
    const calculateColumnWidth = (label) => {
        const baseWidth = 148;
        const charWidth = 10;
        return Math.max(baseWidth, label.length * charWidth);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        resetPage(1);
    };

    return (
        <>
            <Paper className="paper">
                <Box className="header">
                    <ResSearch
                        resetPage={resetPage}
                        onSearch={handleSearch}/>
                </Box>
                <Box sx={{position: 'relative', width: '100%'}}>
                    {/* Loader Container */}
                    {loading && (
                        <Box className="loader">
                            <CircularProgress/>
                            <Typography variant="body2" sx={{marginLeft: 1}}>Loading...</Typography>
                        </Box>
                    )}

                    <Box className='reservationContainerBox' sx={{overflowX: 'auto', width: '100%'}}>
                        <TableContainer sx={{width: '2120px'}}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow sx={{height: '45px'}}>
                                        {RESERVATION_COLUMNS.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={orderBy === column.id ? order : false}
                                                sx={{
                                                    padding: '4px 8px',
                                                    fontSize: '12px',
                                                    fontWeight:'600',
                                                    color:'#000000',
                                                    textAlign: column.id === 'name_hotel' ? 'left' : 'right',
                                                    width: calculateColumnWidth(column.label)
                                                }}
                                            >
                                                <Tooltip
                                                    title={
                                                        TOOLTIP_TEXT.find((tooltip) => tooltip.id === column.id)?.label || column.id
                                                    }
                                                    placement="bottom">
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={() => handleRequestSort(column.id)}
                                                        sx={{
                                                            paddingLeft:
                                                                column.id === 'name_hotel'
                                                                    ? '10px'
                                                                    : '0px',
                                                            display: 'flex',
                                                            flexDirection:
                                                                column.id === 'market_code'
                                                                    ? ''
                                                                    : column.id === 'name_hotel'
                                                                        ? ''
                                                                        : 'row-reverse',
                                                            justifyContent:
                                                                column.id === 'market_code'
                                                                    ? 'center'
                                                                    : column.id === 'name_hotel'
                                                                        ? 'flex-start'
                                                                        : ''
                                                        }}>
                                                        {column.id === 'amount_account_currency' && <span style={{
                                                            marginLeft: '4px',
                                                            fontSize: '12px'
                                                        }}>{accountCurrency}</span>}
                                                        {column.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedRows.length === 0 ? (
                                        <TableRow sx={{height: '45px'}}> {/* Set fixed empty row height */}
                                            <TableCell colSpan={RESERVATION_COLUMNS.length}
                                                       sx={{textAlign: 'center', padding: 2}}>
                                                <Typography sx={{fontSize: '12px', paddingRight: "50px"}}>No records
                                                    available</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <>
                                            {sortedRows
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => (
                                                    <TableRow
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code_resa}
                                                        sx={{
                                                            height: '45px',
                                                            '&:hover': {
                                                                backgroundColor: '#FAFAFA',
                                                            }
                                                        }}>

                                                        {RESERVATION_COLUMNS.map((column) => {
                                                            let value = row[column.id];
                                                            if (['booking_datetime', 'check_in_date', 'check_out_date', 'metaclick_datetime'].includes(column.id)) {
                                                                value = formatDate(value);
                                                            }

                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    sx={{
                                                                        fontFamily:'Roboto',
                                                                        fontWeight:'400',
                                                                        padding: '4px 8px',
                                                                        textAlign: 'right',
                                                                        fontSize: '12px',
                                                                        color: row.line_disabled ? '#BA7BABA' : '#000000',
                                                                    }}
                                                                >
                                                                    {(['amount', 'check_in_date', 'check_out_date', 'booking_status'].includes(column.id) && !row.line_disabled) ? (
                                                                        <Box display="flex" alignItems="center"
                                                                             justifyContent="flex-end"> {/* Flexbox to align value and label */}
                                                                            <TextField
                                                                                fullWidth
                                                                                value={getStatusLabel(value)}
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                onClick={() => handleOpenModal(row, column.id, column.label, value)}  // Open modal on click
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                    maxWidth: '85px',
                                                                                    height: '25px',
                                                                                }}
                                                                                inputProps={{
                                                                                    style: {
                                                                                        fontFamily:"Roboto",
                                                                                        textAlign: 'right',
                                                                                        padding: '0px 6px',
                                                                                        marginTop: '2px',
                                                                                        height: '20px',
                                                                                        color: row.line_disabled ? '#BA7BABA' : '#000000',
                                                                                        fontSize: '12px',
                                                                                    },
                                                                                }}
                                                                            />

                                                                            {/* Display currency label outside the TextField */}
                                                                            {column.id === 'amount' && <span style={{
                                                                                marginLeft: '4px'
                                                                            }}>{row.currency}</span>}
                                                                        </Box>
                                                                    ) : (

                                                                        <Box sx={{
                                                                            paddingLeft:
                                                                                column.id === 'name_hotel'
                                                                                    ? '10px'
                                                                                    : '0px',
                                                                            display: 'flex',
                                                                            justifyContent:
                                                                                column.id === 'market_code'
                                                                                    ? 'center'
                                                                                    : column.id === 'name_hotel'
                                                                                        ? 'flex-start'
                                                                                        : 'flex-end',
                                                                            textAlign: column.id === 'name_hotel'
                                                                                ? 'left'
                                                                                : 'right'
                                                                        }}
                                                                        >
                                                                            {column.id === 'booking_status' ? getStatusLabel(value) : value}
                                                                            {column.id === 'amount_account_currency' &&
                                                                                <span style={{
                                                                                    marginLeft: '4px',
                                                                                }}>{row.account_currency}</span>}
                                                                            {column.id === 'amount' &&
                                                                                <span style={{
                                                                                    marginLeft: '4px',
                                                                                }}>{row.currency}</span>}
                                                                        </Box>

                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                        </>
                                    )}

                                    {/* TOTAL SELECTION ROW */}
                                    <TableRow sx={{width: '150%', height: '45px', '&:hover': {bgcolor: '#F2F2F2'}}}>
                                        <TableCell colSpan={RESERVATION_COLUMNS.findIndex((col) => col.id === 'nights')}
                                                   className="totalSelection"
                                                   sx={{
                                                       padding: '4px 8px 0px 5px',
                                                       fontSize: '12px',fontWeight:'600',
                                                   }}
                                        >
                                            TOTAL SELECTION
                                        </TableCell>
                                        <TableCell sx={{padding: '4px 8px', textAlign: 'right', fontSize: '12px',fontWeight:'600'}}>
                                            {lengthOfStay}
                                        </TableCell>
                                        {/* Skipping some cells if needed */}
                                        {RESERVATION_COLUMNS.slice(RESERVATION_COLUMNS.findIndex((col) => col.id === 'nights') + 1, RESERVATION_COLUMNS.findIndex((col) => col.id === 'amount_account_currency')).map(() => (
                                            <TableCell sx={{
                                                padding: '4px 8px',
                                                textAlign: 'right',
                                                fontSize: '12px',
                                            }}></TableCell>
                                        ))}
                                        <TableCell sx={{padding: '4px 8px', textAlign: 'right', fontSize: '12px',fontWeight:'600'}}>
                                            {totalAmount} {accountCurrency}
                                        </TableCell>
                                        <TableCell colSpan={7}></TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>

                {/* HotelTable for editing */}
                <Dialog className="reservations" open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogTitle className="rsvDialogTitle">Change {editingFieldLabel}
                        <IconButton
                            onClick={() => setOpenModal(false)} // Close the modal on click
                            className="modalCloseIcon"
                            sx={{color: (theme) => theme.palette.grey[500]}}
                        >
                            <CloseIcon sx={{fontSize: '16px', margin:"10px"}}/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{width: '319px', height: "58px",padding:"0 24px" }}>
                        {editingField === 'booking_status' ? (
                            <Select
                                fullWidth
                                value={fieldValue}
                                onChange={(e) => setFieldValue(e.target.value)}
                                sx={{
                                    marginTop: '5px',
                                    width: '150px',
                                    height: '28px',
                                    '& .MuiSelect-icon': {
                                        color: '#BAB7B7',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '0 0 0 10px',
                                        fontSize: '12px',  // Reduced font size
                                        height: '25px', // Adjust padding for Select input
                                    },
                                }}
                            >
                                {BOOKING_STATUS_OPTIONS.map((status) =>
                                        status.value !== '' && (
                                            <MenuItem
                                                sx={{
                                                    padding: '4px 10px',
                                                    fontSize: '12px',  // Reduced font size
                                                    height: '25px', // Adjust padding for Select input
                                                }}
                                                key={status.value} value={status.value}>
                                                {status.label}
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                        ) : (

                            <>
                                {editingField === 'check_in_date' || editingField === 'check_out_date' ? (
                                    <div>
                                        <DateInputWithCustomIcon
                                            format={'DD/MM/YYYY'}
                                            value={dayjs(fieldValue)}
                                            onChange={handleDateChange}
                                            icon={Icons.ActionsIcons.CalendarIcon}
                                        />
                                        {/*<DateInput onChange={handleDateChange}*/}
                                        {/*           value={dayjs(fieldValue)}/> /!*add format="MMM DD, YYYY" to change to date format*!/*/}
                                    </div>

                                ) : (
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                                        <TextField
                                            placeholder="value"
                                            fullWidth
                                            value={fieldValue}
                                            onChange={handleFieldChange}
                                            sx={{
                                                width: '120px',height:'28px',
                                                '& .MuiInputBase-input': {
                                                    padding: '0 8px 0 10px',  // Adjust padding for better alignment
                                                    fontSize: '12px',
                                                    height: '27px',  // Increase the height for a better look
                                                },
                                            }}
                                        />
                                        {editingField === 'amount' && (
                                            <span className='currencyLabel'>{currency}</span>
                                        )}
                                    </div>
                                )}
                            </>

                        )}
                    </DialogContent>
                    <DialogActions className="dialogActionsBtns">
                        <CustomButtonWithIcon
                            color="#b0acac"
                            hoverColor="#12794F"
                            titleColor="#373F41"
                            hoverTitleColor={"white"}
                            width={"100px"}
                            height={"24px"}
                            borderRadius="20px"
                            textStyle={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '14.52px',
                                textAlign: 'center',
                            }}
                            onClick={handleSaveModal}
                        >
                            Apply
                        </CustomButtonWithIcon>

                        <CustomButtonWithIcon
                            color="#b0acac"
                            hoverColor="#12794F"
                            titleColor="#373F41"
                            hoverTitleColor={"white"}
                            width={"100px"}
                            height={"24px"}
                            borderRadius="20px"
                            textStyle={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '14.52px',
                                textAlign: 'center',
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            Cancel
                        </CustomButtonWithIcon>

                    </DialogActions>
                </Dialog>
            </Paper>
            {/*Add Pagination component*/}
            {totalPages > 1 && (
                <Stack sx={{width: '2120 px', padding: '0 14px 0 24px'}}>
                    <CustomPagination
                        pageCount={totalPages}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                    />
                </Stack>
            )}
            {/*Snackbar to show response message*/}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackbarMessage}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={handleClose} severity={severity}
                       sx={{width: '100%', marginLeft: '4px', marginTop: '20px'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
