import React from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import './Modal.scss';

const DeleteConfirmationModal = ({ open, onClose, onConfirm, alertName, loading, error }) => {
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
                } else {
                    onClose();
                }
            }}
        >
            <DialogTitle sx={{fontSize: "12px", fontWeight: 400, fontFamily: "Inter", backgroundColor: "#FAFAFA"}} >Delete Alert</DialogTitle>
            <DialogContent sx={{backgroundColor: "#FAFAFA"}} >
                <DialogContentText sx={{fontSize: "12px", fontWeight: 400, fontFamily: "Inter"}} >
                    Are you sure you want to delete this alert?
                </DialogContentText>

                {/* Message d'erreur en rouge, le cas échéant */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </DialogContent>
            <DialogActions sx={{backgroundColor: "#FAFAFA"}} >
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={(event) => {
                                onClose();
                            }}
                            className='cancelBtn'
                            sx={{fontSize: "10", fontWeight: 400, fontFamily: "Inter"}}
                        >
                            Cancel
                        </Button>
                        <Button
                            type={"button"}
                            variant="outlined"
                            onClick={(event) => {
                                onConfirm();
                            }}
                            className='deleteBtn'
                        >
                            Delete
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
