// alertUtils.js

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Function to format date into a readable format
export const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};

// Function to concatenate email addresses with a comma and space
export const formatEmails = (emailArray) => {
    return emailArray.join(', ');
};

// Function to format alert data
export const formatAlertData = (data) => {
    return data.map(alert => ({
        ...alert,
        status: capitalizeFirstLetter(alert.status),
        created_date: formatDate(alert.created_date),
        updated_date: formatDate(alert.updated_date),
      //  alert_recipients: formatEmails(alert.alert_recipients),
    }));
};


export function sqlToJsonLogic(sqlWhereClause) {

    if (!sqlWhereClause) {
        return null;
    }

    // Tokenizer
    function tokenize(str) {
        const tokens = [];
        const regex = /\s*(<=|>=|!=|<>|=|<|>|\(|\)|AND|OR|[A-Za-z_][A-Za-z0-9_]*|\d+(\.\d+)?|'[^']*'|"[^"]*")\s*/gi;
        let match;
        while ((match = regex.exec(str)) !== null) {
            tokens.push(match[1]);
        }
        return tokens;
    }

    // Parser
    function parse(tokens) {
        let pos = 0;

        function parseExpression() {
            let node = parseTerm();
            while (match('OR')) {
                pos++;
                let right = parseTerm();
                node = { 'or': [node, right] };
            }
            return node;
        }

        function parseTerm() {
            let node = parseFactor();
            while (match('AND')) {
                pos++;
                let right = parseFactor();
                node = { 'and': [node, right] };
            }
            return node;
        }

        function parseFactor() {
            if (match('(')) {
                pos++;
                let node = parseExpression();
                if (match(')')) {
                    pos++;
                    return node;
                } else {
                    throw new Error("Parenthèse fermante attendue");
                }
            } else {
                return parseComparison();
            }
        }

        function parseComparison() {
            let left = parseValue();
            if (match('=', '!=', '<>', '<=', '>=', '<', '>')) {
                let operator = tokens[pos++];
                let right = parseValue();
                let opMap = {
                    '=': '==',
                    '!=': '!=',
                    '<>': '!=',
                    '<=': '<=',
                    '>=': '>=',
                    '<': '<',
                    '>': '>'
                };
                let op = opMap[operator];
                let node = {};
                node[op] = [{ 'var': left }, isNaN(right) ? right : parseFloat(right)];
                return node;
            } else {
                throw new Error("Opérateur de comparaison attendu");
            }
        }

        function parseValue() {
            let token = tokens[pos];
            if (/^\d+(\.\d+)?$/.test(token)) {
                pos++;
                return token;
            } else if (/^[A-Za-z_][A-Za-z0-9_]*$/.test(token)) {
                pos++;
                return token;
            } else if ((/^'[^']*'$/.test(token)) || (/^"[^"]*"$/.test(token))) {
                pos++;
                return token.slice(1, -1);
            } else {
                throw new Error("Jeton inattendu : " + token);
            }
        }

        function match(...args) {
            if (pos < tokens.length) {
                let token = tokens[pos].toUpperCase();
                for (let arg of args) {
                    if (token === arg.toUpperCase()) {
                        return true;
                    }
                }
            }
            return false;
        }

        let result = parseExpression();
        if (pos < tokens.length) {
            throw new Error("Jeton inattendu : " + tokens[pos]);
        }
        return result;
    }

    const tokens = tokenize(sqlWhereClause);
    return parse(tokens);
}

