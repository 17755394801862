import {FormControl, Select} from '@mui/material';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';

const CustomFormControl = ({
                               value,
                               onChange,
                               displayEmpty,
                               renderValue,
                               multiple,
                               children,
                               error,
                               helperText,
                               helperTextFontSize="12px",
                               MenuProps,
                               minWidth= "180px",
                               ...props
                           }) => {
    const success = !error && Boolean(value);
    const getHelperTextColor = () => {
        if (error) return '#d32f2f';
        if (success) return 'green';
        return '#BAB7B7';
    };
    return (
        <FormControl
            sx={{ minWidth: minWidth }}
            size="small"
            error={error}
            {...props}
        >
            <Select
                error={error}
                value={value}
                onChange={onChange}
                displayEmpty={displayEmpty}
                renderValue={renderValue}
                multiple={multiple}
                MenuProps={MenuProps}
                sx={{
                    height: "28px",
                    fontSize: "12px",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    backgroundColor: "white",
                    '& .MuiSelect-icon': {
                        color: '#BAB7B7',
                    },
                    "& .MuiSelect-select": {
                        padding: "4px 2px 3px 2px",
                        paddingLeft: "10px",
                    },
                    // Bordure par défaut
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: error ? '#d32f2f' : '#C4C4C4',
                    },
                    // Changez la couleur de la bordure au survol
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: error ? '#d32f2f' : '#61D5A9',
                    },
                    // Changez la couleur de la bordure lorsqu'il est focus
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: error ? '#d32f2f' : '#61D5A9',
                    },
                }}
            >
                {children}
            </Select>
            {helperText && (
                <span style={{ color: getHelperTextColor(), fontSize: helperTextFontSize, marginTop: '4px', paddingLeft: "5px" }}>
          {helperText}
        </span>
            )}
        </FormControl>
    );
};

export default CustomFormControl;
