import { useCallback, useState, useMemo } from "react";
import { createAccountDatePayload } from "../payload/accountDatePayload";
import { createChannelAccountDatePayload } from "../payload/channelAccountDatePayload";
import { getKpiReporting } from "../api/kpiReporting";
import { getMetasearchReporting } from "../api/metasearchReporting";
import { getChartsReporting } from "../api/chartsReporting";
import { getDeviceReporting } from "../api/deviceReporting";
import { getTopHotelsReporting } from "../api/topHotelsReporting";
import { getTopMarketsReporting } from "../api/topMarketsReporting";

/**
 * Custom hook to fetch and manage metrics data from various APIs.
 *
 * @module useFetchMetricsData
 * @param {string} idaccount - The account ID used for API calls.
 * @param {Object} calender - The calendar date range used for filtering API requests.
 * @returns {Object} An object containing state variables, functions for fetching data, and handling dropdown changes.
 */
export const useFetchMetricsData = (idaccount, calender) => {
    // State management
    const [metrics, setMetrics] = useState([]);
    const [reportingMetrics, setReportingMetrics] = useState([]);
    const [chartsMetrics, setChartsMetrics] = useState([]);
    const [deviceMetrics, setDeviceMetrics] = useState([]);
    const [topHotelsMetrics, setTopHotelsMetrics] = useState([]);
    const [topMarketsMetrics, setTopMarketsMetrics] = useState([]);
    const [errorsMessage, setErrorsMessage] = useState({});

    /**
     * Creates an account date payload based on the account ID and calendar date range.
     * Memoized to avoid recalculations on every render.
     */
    const accountDatePayload = useMemo(
        () => createAccountDatePayload(idaccount, calender),
        [idaccount, calender]
    );

    /**
     * Handles API errors by updating the relevant error message in the state.
     *
     * @param {string} section - The section where the error occurred (e.g., "device", "topHotels").
     * @param {string} error - The error message returned from the API.
     */
    const handleApiError = (section, error) => {
        setErrorsMessage((prev) => ({ ...prev, [section]: error }));
    };

    /**
     * Fetches data from a specific API and updates the corresponding state.
     *
     * @param {Function} fetchFunc - The API function to call.
     * @param {Function} setFunc - The state setter function to update the relevant data.
     * @param {string} errorKey - The key for storing error messages in the state.
     * @param {Object} payload - The payload to pass to the API function.
     */
    const fetchSectionData = useCallback(async (fetchFunc, setFunc, errorKey, payload) => {
        try {
            const response = await fetchFunc(payload);
            if (response.success) setFunc(response.data);
            else handleApiError(errorKey, response.error);
        } catch (error) {
            handleApiError(errorKey, error.message);
        }
    }, []);

    /**
     * Fetches all required metrics data from APIs and updates the corresponding states.
     */
    const fetchData = useCallback(() => {
        // Reset all data and errors
        setMetrics([]);
        setReportingMetrics([]);
        setChartsMetrics([]);
        setDeviceMetrics([]);
        setTopHotelsMetrics([]);
        setTopMarketsMetrics([]);
        setErrorsMessage({});

        // Fetch all data sections
        fetchSectionData(getKpiReporting, setMetrics, "kpi", accountDatePayload);
        fetchSectionData(getMetasearchReporting, setReportingMetrics, "reporting", accountDatePayload);
        fetchSectionData(getChartsReporting, setChartsMetrics, "charts", accountDatePayload);

        fetchSectionData(
            getDeviceReporting,
            setDeviceMetrics,
            "device",
            createChannelAccountDatePayload(idaccount, "GHA_META", calender)
        );
        fetchSectionData(
            getTopHotelsReporting,
            setTopHotelsMetrics,
            "topHotels",
            createChannelAccountDatePayload(idaccount, "GHA_META", calender)
        );
        fetchSectionData(
            getTopMarketsReporting,
            setTopMarketsMetrics,
            "topMarkets",
            createChannelAccountDatePayload(idaccount, "GHA_META", calender)
        );
    }, [accountDatePayload, fetchSectionData, idaccount, calender]);

    /**
     * Handles dropdown changes by resetting the corresponding state and fetching new data.
     *
     * @param {Object} event - The change event from the dropdown.
     * @param {string} section - The section being updated (e.g., "device", "topHotels", "topMarkets").
     */
    const handleDropdownChange = useCallback(
        async (event, section) => {
            const newChannel = event.target.value;
            const payload = createChannelAccountDatePayload(idaccount, newChannel, calender);

            // Reset the relevant state
            const resetFunctions = {
                device: setDeviceMetrics,
                topHotels: setTopHotelsMetrics,
                topMarkets: setTopMarketsMetrics,
            };
            resetFunctions[section]([]);

            // Fetch updated data
            const fetchFunctions = {
                device: getDeviceReporting,
                topHotels: getTopHotelsReporting,
                topMarkets: getTopMarketsReporting,
            };
            const errorKeys = {
                device: "device",
                topHotels: "topHotels",
                topMarkets: "topMarkets",
            };

            await fetchSectionData(
                fetchFunctions[section],
                resetFunctions[section],
                errorKeys[section],
                payload
            );
        },
        [fetchSectionData, idaccount, calender]
    );

    return {
        metrics,
        reportingMetrics,
        chartsMetrics,
        deviceMetrics,
        topHotelsMetrics,
        topMarketsMetrics,
        errorsMessage,
        fetchData,
        handleDropdownChange,
    };
};
