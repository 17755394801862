import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import {DatePicker} from "../../components/DatePicker";
import MetricsComponent from "../../features/new-homepage/MetricsComponent";
import ReportingMetricsTable from "../../features/new-homepage/ReportingMetricsTable";
import ChartComponent from "../../features/new-homepage/ChartComponent";
import DeviceComponent from "../../features/new-homepage/DeviceComponent";
import TopHotelsComponent from "../../features/new-homepage/TopHotelsComponent";
import TopMarketsComponent from "../../features/new-homepage/TopMarketsComponent";
import TableContainer from "@mui/material/TableContainer";

import {useFetchMetricsData} from "./utils/hooks/useFetchMetricsData";

import "./new-homepage.scss";

const NewHomepage = () => {
  const { idaccount } = useParams();
  const { calender } = useSelector((state) => state);

  const [deviceIdChannel, setDeviceIdChannel] = useState("GHA_META");
  const [topHotelsIdChannel, setTopHotelsIdChannel] = useState("GHA_META");
  const [topMarketsIdChannel, setTopMarketsIdChannel] = useState("GHA_META");
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);

  const handleDeviceChange = (event) => {
    const newChannel = event.target.value;
    setDeviceIdChannel(newChannel);
    handleDropdownChange(event, "device");
  };

  const handleTopHotelsChange = (event) => {
    const newChannel = event.target.value;
    setTopHotelsIdChannel(newChannel);
    handleDropdownChange(event, "topHotels");
  };

  const handleTopMarketsChange = (event) => {
    const newChannel = event.target.value;
    setTopMarketsIdChannel(newChannel);
    handleDropdownChange(event, "topMarkets");
  };

  const {
    metrics,
    reportingMetrics,
    chartsMetrics,
    deviceMetrics,
    topHotelsMetrics,
    topMarketsMetrics,
    errorsMessage,
    handleDropdownChange,
    fetchData,
  } = useFetchMetricsData(idaccount, calender);

  useEffect(() => {
    if (calender?.currentDateRange?.startDate && calender?.currentDateRange?.endDate) {
      fetchData();
    }
  }, [
    calender.currentDateRange.startDate,
    calender.currentDateRange.endDate,
    calender.comparitionDateRange.startDate,
    calender.comparitionDateRange.endDate,
    idaccount,
    fetchData,
  ]);

  return (
      <DefaultLayout>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: "18px", marginLeft: "2px" }}>
          <PageTitles pageTitle="Digital Marketing Platform" />
          <DatePicker />
        </Box>

        <Box sx={{ backgroundColor: "#FAFAFA", pt: "14px", paddingInline: "18px" }}>
          <MetricsComponent metrics={metrics} calender={calender} errorMessage={errorsMessage.kpi} />

          <Grid container spacing="14px" sx={{ pt: "14px" }}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <ReportingMetricsTable metrics={reportingMetrics} errorMessage={errorsMessage.reporting} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                {!isChartCollapsed && (
                    <ChartComponent
                        menuOptions={chartsMetrics.metrics}
                        comparitionData={chartsMetrics.compareChartData}
                        rawChartData={chartsMetrics.chartData}
                        axisData={chartsMetrics.chartAxisDate}
                        errorMessage={errorsMessage.charts}
                    />
                )}
                <TableContainer setIsChartCollapsed={setIsChartCollapsed} isChartCollapsed={isChartCollapsed} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing="14px" sx={{pb: 5, paddingTop: "14px"}}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <DeviceComponent
                  selectedOption={deviceIdChannel}
                  handleDropdownChange={handleDeviceChange}
                  deviceMetrics={deviceMetrics}
                  errorMessage={errorsMessage.device}
              />
              <TopHotelsComponent
                  selectedOption={topHotelsIdChannel}
                  handleDropdownChange={handleTopHotelsChange}
                  topHotelsMetrics={topHotelsMetrics}
                  errorMessage={errorsMessage.topHotels}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <TopMarketsComponent
                  selectedOption={topMarketsIdChannel}
                  handleDropdownChange={handleTopMarketsChange}
                  topMarketsMetrics={topMarketsMetrics}
                  errorMessage={errorsMessage.topMarkets}
              />
            </Grid>
          </Grid>
        </Box>
      </DefaultLayout>
  );
};

export default NewHomepage;