export const isEmpty = (value) =>
    !value ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0);

export const validateBiddingOptionsPayload = (updateBidPayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.bidStrategy = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type);
    errors.baseBid = !isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && ['MANUAL'].includes(updateBidPayload?.bidding_strategy?.bidding_type) &&
            isEmpty(updateBidPayload?.bidding_strategy?.bidding_value);
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateBiddingRulesPayload = (updateBidPayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.bidding_rule_id = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type);
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateMultipliersPayload = (updateBidPayload,setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.multiplier = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type)
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};
