export const isEmpty = (value) =>
    !value ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0);


export const validateBiddingOptionsPayload = (updateBidPayload, updateBidMobilePayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL_MARKET' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP_MARKET' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.bidding_type_desktop = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type);
    errors.bidding_type_mobile = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type);
    errors.bidding_value_desktop = !isEmpty(updateBidPayload?.bidding_strategy?.bidding_type) &&
        ['MIN_BID_EXTRA_BID'].includes(updateBidPayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidPayload?.bidding_strategy?.bidding_value);
    errors.bidding_value_mobile = !isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        ['MIN_BID_EXTRA_BID'].includes(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_value);

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};


export const validateExtraBidPayload = (updateBidPayload, extraBidPayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    const isEmptyMultiplier = extraBidPayload.length === 0;
    const hasMissingValues = extraBidPayload.filter((p) => p?.bidding_strategy?.bidding_value).length === 0;
    if (isEmptyMultiplier || hasMissingValues) {
        errors.EXTRA_BID = true;
        errors.MAX_BID = true;
    }
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateMultipliersPayload = (updateBidPayload, multiplierPayload, activeButton, setErrors) => {
    const errors = {};
    errors.hotelId = isEmpty(updateBidPayload?.hotel_id);
    errors.device_identifier = isEmpty(updateBidPayload?.device_identifier);

    const isEmptyMultiplier = multiplierPayload.length === 0;
    const hasMissingValues =
        (activeButton === "CHECKIN_DAY" || activeButton === "WEEK_END_STAY") &&
        multiplierPayload.filter((p) => p?.bidding_strategy?.multiplier_value).length === 0;

    if (isEmptyMultiplier || hasMissingValues) {
        switch (activeButton) {
            case "DAYS_TO_ARRIVAL":
                errors.days = true;
                errors.bidMultiplier = true;
                break;

            case "LENGTH_OF_STAY":
                errors.numberOfNights = true;
                errors.multiplierValue = true;
                break;

            case "WEEK_END_STAY":
                errors.STAY_INCLUDE_SATURDAY_NIGHT = true;
                errors.STAY_EXCLUDE_SATURDAY_NIGHT = true;
                break;

            default:
                break;
        }
    }

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};