import "./export.scss";
import DateInputWithCustomIcon from "components/DateInputWithCustomIcon/DateInputWithCustomIcon";
import {Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, {useState} from 'react';
import axios from 'axios';
import {APP_BIDDING_OFFLINE_API_ENDPOINT} from 'constants/api_urls';
import {useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Icons from 'components/DmpIcons/icons';
import BiddingOfflineExportBreakdownBuilder from './BiddingOfflineExportBreakdownBuilder';
import dayjs from 'dayjs';
import {getUserToken} from "../../../utils/functions/userToken";

export default function Export() {
    const account = useSelector((state) => state.account.account);

    const {idaccount} = useParams();

    const [exportLevel, setExportLevel] = useState('HOTEL');
    const handleChangeExportLevel = (event) => {
        setExportLevel(event.target.value);
    };

    const [exportPlatform, setExportPlatform] = useState('');
    const handleChangeExportPlatform = (event) => {
        setExportPlatform(event.target.value);
    };

    const [googleBreakdowns, setGoogleBreakdowns] = useState([]);
    const handleGoogleBreakdownChange = (selectedBreakdowns) => {
        setGoogleBreakdowns(selectedBreakdowns);
    };

    const breakDownItemsGoogle = [{value: "DEVICE", title: "Device"}, {value: "LENGTH_OF_STAY", title: "Length Of Stay"}, {value: "CHECKIN_DAY", title: "Checkin Day"}, {value: "DAYS_TO_ARRIVAL", title: "Days to Arrival"}, {value: "DATE_TYPE", title: "Date Type"}];

    const [tripadvisorBreakdowns, setTripadvisorBreakdowns] = useState([]);
    const handleTripadvisorBreakdownChange = (selectedBreakdowns) => {
        setTripadvisorBreakdowns(selectedBreakdowns);
    };

    const breakDownItemsTripadvisor = [{value: "LENGTH_OF_STAY", title: "Length Of Stay"}, {value: "DAYS_TO_ARRIVAL", title: "Days to Arrival"}, {value: "DATE_TYPE", title: "Date Type"}];


    const [kayakBreakdowns, setKayakBreakdowns] = useState([]);
    const handleKayakBreakdownChange = (selectedBreakdowns) => {
        setKayakBreakdowns(selectedBreakdowns);
    };

    const breakDownItemsKayak = [{value: "DEVICE", title: "Device"}, {value: "LENGTH_OF_STAY", title: "Length Of Stay"}, {value: "DAYS_TO_ARRIVAL", title: "Days to Arrival"}, {value: "WEEKEND_STAY", title: "Week-end Stay"}];


    const [bingBreakdowns, setBingBreakdowns] = useState([]);
    const handleBingBreakdownChange = (selectedBreakdowns) => {
        setBingBreakdowns(selectedBreakdowns);
    };

    const breakDownItemsBing = [{value: "DEVICE", title: "Device"}, {value: "LENGTH_OF_STAY", title: "Length Of Stay"}, {value: "CHECKIN_DAY", title: "Checkin Day"}, {value: "BING_SITE", title: "Bing Site"}, {value: "MARKET", title: "Market"}];


    const formatDateToISO = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = async () => {


        const jwtToken = getUserToken();

        exportApiMessage.showMessage = false;
        setExportApiMessage(exportApiMessage);


        let axiosInstance = axios.create({
            baseURL: APP_BIDDING_OFFLINE_API_ENDPOINT, headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}`,
            },
        });
        let biddingOfflineExport = {};
        biddingOfflineExport.export_type = exportPlatform;
        biddingOfflineExport.id_account = idaccount;
        biddingOfflineExport.export_level = exportLevel;
        biddingOfflineExport.report_start = formatDateToISO(startDate);
        biddingOfflineExport.report_end = formatDateToISO(endDate);
        biddingOfflineExport.breakdown = [];

        if (exportPlatform === "GOOGLE") {
            biddingOfflineExport.breakdown = googleBreakdowns;
        }
        if (exportPlatform === "TRIPADVISOR") {
            biddingOfflineExport.breakdown = tripadvisorBreakdowns;
        }
        if (exportPlatform === "KAYAK") {
            biddingOfflineExport.breakdown = kayakBreakdowns;
        }
        if (exportPlatform === "BING") {
            biddingOfflineExport.breakdown = bingBreakdowns;
        }
        try {
            const response = await axiosInstance.put('/api/bidding-offline/export/', biddingOfflineExport);


            console.log(response);
        } catch (error) {
            console.log(error);
        }
        setIsExported(true);
        exportApiMessage.showMessage = true;
        exportApiMessage.message = "Bidding file scheduled for export, check your email!";
        setExportApiMessage(exportApiMessage);

        setSnackbarMessage('Bidding file scheduled for export, check your email!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    };
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [exportApiMessage, setExportApiMessage] = useState({message: '', severity: 'success', showMessage: false});
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [setIsExported] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [isEndDateValid] = useState(true); // Validation d'endDate

    // Gestion des changements de startDate
    // Gestion des changements de startDate
    const handleStartDateChange = (newStartDate) => {
        if (newStartDate.isAfter(endDate)) {
            setEndDate(newStartDate);  // endDate prend la valeur de startDate
            setStartDate(endDate);     // startDate prend la valeur de endDate
        } else {
            setStartDate(newStartDate); // sinon, on applique la nouvelle startDate
        }
    };

    // Gestion des changements de endDate
    const handleEndDateChange = (newEndDate) => {
        if (newEndDate.isBefore(startDate)) {
            setStartDate(newEndDate);  // startDate prend la valeur de endDate
            setEndDate(startDate);     // endDate prend la valeur de startDate
        } else {
            setEndDate(newEndDate); // sinon, on applique la nouvelle endDate
        }
    };


    const isButtonVisible = exportLevel && startDate && endDate && exportPlatform;


    return (<div className="bidding-offline-container">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{width: '100%'}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className="export-header">
                <span>EXPORT BIDDING OFFLINE</span>
                <span>{account?.account_name ?? ""}</span>
            </div>
            <div className="export-fields-container"><div className="row">
                <div className="row-label">METRIC DATE RANGE</div>
                <div style={{display: "flex", gap: "1rem"}}>
                    <div>
                        <div className="dateLabel">FROM</div>
                        <DateInputWithCustomIcon
                            format={'DD/MM/YYYY'}
                            value={dayjs(startDate)}
                            onChange={handleStartDateChange}
                            icon={Icons.ActionsIcons.CalendarIcon}
                        />
                    </div>
                    <div>
                        <div className="dateLabel">TO</div>
                        <DateInputWithCustomIcon
                            format={'DD/MM/YYYY'}
                            value={dayjs(endDate)}
                            onChange={handleEndDateChange}
                            error={!isEndDateValid}
                            icon={Icons.ActionsIcons.CalendarIcon}
                        />
                        {!isEndDateValid && (
                            <FormHelperText error>
                                La date de fin ne peut pas être antérieure à la date de début.
                            </FormHelperText>
                        )}
                    </div>
                </div>
            </div>

                <div className="row" style={{alignItems: "center"}}>
                    <div className="row-label">EXPORT LEVEL</div>
                    <FormControl>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            value={exportLevel}
                            onChange={handleChangeExportLevel}
                        >
                            <FormControlLabel
                                value="HOTEL"
                                control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                                label="Hotel"
                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}
                            />
                            <FormControlLabel
                                value="GROUP"
                                control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                                label="Group"
                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className="row" style={{alignItems: "center"}}>
                    <div className="row-label">PLATFORM</div>
                    <FormControl>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            value={exportPlatform}
                            onChange={handleChangeExportPlatform}
                        >
                            <FormControlLabel value="GOOGLE" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>} label="Google Hotel Ads" sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}/>
                            <FormControlLabel value="TRIPADVISOR" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>} label="Tripadvisor" sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}/>
                            <FormControlLabel value="TRIVAGO" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>} label="Trivago" sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}/>
                            <FormControlLabel value="KAYAK" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>} label="Kayak" sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}/>
                            <FormControlLabel value="BING" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>} label="Bing" sx={{'& .MuiFormControlLabel-label': {fontSize: 12, fontFamily: 'Inter', fontWeight: 400}}}/>
                        </RadioGroup>
                    </FormControl>
                </div>

                {exportPlatform === 'GOOGLE' && (
                    <BiddingOfflineExportBreakdownBuilder title="BREAK DOWN FOR GOOGLE HOTEL ADS" checkboxItems={breakDownItemsGoogle} onChange={handleGoogleBreakdownChange}/>
                )}

                {exportPlatform === 'TRIPADVISOR' && (
                    <BiddingOfflineExportBreakdownBuilder title="BREAK DOWN FOR TRIPADVISOR" checkboxItems={breakDownItemsTripadvisor} onChange={handleTripadvisorBreakdownChange}/>
                )}

                {exportPlatform === 'KAYAK' && (
                    <BiddingOfflineExportBreakdownBuilder title="BREAK DOWN FOR KAYAK" checkboxItems={breakDownItemsKayak} onChange={handleKayakBreakdownChange}/>
                )}

                {exportPlatform === 'BING' && (
                    <BiddingOfflineExportBreakdownBuilder title="BREAK DOWN FOR BING" checkboxItems={breakDownItemsBing} onChange={handleBingBreakdownChange}/>
                )}

                {isButtonVisible && (
                    <div>
                        <Button
                            onClick={handleSubmit}
                            variant="outlined"
                            sx={{
                                marginBottom: '3px',
                                backgroundColor: "#12794F",
                                height: "24px",
                                borderRadius: "12px",
                                textTransform: "none",
                                color: "white",
                                fontSize: "12px",
                                "&:hover": {backgroundColor: "#12794F"},
                            }}
                            disableRipple
                            disableElevation
                        >
                            Export
                        </Button>
                    </div>
                )}

                {exportApiMessage.showMessage && (
                    <Grid container>
                        {exportApiMessage.showMessage && (
                            <Grid item xs={12} sm={6} md={6}>
                                <Alert variant="outlined" severity={exportApiMessage.severity}>
                                    {exportApiMessage.message}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
        </div>);
}
