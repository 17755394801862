export const isEmpty = (value) =>
    !value ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0);

export const validateBiddingOptionsPayload = (updateBidPayload,setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));
    errors.bidStrategy = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type);
    errors.baseBid= isEmpty(updateBidPayload?.bidding_strategy?.bidding_value);
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateMultipliersPayload = (updateBidPayload, multiplierPayload, activeButton, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP' && isEmpty(updateBidPayload?.group_id));

    if(activeButton === "MARKET") {
        errors.marketId = isEmpty(updateBidPayload?.market_id);
    }
    const isEmptyMultiplier = multiplierPayload.length === 0;
    const hasMissingValues =
        (activeButton === "CHECKIN_DAY" || activeButton === "BING_SITE") &&
        multiplierPayload.filter((p) => p?.bidding_strategy?.multiplier_value).length === 0;

    if (isEmptyMultiplier || hasMissingValues) {
        switch (activeButton) {
            case "LENGTH_OF_STAY":
                errors.numberOfNights = true;
                errors.multiplierValue = true;
                break;

            case "MARKET":
                errors.bidMultiplier = true;
                break;

            case "CHECKIN_DAY":
                errors.monday = true;
                errors.tuesday = true;
                errors.wednesday = true;
                errors.thursday = true;
                errors.friday = true;
                errors.saturday = true;
                errors.sunday = true;
                break;

            case "BING_SITE":
                errors.LOCAL_UNIVERSE = true;
                errors.MAP_RESULTS = true;
                break;

            default:
                break;
        }
    }

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};
