import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../../constants/api_urls";
import {getUserToken} from "../../../../../utils/functions/userToken";

export const validateCampaignName = async (accountId = null, hotelId = null, campaignName, campaignId = null) => {
    const jwtToken = getUserToken();
    let apiEndpoint;

    if (accountId && hotelId) {
        apiEndpoint = `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-campaign-name/${accountId}/${hotelId}/`;
    } else if (campaignId) {
        apiEndpoint = `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-edit-campaign-name/${campaignId}/`;
    } else {
        return { success: false, error: "Missing required parameters for validation" };
    }

    try {
        const response = await axios.post(
            apiEndpoint,
            {
                campaign_name: campaignName
            }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateBiddingStrategy = async (biddingStrategyOption, biddingValue) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-bidding-strategy/`,
            {
                bidding_strategy_option: biddingStrategyOption,
                bidding_value: biddingValue
            }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.[0]?.errorMessage || "Validation failed";
        return { success: false, error: errorMessage };
    }
};

export const validateBudget = async ({
    defaultBudget,
    monthlyBudgets,
}) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-budget/`,
            {
                default_budget: defaultBudget,
                ...monthlyBudgets,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const responseErrors = error?.response?.data || [];
        const errors = responseErrors.reduce((acc, err) => {
            acc[err.path] = err.errorMessage;
            return acc;
        }, {});
        return { success: false, errors: errors };
    }
};

export const validatePOS = async (posIds) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-pos/`,
            posIds,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const data = response.data;

        if (Array.isArray(data) && data.length === 0) {
            return { success: true };
        } else {
            return { success: false, errors: data };
        }
    } catch (error) {
        const errorMessage = error?.[0]?.errorMessage || "Internal Server Error, please retry later";
        return { success: false, error: errorMessage };
    }
};

export const validateLanguage = async (languageId) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-lang/`,
            [languageId],
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const data = response.data;
        if (Array.isArray(data) && data.length === 0) {
            return { success: true };
        }
    } catch (error) {
        const errorMessage = error?.[0]?.errorMessage || "Internal Server Error, please retry later";
        return { success: false, error: errorMessage };
    }
};


export const validatePMaxCampaignSettings = async (campaignSettings, idAccount, idHotel) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/campaign-validator/campaign-settings/validate-add-campaign-settings/${idAccount}/${idHotel}/`,
            campaignSettings,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const { data } = response;
        const errors = {};

        if (data.campaign_name?.errorMessage) {
            errors.campaignName = data.campaign_name.errorMessage;
        }

        if (data.campaign_lang?.[0]?.errorMessage) {
            errors.campaignLang = data.campaign_lang[0].errorMessage;
        }

        if (data.budget?.default_budget?.length > 0) {
            errors.defaultBudget = data.budget.default_budget.map(err => err.errorMessage).join(', ');
        }

        if (data.bidding_strategy?.bidding_strategy_option?.length > 0) {
            errors.biddingStrategy = data.bidding_strategy.bidding_strategy_option.map(err => err.errorMessage).join(', ');
        }

        if (data.bidding_strategy?.bidding_value?.length > 0) {
            errors.biddingValue = data.bidding_strategy.bidding_value.map(err => err.errorMessage).join(', ');
        }

        if (Object.keys(errors).length > 0) {
            return { success: false, errors };
        }

        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, errors: { global: "An unexpected error occurred during validation." } };
    }
};

