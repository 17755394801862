import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, CircularProgress } from '@mui/material';

const HotelsTable = ({ hotelsData, rowSelection, onRowSelectionChange, loading, onSelectAll }) => {
    const hotelColumns = [
        {
            accessorKey: 'id',
            header: 'Hotel ID',
            size: 40,
        },
        {
            accessorKey: 'name',
            header: 'Hotel Name',
            size: 80,
        },
    ];

    return (
        <Box sx={{ position: 'relative' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <MaterialReactTable
                columns={hotelColumns}
                data={hotelsData}
                enablePagination={false}
                enableSorting={true}
                enableColumnFilters={true}
                enableRowSelection={true}
                state={{ rowSelection }}
                onRowSelectionChange={onRowSelectionChange}
                getRowId={(row) => String(row.id)}
                muiTableContainerProps={{sx: {boxShadow: 'none',border: 'none'}}}
                muiTablePaperProps={{sx: {boxShadow: 'none', borderRadius: '4px 4px 4px 4px', overflow: 'hidden'}}}
                muiTopToolbarProps={{sx: {display: 'none'}}}
                muiBottomToolbarProps={{sx: {display: 'none'}}}
                muiTableBodyCellProps={() => ({
                    sx: {
                        padding: '2px',
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        fontWeight: 400,
                        lineHeight: '12.1px',
                        textAlign: 'left',
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        padding: '2px',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '13.31px',
                        textAlign: 'left',
                    },
                }}
                muiSelectAllCheckboxProps={{
                    sx: {
                        '& .MuiSvgIcon-root': {
                            fontSize: '16px',marginLeft:'10px',
                        },
                        verticalAlign: 'middle',
                        marginTop: '-12px',
                    },
                    onChange: (event) => {
                        const selectAll = event.target.checked;
                        onSelectAll(selectAll);
                    },
                }}
                muiSelectCheckboxProps={{
                    sx: {
                        '& .MuiSvgIcon-root': {
                            fontSize: '16px',marginLeft:'10px',
                        },
                    },
                }}
                enableHiding={false}
            />
        </Box>
    );
};

export default HotelsTable;
