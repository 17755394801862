import React, { useEffect, useState } from "react";
import {Box,Snackbar, Alert} from "@mui/material";
import DefaultLayout from "../../layout/defaultLayout";
import SubUserTable from "../../features/sub-user-management/Table/SubUser/SubUserTable";
import SubUserApi from "./SubUserApi";
import SlideOutPanel from "../../features/sub-user-management/SlidePanel/SlideOutPanel";
import './subusersmgmt.scss';
import PageTitles from "../../components/PageTitles";
import DmpIcons from "../../components/DmpIcons/icons";
import CustomButtonWithIcon from "../../components/CustomButtonWithIcon/Buttons";
import SearchTableInput from "components/SearchTableInput/SearchTableInput"

const SubUsersManagement = () => {
    const [usersData, setUsersData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [panelMode, setPanelMode] = useState('edit');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');

    const fetchSubUsers = async () => {
        setLoading(true);
        try {
            const data = await SubUserApi.getSubUsers();
            setUsersData(data);
            setError('');
        } catch (error) {
            //setError("Failed to fetch sub-users. Please try again later.");
            //setOpenErrorSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubUsers();
    }, []);

    const handleEmailClick = (user) => {
        setSelectedUser(user);
        setPanelMode('edit');
        setIsPanelOpen(true);
    };

    const handleAddUserClick = () => {
        setSelectedUser(null);
        setPanelMode('add');
        setIsPanelOpen(true);
    };

    const handlePanelClose = () => {
        setIsPanelOpen(false);
        setSelectedUser(null);
    };

    const handleUserUpdateSuccess = async () => {
        await fetchSubUsers();
    };

    const handleSuccessMessage = (message) => {
        setSuccessMessage(message);
        setOpenSuccessSnackbar(true);
    };

    const handleErrorMessage = (message) => {
        setError(message);
        setOpenErrorSnackbar(true);
    };

    const filteredData = usersData.filter((user) => {
        return (
            user.username?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            user.firstname?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            user.lastname?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            user.language?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            user.status?.toLowerCase().includes(globalFilter.toLowerCase()) ||
            user.role?.toLowerCase().includes(globalFilter.toLowerCase())
        );
    });

    return (
        <DefaultLayout page="sub-users-management">
            <PageTitles
                pageTitle="Metasearch"
                pageSubTitle="Users"
            />
            <Box className="subUserMgmt">
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#61D5A9"
                        width="120px"
                        height="30px"
                        titleColor="#373F41"
                        borderRadius="20px"
                        title="Click to create a new user"
                        icon={DmpIcons.ActionsIcons.AddIconBlack}
                        iconBorder="0.5px solid #373F41"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={handleAddUserClick}
                    >
                        Create User
                    </CustomButtonWithIcon>
                </Box>
                <Box className="searchBoxContainer">
                    <Box sx={{paddingLeft: "10px"}}>
                        <SearchTableInput
                            placeholder={'Search'}
                            onChange={(e) => {setGlobalFilter(e.target.value || '')}}
                        />
                    </Box>
                </Box>
                <Box>
                    <SubUserTable
                        usersData={filteredData}
                        onEmailClick={handleEmailClick}
                        loading={loading}
                    />
                </Box>
            </Box>
            <SlideOutPanel
                open={isPanelOpen}
                user={selectedUser}
                onClose={handlePanelClose}
                onAddUserSuccess={handleUserUpdateSuccess}
                mode={panelMode}
                onErrorMessage={handleErrorMessage}
                onSuccessMessage={handleSuccessMessage}
                handleEmailClick={handleEmailClick}
            />
            <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenErrorSnackbar(false)}
            >
                <Alert severity="error" onClose={() => setOpenErrorSnackbar(false)}>
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openSuccessSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSuccessSnackbar(false)}
            >
                <Alert severity="success" onClose={() => setOpenSuccessSnackbar(false)}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </DefaultLayout>
    );
};

export default SubUsersManagement;
