import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { APP_KAYAK_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import { kayakBiddingOptionsFormFields } from "./options";
import MarketsFilterMenu from "components/MarketFilterMenu";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";
import {
  setOpenEditBidding,
  updateEditBidHotels,
  updateEditBidMarkets,
} from "store/slices/editBid/editBid";
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";
import {validateBiddingOptionsPayload} from "../formValidator";
import {getUserToken} from "../../../../../utils/functions/userToken";

const Kayak = () => {
  const token = getUserToken();
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const { hotels = [], markets = [] } = useSelector((state) => state?.editBid);
  const dispatch = useDispatch();
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState(hotels);
  const [selectedMarkets] = useState(markets);

  const [updateBidDesktopPayload, setUpdateBidDesktopPayload] = useState({
    id_account: account_id,
    bidding_level:
      selectedHotelOrGroupType === "hotels" ? "HOTEL_MARKET" : "GROUP_MARKET",
  });
  const [updateBidMobilePayload, setUpdateBidMobilePayload] = useState({
    id_account: account_id,
    bidding_level:
      selectedHotelOrGroupType === "hotels" ? "HOTEL_MARKET" : "GROUP_MARKET",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const {
    hotel_list = [],
    group_list = [],
    market_list = [],
    allowed_options_device_mobile = [],
    allowed_options_device_desktop = [],
    can_use_group,
  } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: " Select a hotel ",
      options: hotel_list,
    },

    {
      id: "market_id",
      label: "Market",
      type: "select",
      placeholder: " Select a value ",
      options: market_list,
    },
  ];

  const [errors, setErrors] = useState({});

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      setUpdateBidDesktopPayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      setUpdateBidMobilePayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      axios
        .get(
          `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id,token]);

  const handleChangeFieldValue = useCallback((field, value, device) => {
    if (device === "desktop") {
      setUpdateBidDesktopPayload((prev) => ({ ...prev, [field]: value }));
    } else {
      setUpdateBidMobilePayload((prev) => ({ ...prev, [field]: value }));
    }
  }, []);

  useEffect(() => {
    handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id),'desktop');
    handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id),'mobile');
    handleChangeFieldValue("market_id",markets.map((s) => s.market_id),'desktop');
    handleChangeFieldValue("market_id",markets.map((s) => s.market_id),'mobile');
  }, [hotels, markets,handleChangeFieldValue,selectedHotelOrGroupType]);

  const sendUpdateRequest = useCallback((payload) => {
    axios
      .put(
        `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/update-bids/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setToastType("success");
        setToastMessage("Bidding Options Were Saved");
        setShowToast(true);
      })
      .catch((err) => {
        setToastType("error");
        setToastMessage(
          err?.response?.data[0]?.errorMessage || err?.[0]?.errorMessage || err?.message || "Something went wrong"
        );
        setShowToast(true);
      });
  }, [token]);

  const handleSave = useCallback(() => {
    if (!validateBiddingOptionsPayload(updateBidDesktopPayload, updateBidMobilePayload,setErrors)) {
      const finalDesktopPayload = {...updateBidDesktopPayload};
      const finalMobilePayload = {...updateBidMobilePayload};

      if (selectedHotelOrGroupType === "hotels") {
        delete finalDesktopPayload?.group_id;
        delete finalMobilePayload?.group_id;
      }
      if (selectedHotelOrGroupType === "groups") {
        delete finalDesktopPayload?.hotel_id;
        delete finalMobilePayload?.hotel_id;
      }
      const isDesktopPayloadValid =
          finalDesktopPayload?.bidding_strategy &&
          finalDesktopPayload?.bidding_strategy?.bidding_type
              ? (finalDesktopPayload?.bidding_strategy?.bidding_type ===
                  "MIN_BID_EXTRA_BID" &&
                  finalDesktopPayload?.bidding_strategy?.bidding_value) ||
              (finalDesktopPayload?.bidding_strategy?.bidding_type !==
                  "MIN_BID_EXTRA_BID" &&
                  !finalDesktopPayload?.bidding_strategy?.bidding_value)
              : true;

      const isMobilePayloadValid =
          finalMobilePayload?.bidding_strategy &&
          finalMobilePayload?.bidding_strategy?.bidding_type
              ? (finalMobilePayload?.bidding_strategy?.bidding_type ===
                  "MIN_BID_EXTRA_BID" &&
                  finalMobilePayload?.bidding_strategy?.bidding_value) ||
              (finalMobilePayload?.bidding_strategy?.bidding_type !==
                  "MIN_BID_EXTRA_BID" &&
                  !finalMobilePayload?.bidding_strategy?.bidding_value)
              : true;

      if (!isDesktopPayloadValid && !isMobilePayloadValid) {
        setToastMessage("Inconsistent bid value for Desktop and Mobile");
        setShowToast(true);
        setToastType("error");
        return;
      }

      if (!isDesktopPayloadValid) {
        setToastMessage("Inconsistent bid value for Desktop");
        setShowToast(true);
        setToastType("error");
        return;
      }

      if (!isMobilePayloadValid) {
        setToastMessage("Inconsistent bid value for Mobile");
        setShowToast(true);
        setToastType("error");
        return;
      }

      if (
          updateBidDesktopPayload?.bidding_strategy?.bidding_type &&
          isDesktopPayloadValid
      ) {
        sendUpdateRequest(finalDesktopPayload);
      }

      if (
          updateBidMobilePayload?.bidding_strategy?.bidding_type &&
          isMobilePayloadValid
      ) {
        sendUpdateRequest(finalMobilePayload);
      }
    }
  }, [updateBidDesktopPayload, updateBidMobilePayload,selectedHotelOrGroupType,sendUpdateRequest]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "market_id") {
                return (
                  <div className="form-grid-item">
                    {market_list?.length ? (
                      <MarketsFilterMenu
                        allMarkets={market_list?.map((market) =>
                          selectedMarkets?.find(
                            (m) => market?.market_id === m?.market_id
                          )
                            ? { ...market, isSelected: true }
                            : { ...market, isSelected: false }
                        )}
                        onApply={(selected) => {
                          dispatch(updateEditBidMarkets(selected));
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "desktop"
                          );
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "mobile"
                          );
                        }}
                        error={errors.marketId}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }

              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item bidding_multiplier_height">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          backgroundColor: "white",
                          fontSize: "12px",
                          fontWeight: "400",
                          paddingLeft: "4px",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                        error={errors.hotelId}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list?.map((hotel) =>
                          selectedHotelsorGroups?.find((h) => h.id === hotel.id) || hotel_list.length ===1
                            ? { ...hotel, isSelected: true }
                            : { ...hotel, isSelected: false }
                        )}
                        allGroups={group_list?.map((group) =>
                          selectedHotelsorGroups?.find((h) => h.id === group.id)
                            ? { ...group, isSelected: true }
                            : { ...group, isSelected: false }
                        )}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          dispatch(updateEditBidHotels(selected));

                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "mobile"
                          );
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "desktop"
                          );
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL_MARKET" : "GROUP_MARKET",
                            "desktop"
                          );
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL_MARKET" : "GROUP_MARKET",
                            "mobile"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="fields-container bidding_multiplier_height">
            {kayakBiddingOptionsFormFields?.map((field) => {
              const biddingAmountFields = ["MIN_BID_EXTRA_BID"];
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={(field?.options || []).filter((opt) =>
                      field.label.includes("Desktop")
                        ? allowed_options_device_desktop?.includes(opt.value)
                        : allowed_options_device_mobile?.includes(opt.value)
                    )}
                    hotelMenuOpen={openHotelMenu}
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    disabled={
                      (!biddingAmountFields.includes(
                        updateBidDesktopPayload?.bidding_strategy?.bidding_type
                      ) &&
                        field.id === "bidding_value_desktop") ||
                      (!biddingAmountFields.includes(
                        updateBidMobilePayload?.bidding_strategy?.bidding_type
                      ) &&
                        field.id === "bidding_value_mobile")
                    }
                    onChange={(val) => {
                      let device_identifier;
                      if (field.label.toLowerCase().includes("desktop")) {
                        device_identifier = "desktop";
                        handleChangeFieldValue(
                          "device_identifier",
                          device_identifier,
                          "desktop"
                        );
                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidDesktopPayload?.bidding_strategy,
                            [field.id.includes("bidding_value")
                              ? "bidding_value"
                              : field.value]:
                              field.id === "bidding_value_desktop"
                                ? Number(val)
                                : val,
                          },
                          device_identifier
                        );
                        if (field.id === "bidding_type_desktop") {
                          handleChangeFieldValue(
                            "bidding_strategy",
                            {
                              ...updateBidDesktopPayload?.bidding_strategy,
                              bidding_type: val,
                              bidding_value: undefined,
                            },
                            device_identifier
                          );
                        }
                      }
                      if (field.label.toLowerCase().includes("mobile")) {
                        device_identifier = "mobile";
                        handleChangeFieldValue(
                          "device_identifier",
                          device_identifier,
                          "mobile"
                        );
                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidMobilePayload?.bidding_strategy,
                            [field.id.includes("bidding_value")
                              ? "bidding_value"
                              : field.value]:
                              field.id === "bidding_value_mobile"
                                ? Number(val)
                                : val,
                          },
                          device_identifier
                        );

                        if (field.id === "bidding_type_mobile") {
                          handleChangeFieldValue(
                            "bidding_strategy",
                            {
                              ...updateBidMobilePayload?.bidding_strategy,
                              bidding_type: val,
                              bidding_value: undefined,
                              bidding_upper_limit: undefined,
                            },
                            device_identifier
                          );
                        }
                      }
                    }}
                    labelKey={"label"}
                    errors={errors}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div style={{display: 'flex', gap: 10, marginBottom:'-20px'}}>
          <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#12794F"
              titleColor="#373F41"
              hoverTitleColor={"white"}
              width={"85px"}
              height={"24px"}
              borderRadius="20px"
              textStyle={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14.52px',
                textAlign: 'center',
              }}
              onClick={handleSave}
          >
            Save
          </CustomButtonWithIcon>
          <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#12794F"
              titleColor="#373F41"
              hoverTitleColor={"white"}
              width={"85px"}
              height={"24px"}
              borderRadius="20px"
              textStyle={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14.52px',
                textAlign: 'center',
              }}
              onClick={() => {
                dispatch(setOpenEditBidding(false));
              }}
          >
            Cancel
          </CustomButtonWithIcon>
        </div>
        {/* )} */}
      </div>
      <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => {
            setShowToast(false);
          }}
      >
        <Alert severity={toastType} variant="filled" sx={{width: "100%"}}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Kayak;
