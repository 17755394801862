import { Box, Typography } from "@mui/material";
import "./CreateBudgetForm.scss";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import SingleSelect from "components/SingleSelect";

const CreateBudgetForm = ({ data = [], handleFieldChange, payload = {} }) => {
  return (
    <Box display="flex" width={"100%"} gap={"20px"}>
      <Box display="flex" flexDirection="column" gap={"8px"}>
        <Typography variant="caption">CREATE SHARED BUDGET NAME</Typography>
        <CustomOutlinedInput
          sx={{
            minWidth: "200px",
          }}
          inputProps={{
            sx: {
              minHeight: "28px",
              height: "28px",
            },
          }}
          value={payload?.shared_budget_name || ""}
          helperText={"Shared Budget Name"}
          onChange={(e) => {
            handleFieldChange("shared_budget_name", e.target.value);
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={"6px"}>
        <Typography variant="caption">HOTEL NAME</Typography>
        <SingleSelect
            MenuProps={{
                PaperProps: {
                    sx: {
                        zIndex: 2000,
                    },
                },
            }}
          sx={{
            minWidth: "200px",
            minHeight: "24px",
            fontSize: "12px",
            marginTop:"2px",
          }}
          placeholder={"Select a Hotel"}
          options={data?.map((opt) => ({
            id: opt?.hotel_id,
            label: opt?.hotel_name,
            value: opt?.hotel_name,
          }))}
          value={payload?.hotel_id || ""}
          onChange={(value) => {
            handleFieldChange(
              "hotel_id",
              data?.find((opt) => opt?.hotel_name === value)?.hotel_id
            );
          }}
        />
        <span className="helperTextDropdown">Hotel</span>
      </Box>
    </Box>
  );
};

export default CreateBudgetForm;
