import React from "react";
import {Box} from "@mui/material";
import "./ObjectiveAccordion.scss";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons"

const ObjectiveAccordion = ({ selectedObjective, setSelectedObjective }) => {
  const objectives = [
    {
      id: "BRAND",
      name: "Brand Protection",
      description: "Drive Sales through your website and by phone",
    },
    {
      id: "GENERIC",
      name: "Generic Campaign",
      description: "Drive Sales through your website and by phone",
    },
  ];

  return (
    <Box className="search-ads-form-objective">
      <Box className="search-ads-form-objective-buttons">
        {objectives.map((objective) => (
            <CustomButtonWithIcon
                key={objective.id}
                className={`objectiveButton ${
                    selectedObjective === objective.id ? "selected" : ""
                }`}
                color="white"
                hoverColor="#12794F"
                width="475px"
                height="200px"
                titleColor="black"
                hoverTitleColor={"white"}
                borderRadius="20px"
                title="Click to create a new alert"
                textStyle={{
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14.52px',
                  textAlign: 'center',
                }}
                onClick={() => setSelectedObjective(objective.id)}
            >
              <div>{objective.name}</div>
              <small>{objective.description}</small>
            </CustomButtonWithIcon>
        ))}
      </Box>
    </Box>
  );
};

export default ObjectiveAccordion;
