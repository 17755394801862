import axios from "axios";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import {getUserToken} from "../../../../utils/functions/userToken";

export const validateCampaignSettings = ({
  campaignName,
  biddingStrategy,
  targetCpa,
  targetRoas,
  maxCpcLimit,
  impressionShare,
  adLocation,
  targetValue,
}) => {
  const errors = {};

  errors.campaignNameError = !campaignName;
  errors.biddingStrategyError = !biddingStrategy;

  if (biddingStrategy === "MAXIMIZE_CONVERSIONS") {
    errors.targetCpaError = !targetCpa;
  }

  if (biddingStrategy === "MAXIMIZE_CONVERSION_VALUE") {
    errors.targetRoasError = !targetRoas;
  }

  if (biddingStrategy === "MAXIMIZE_CLICKS") {
    errors.maxCpcLimitError = !maxCpcLimit;
  }

  if (biddingStrategy === "TARGET_IMPRESSION_SHARE") {
    errors.impressionShareError = !impressionShare;
    errors.adLocationError = !adLocation;
  }

  errors.targetValueError = !targetValue;

  return errors;
};

export const validateKeywords = ({ keywords, negativeKeywords }) => {
  const errors = { keywords: [], negativeKeywords: [] };

  keywords.forEach((keyword, index) => {
    if (!keyword.keyword || !keyword.matchType) {
      errors.keywords[index] = true;
    }
  });

  negativeKeywords.forEach((keyword, index) => {
    if (!keyword.keyword || !keyword.matchType) {
      errors.negativeKeywords[index] = true;
    }
  });

  return errors;
};

export const validateCampaignName = async (
  assetContent,
  accountid,
  hotelid,
  campaignId = null
) => {
  const jwtToken = getUserToken();
  try {
    let apiEndpoint;
    if (campaignId) {
      apiEndpoint = `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-edit-campaign-name/${campaignId}/`;
    } else {
      apiEndpoint = `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-campaign-name/${accountid}/${hotelid}/`;
    }

    const response = await axios.post(
        apiEndpoint,
        { ...assetContent },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateBiddingStrategy = async (biddingStrategy) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-bidding-strategy/`,
      { ...biddingStrategy },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateBudget = async (budget) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-budget/`,
      { ...budget },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const responseErrors = error?.response?.data || [];
    const errors = responseErrors.reduce((acc, err) => {
      acc[err.path] = err.errorMessage;
      return acc;
    }, {});
    return { success: false, errors: errors };
  }
};

export const validatePos = async (pos) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-pos/`,
      [...pos],
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateLang = async (lang) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-lang/`,
      [...lang],
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateFullCampaignSettings = async (
  campaignSettings,
  accountid,
  hotelid
) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/campaign-settings/validate-add-campaign-settings/${accountid}/${hotelid}/`,
      { ...campaignSettings },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateHeadline = async (assetContent) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-headline/`,
      { assetContent },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateDescription = async (assetContent) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-description/`,
      { assetContent },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateBusinessName = async (assetContent) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-business-name/`,
      { assetContent },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateCallout = async (assetContent) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-callout/`,
      { assetContent },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validatePathProperty = async (property, paths) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-path/${property}`,
      { ...paths },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validatePath = async (paths) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-path/`,
      { ...paths },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validatePhoneNumberProperty = async (property, phoneNumber) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-phone-call/${property}`,
      { ...phoneNumber },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validatePhoneNumber = async (phoneNumber) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-phone-call/`,
      { ...phoneNumber },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateSiteLinkProperty = async (property, sitelink) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-site-link/${property}/`,
      { ...sitelink },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateSiteLink = async (sitelink) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-site-link/`,
      { ...sitelink },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateSnippet = async (assetContent) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-snippet/`,
      { assetContent: assetContent },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateFinalURL = async (finalURL) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-one-finale-url/`,
      { url: finalURL },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateFullAdsCreative = async (adsCreative) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/ads-creative/validate-ads-creative/`,
      { ...adsCreative },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateOneKeyword = async (keyword) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/keywords/validate-one-keywords/`,
      { ...keyword },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};

export const validateAllKeywords = async (keywords) => {
  const jwtToken = getUserToken();

  try {
    const response = await axios.post(
      `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaign-validator/keywords/validate-keywords/`,
      { ...keywords },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage =
      error?.response?.data?.[0]?.errorMessage || "Validation failed";
    return { success: false, error: errorMessage };
  }
};
