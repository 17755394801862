import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Menu } from "@mui/material";
import CustomCheckbox from "components/Checkbox";
import { useState } from "react";

const CheckboxMenu = ({
  open,
  options,
  onClose,
  filterType,
  onApply,
  anchorEl,
}) => {
  const [selected, setSelected] = useState(options);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      maxWidth="m"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "10px",
        },
      }}
    >
      <div style={{ paddingInline: "10px" }}>
        <div
          style={{
            fontSize: "10px",
            color: "#C4C4C4",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0px",
          }}
        >
          <span>{filterType}</span>
          <span onClick={onClose}>
            <CloseOutlined sx={{
                fontSize: "16px",
                cursor: "pointer",
                "&:hover": { color: "black"}
            }} />
          </span>
        </div>
        <ul
          style={{
            listStyleType: "none",
            marginBlock: 0,
            marginBottom: "8px",
            padding: 0,
            width: "100%",
            maxHeight: "250px",
            overflow: "auto",
          }}
        >
          {selected?.map((item, i) => {
            return (
              <li
                style={{
                  borderLeft: "1px solid #CADEDD",
                  paddingLeft: "10px",
                  paddingBlock: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span>
                    <CustomCheckbox
                      size={"small"}
                      checked={item?.isSelected}
                      title={item?.label}
                      onChange={() => {
                        setSelected(
                          selected?.map((i) => {
                            return {
                              ...i,
                              isSelected:
                                i?.id === item.id
                                  ? !i?.isSelected
                                  : i?.isSelected,
                            };
                          })
                        );
                      }}
                    />
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "14px",
          paddingBlock: "0px",
        }}
      >
        {false && (
          <span
            style={{ fontSize: "10px", color: "#C4C4C4", marginRight: "20px" }}
            onClick={() => {
              setSelected((prev) => {
                return prev.map((item) => {
                  return {
                    ...item,
                    isSelected: false,
                  };
                });
              });
            }}
          >
            Unselect All
          </span>
        )}
        <Button
          variant="outlined"
          sx={{
            color: "black",
            backgroundColor: "#61D5A9",
            borderRadius: "20px",
            fontSize: "10px",
            width: "100px",
            height: "22px",
            paddingInline: "8px",
          }}
          onClick={() => {
            onApply(selected?.filter((item) => item.isSelected));
            onClose();
          }}
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
};
export default CheckboxMenu;
