export default class UnitBase {
    constructor(context, label = '', symbol = '') {
        this.context = context;
        this.label = label;
        this.symbol = symbol;
    }

    setContext(context = {}) {
    }
}
