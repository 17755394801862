import {MuiConfig} from "@react-awesome-query-builder/mui";
import Icons from "../../../../components/DmpIcons/icons";
import React from "react";

const circleStyle = {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    border: '0.5px solid #BAB7B7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
};

const customConfig = {
    ...MuiConfig,
    operators: {
        ...MuiConfig.operators,
        equal: {
            ...MuiConfig.operators.equal,
            label: 'Equal',
            labelInBrackets: 'Equal',
        },
        not_equal: {
            ...MuiConfig.operators.not_equal,
            label: 'Not equal',
            labelInBrackets: 'Not equal',
        },
        less: {
            ...MuiConfig.operators.less,
            label: 'Less',
            labelInBrackets: 'Less',
        },
        less_or_equal: {
            ...MuiConfig.operators.less_or_equal,
            label: 'Less or equal',
            labelInBrackets: 'Less or equal',
        },
        greater: {
            ...MuiConfig.operators.greater,
            label: 'Greater',
            labelInBrackets: 'Greater',
        },
        greater_or_equal: {
            ...MuiConfig.operators.greater_or_equal,
            label: 'Greater or equal',
            labelInBrackets: 'Greater or equal',
        },
    },
    settings: {
        ...MuiConfig.settings,
        renderIcon: (icon) => {
            switch (icon.type) {
                case 'addRule':
                    return (
                        <div style={circleStyle}>
                            <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Rule" style={{ width: '8px', height: '8px' }} />
                        </div>
                    );
                case 'addGroup':
                    return (
                        <div style={circleStyle}>
                            <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Group" style={{ width: '8px', height: '8px' }} />
                        </div>
                    );
                case 'delGroup':
                    return <img src={Icons.ActionsIconsSVG.TrashcanIconSVG} alt="Delete Group" style={{ width: '30px', height: '30px' }} />;
                case 'delRule':
                    return <img src={Icons.ActionsIconsSVG.TrashcanIconSVG} alt="Delete Rule" style={{ width: '30px', height: '30px' }} />;
                default:
                    return <span>{icon.type}</span>;
            }
        },
    },
    fields: {
    },
};

export default customConfig;