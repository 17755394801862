import React, {useCallback, useEffect, useState} from "react";
import {
  addDays,
  compareAsc,
  differenceInDays,
  endOfYear,
  format,
  isValid,
  parse,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import {DateRangePicker} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {Box, Switch, TextField, Typography} from "@mui/material";
import {useStyles} from "./useStyles";
import {enGB} from "date-fns/locale";
import {CalendarTodayOutlined} from "@mui/icons-material";
import "./DatePicker.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  updateCompareWith,
  updateComparitionDateRange,
  updateComparitionSelection,
  updateCurrentDateRange,
  updateCurrentSelection,
} from "store/slices/calender/calenderSlice";
import CustomButtonWithIconComponent from "components/CustomButtonWithIcon/Buttons";

export const mapper = {
  previousPeriod: "Previous Period",
  previousYearPeriod: "Previous year",
  customRange: "Custom Range",
};

export const DatePicker = () => {
  const minDate = new Date("2011-01-01");
  const maxDate = new Date();
  const { classes } = useStyles();
  const {currentDateRange = {}, compareWith} = useSelector(state => state?.calender)

  const [selection, setSelection] = useState({
    startDate: addDays(new Date(), -30),
    endDate: addDays(new Date(), -1),
    key: "selection",
  });
  const [compare, setCompare] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 3),
    disabled: true,
    key: "compare",
  });
  const [openDateRange, setOpenDateRange] = useState(false);
  const [compareWithState, setCompareWithState] = useState(false);
  const currentMonthStartDate = subMonths(selection.startDate, 1);
  const currentMonthEndDate = subMonths(selection.endDate, 1);
  const [customRange1, setCustomRange1] = useState({
    startDate: format(currentMonthStartDate, "dd/MM/yyyy"),
    endDate: format(currentMonthEndDate, "dd/MM/yyyy"),
  });
  const [customRange2, setCustomRange2] = useState({
    startDate: "",
    endDate: "",
  });
  const [currentSelection, setCurrentSelection] = useState("last30Days");
  const [currentCompareSelection, setCurrentCompareSelection] =
    useState("previousYearPeriod");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateComparitionSelection(currentCompareSelection));
  }, [currentCompareSelection, dispatch]);

  useEffect(() => {
    dispatch(updateCurrentSelection(currentSelection));
  }, [currentSelection, dispatch]);

  useEffect(() => {
    if(currentDateRange?.startDate && currentDateRange?.endDate){
      setSelection({startDate: currentDateRange?.startDate, endDate: currentDateRange?.endDate, key: 'selection'})
    }else{
      dispatch(
        updateCurrentDateRange({
          startDate: selection.startDate,
          endDate: selection.endDate,
        })
      );
    }
  }, [currentDateRange, dispatch]);

  // Set Default Last30Days

  const handleApplyDates = useCallback(() => {
    dispatch(
      updateCurrentDateRange({
        startDate: selection.startDate,
        endDate: selection.endDate,
      })
    );

    dispatch(updateCompareWith(compareWithState));

    if (compareWithState) {
      dispatch(
        updateComparitionDateRange({
          startDate: compare.startDate,
          endDate: compare.endDate,
        })
      );
    }else {
      dispatch(updateComparitionDateRange({
        startDate: null,
        endDate: null,
      }))
    }

    setOpenDateRange(false);
  }, [selection, compare, compareWithState, dispatch]);

  const handleCustomDateChange = (
    e,
    customRange,
    key,
    setCustomRange,
    type
  ) => {
    let input = e.target.value.replace(/\D/g, "").substring(0, 8); // Remove non-numeric characters and limit to 8 characters
    let day = input.substring(0, 2);
    let month = input.substring(2, 4);
    let year = input.substring(4, 8);

    let formattedDate = "";

    if (input.length > 4) {
      formattedDate = `${day}/${month}/${year}`;
    } else if (input.length > 2) {
      formattedDate = `${day}/${month}`;
    } else if (input.trim() !== "") {
      formattedDate = day;
    }
    if (input.length >= 8) {
      const startDate =
        key === "startDate" ? formattedDate : customRange.startDate;
      const endDate = key === "endDate" ? formattedDate : customRange.endDate;
      const parsedStartDate = parse(startDate, "P", new Date(), {
        locale: enGB,
      });
      const isStartDateValidDate = isValid(parsedStartDate);
      const parsedEndDate = parse(endDate, "P", new Date(), { locale: enGB });
      const isEndDateValidDate = isValid(parsedEndDate);
      if (isStartDateValidDate && isEndDateValidDate) {
        if (type === "range1") {
          handleSelection("customRange");
          if (compareAsc(parsedStartDate, parsedEndDate) > 0) {
            setSelection({
              key: "selection",
              startDate: parsedEndDate,
              endDate: parsedStartDate,
            });

            setCustomRange({ startDate: endDate, endDate: startDate });
            return;
          } else {
            setSelection({
              key: "selection",
              startDate: parsedStartDate,
              endDate: parsedEndDate,
            });

            setCustomRange({
              startDate: parsedStartDate,
              endDate: parsedEndDate,
            });
          }
        }
        if (type === "range2") {
          setCurrentCompareSelection("customRange");
          handleCompareCustomRangeSelected();
          if (compareAsc(parsedStartDate, parsedEndDate) > 0) {
            setCompare({
              key: "compare",
              startDate: parsedEndDate,
              endDate: parsedStartDate,
            });
            setCustomRange({ startDate: endDate, endDate: startDate });
            return;
          } else {
            setCompare({
              disabled: true,
              key: "compare",
              startDate: parsedStartDate,
              endDate: parsedEndDate,
            });
          }
        }
      }
    }
    setCustomRange({ ...customRange, [key]: formattedDate });
  };
  const handlePreviousPeriodClick = () => {
    switch (currentSelection) {
      case "currentMonth": {
        setCompare({
          disabled: true,
          key: "compare",
          startDate: currentMonthStartDate,
          endDate: currentMonthEndDate,
        });
        setCustomRange2({
          startDate: format(currentMonthStartDate, "dd/MM/yyyy"),
          endDate: format(currentMonthEndDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "currentYear": {
        const startDate = subYears(selection.startDate, 1);
        const endDate = subYears(selection.endDate, 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "yesterday": {
        const startDate = addDays(selection.startDate, -1);
        const endDate = addDays(selection.endDate, -1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "lastWeek": {
        const startDate = subWeeks(selection.startDate, 1);
        const endDate = subWeeks(selection.endDate, 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "last30Days": {
        const startDate = addDays(selection.startDate, -30);
        const endDate = addDays(selection.endDate, -30);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "last7Days": {
        const startDate = addDays(selection.startDate, -7);
        const endDate = addDays(selection.endDate, -7);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "lastMonth": {
        const startDate = subMonths(selection.startDate, 1);
        const endDate = subMonths(selection.endDate, 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "customRange": {
        const diff =
          differenceInDays(selection.startDate, selection.endDate) - 1;
        const startDate = addDays(selection.startDate, diff);
        const endDate = addDays(selection.endDate, diff);

        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      default: {
        return;
      }
    }
  };
  const handlePreviousYearPeriodClick = () => {
    switch (currentSelection) {
      case "currentMonth": {
        const startDate = subYears(subMonths(selection.startDate, 1), 1);
        const endDate = subYears(subMonths(selection.endDate, 1), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "currentYear": {
        const startDate = subYears(selection.startDate, 1);
        const endDate = subYears(selection.endDate, 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "yesterday": {
        const startDate = subYears(addDays(selection.startDate, -1), 1);
        const endDate = subYears(addDays(selection.endDate, -1), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "lastYear": {
        setCompare({
          disabled:true,
          key: "compare",
          startDate: startOfYear(subYears(selection.startDate,1)),
          endDate: endOfYear(subYears(selection.endDate,1)),
        });
        setCustomRange2({
          startDate: format(
            startOfYear(subYears(new Date(),1)),
            "dd/MM/yyyy"
          ),
          endDate: format(endOfYear(subYears(new Date(),1)), "dd/MM/yyyy"),
        });

        break;
      }
      case "lastWeek": {
        const startDate = subYears(subWeeks(selection.startDate, 1), 1);
        const endDate = subYears(subWeeks(selection.endDate, 1), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "last30Days": {
        const startDate = subYears(addDays(selection.startDate, -30), 1);
        const endDate = subYears(addDays(selection.endDate, -30), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "last7Days": {
        const startDate = subYears(addDays(selection.startDate, -7), 1);
        const endDate = subYears(addDays(selection.endDate, -7), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "lastMonth": {
        const startDate = subYears(subMonths(selection.startDate, 1), 1);
        const endDate = subYears(subMonths(selection.endDate, 1), 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        setCustomRange2({
          startDate: format(startDate, "dd/MM/yyyy"),
          endDate: format(endDate, "dd/MM/yyyy"),
        });
        return;
      }
      case "customRange": {
        const startDate = subYears(selection.startDate, 1);
        const endDate = subYears(selection.endDate, 1);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      default: {
        return;
      }
    }
  };
  const handleCompareCustomRangeSelected = () => {
    switch (currentSelection) {
      case "currentMonth": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "currentYear": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "yesterday": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "lastWeek": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "last30Days": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "last7Days": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "lastMonth": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      case "customRange": {
        const diff = differenceInDays(compare.startDate, compare.endDate) + 1;
        const startDate = subDays(compare.startDate, diff);
        const endDate = subDays(compare.endDate, diff);
        setCompare({ disabled: true, key: "compare", startDate, endDate });
        return;
      }
      default: {
        return;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (document.querySelector(".container")?.contains(e.target)) {
        // Clicked in box
      } else {
        // Clicked outside the box
        setOpenDateRange(false);
      }
    });
  }, []);
  useEffect(() => {
    if (currentCompareSelection === "previousPeriod") {
      handlePreviousPeriodClick();
    } else if (currentCompareSelection === "previousYearPeriod") {
      handlePreviousYearPeriodClick();
    } else if (currentCompareSelection === "customRange") {
      handleCompareCustomRangeSelected();
    }
  }, [selection, currentCompareSelection]);

  useEffect(() => {
    setCompareWithState(compareWith)
  },[compareWith])

  const handleSelection = (key) => {
    setCurrentSelection(key);
    switch (key) {
      case "currentMonth": {
        setSelection({
          key: "selection",
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
        });
        setCustomRange1({
          startDate: format(startOfMonth(new Date()), "dd/MM/yyyy"),
          endDate: format(new Date(), "dd/MM/yyyy"),
        });
        break;
      }
      case "currentYear": {
        setSelection({
          key: "selection",
          startDate: startOfYear(new Date()),
          endDate: new Date(),
        });
        setCustomRange1({
          startDate: format(startOfYear(new Date()), "dd/MM/yyyy"),
          endDate: format(new Date(), "dd/MM/yyyy"),
        });
        break;
      }
      case "yesterday": {
        setSelection({
          key: "selection",
          startDate: addDays(new Date(), -1),
          endDate: addDays(new Date(), -1),
        });
        setCustomRange1({
          startDate: format(addDays(new Date(), -1), "dd/MM/yyyy"),
          endDate: format(addDays(new Date(), -1), "dd/MM/yyyy"),
        });

        break;
      }
      case "last30Days": {
        setSelection({
          key: "selection",
          startDate: addDays(new Date(), -30),
          endDate: addDays(new Date(), -1),
        });
        setCustomRange1({
          startDate: format(addDays(new Date(), -30), "dd/MM/yyyy"),
          endDate: format(addDays(new Date(), -1), "dd/MM/yyyy"),
        });
        break;
      }
      case "last7Days": {
        setSelection({
          key: "selection",
          startDate: addDays(new Date(), -7),
          endDate: addDays(new Date(), -1),
        });
        setCustomRange1({
          startDate: format(addDays(new Date(), -7), "dd/MM/yyyy"),
          endDate: format(addDays(new Date(), -1), "dd/MM/yyyy"),
        });
        break;
      }
      case "lastWeek": {
        setSelection({
          key: "selection",
          startDate: addDays(startOfWeek(new Date()), -6),
          endDate: startOfWeek(new Date()),
        });
        setCustomRange1({
          startDate: format(addDays(startOfWeek(new Date()), -6), "dd/MM/yyyy"),
          endDate: format(startOfWeek(new Date()), "dd/MM/yyyy"),
        });

        break;
      }
      case "lastMonth": {
        setSelection({
          key: "selection",
          startDate: startOfMonth(addDays(startOfMonth(new Date()), -1)),
          endDate: addDays(startOfMonth(new Date()), -1),
        });
        setCustomRange1({
          startDate: format(
            startOfMonth(addDays(startOfMonth(new Date()), -1)),
            "dd/MM/yyyy"
          ),
          endDate: format(addDays(startOfMonth(new Date()), -1), "dd/MM/yyyy"),
        });

        break;
      }
      case "lastYear": {
        setSelection({
          key: "selection",
          startDate: startOfYear(subYears(new Date(),1)),
          endDate: endOfYear(subYears(new Date(),1)),
        });
        setCustomRange1({
          startDate: format(
            startOfYear(subYears(new Date(),1)),
            "dd/MM/yyyy"
          ),
          endDate: format(endOfYear(subYears(new Date(),1)), "dd/MM/yyyy"),
        });

        break;
      }
      case "customRange": {
        break;
      }
      default: {
        break;
      }
    }
  };
  const getFormattedDate = () => {
    try {
      return `${format(selection.startDate, "dd-MM-yyyy")} - ${
        selection?.endDate ? format(selection?.endDate ?? "", "dd-MM-yyyy") : ""
      }`;
    } catch (error) {
      return "";
    }
  };
  return (
    <Box className={`${classes.datePickerContainer} container`}>
      <Box
        className={classes.datePicker}
        sx={{
          color: openDateRange ? "white" : "#BAB7B7",
          backgroundColor: openDateRange ? "#61D5A9" : "white",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.05);",
          borderRadius: openDateRange ? "20px 20px 0px 0px" : "20px",
        }}
        onClick={() => {
          setOpenDateRange(!openDateRange);
        }}
      >
        {" "}
        <CalendarTodayOutlined
          sx={{
            marginRight: 2,
            color: !openDateRange ? "##8FB5B3" : "white",
            strokeWidth: 1,
          }}
        />{" "}
        {getFormattedDate()}{" "}
      </Box>

      {openDateRange && (
        <Box className={`${classes.calender}`}>
          <Box sx={{ display: "flex" }}>
            <Box className={classes.customActions}>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "currentMonth" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("currentMonth");
                }}
              >
                Current month
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "currentYear" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("currentYear");
                }}
              >
                Current Year
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "yesterday" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("yesterday");
                }}
              >
                Yesterday
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "last30Days" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("last30Days");
                }}
              >
                Last 30 days
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "last7Days" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("last7Days");
                }}
              >
                Last 7 days
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "lastWeek" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("lastWeek");
                }}
              >
                Last week
              </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "lastMonth" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  handleSelection("lastMonth");
                }}
              >
                Last month
              </Typography>
              <Typography
                    className={classes.actionItem}
                    sx={{
                      color:
                      currentSelection === "lastYear" ? "#373F41" : "#BAB7B7",
                    }}
                    onClick={() => {
                      handleSelection("lastYear");
                    }}
                  >
                    Last year
                  </Typography>
              <Typography
                className={classes.actionItem}
                sx={{
                  color:
                    currentSelection === "customRange" ? "#373F41" : "#BAB7B7",
                }}
                onClick={() => {
                  setCurrentSelection("customRange");
                }}
              >
                Custom range
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <TextField
                  sx={{ width: 54 }}
                  variant="standard"
                  type="text"
                  id="dateInput"
                  value={customRange1.startDate}
                  onChange={(e) => {
                    handleCustomDateChange(
                      e,
                      customRange1,
                      "startDate",
                      setCustomRange1,
                      "range1"
                    );
                  }}
                  name="dateInput"
                  placeholder="dd/mm/yyyy"
                  InputProps={{
                    style: { fontSize: 10, color: "#BAB7B7", width: 54 },
                  }}
                />{" "}
                <span style={{ color: "#BAB7B7", marginLeft: 2 }}>-</span>
                <TextField
                  sx={{ width: 54 }}
                  variant="standard"
                  type="text"
                  id="dateInput"
                  value={customRange1.endDate}
                  onChange={(e) => {
                    handleCustomDateChange(
                      e,
                      customRange1,
                      "endDate",
                      setCustomRange1,
                      "range1"
                    );
                  }}
                  name="dateInput"
                  placeholder="dd/mm/yyyy"
                  InputProps={{
                    style: { fontSize: 10, color: "#BAB7B7", width: 54 },
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: 12, fontWeight: 400, marginBlock: "8px" }}
              >
                Compare with{" "}
                <Switch
                  size="small"
                  className={classes.switch}
                  checked={compareWithState}
                  onChange={() => {
                    setCompareWithState((prev) => !prev);
                  }}
                  sx={{
                    marginLeft: "10px",
                    transform: "scale(1.1)",
                  }}
                />{" "}
              </Typography>
              {compareWithState && (
                <Box>
                  <Typography
                    className={classes.actionItem}
                    sx={{
                      color:
                        currentCompareSelection === "previousPeriod"
                          ? "#373F41"
                          : "#BAB7B7",
                    }}
                    onClick={() => {
                      setCurrentCompareSelection("previousPeriod");
                      handlePreviousPeriodClick();
                    }}
                  >
                    Previous period
                  </Typography>
                  <Typography
                    className={classes.actionItem}
                    sx={{
                      color:
                        currentCompareSelection === "previousYearPeriod"
                          ? "#373F41"
                          : "#BAB7B7",
                    }}
                    onClick={() => {
                      setCurrentCompareSelection("previousYearPeriod");
                      handlePreviousYearPeriodClick();
                    }}
                  >
                    Previous year
                  </Typography>
                  <Typography
                    className={classes.actionItem}
                    sx={{
                      color:
                        currentCompareSelection === "customRange"
                          ? "#373F41"
                          : "#BAB7B7",
                    }}
                    onClick={() => {
                      setCurrentCompareSelection("customRange");
                    }}
                  >
                    Custom range
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "90%",
                    }}
                  >
                    <TextField
                      sx={{ width: 54 }}
                      variant="standard"
                      type="text"
                      id="dateInput"
                      value={customRange2.startDate}
                      onChange={(e) => {
                        handleCustomDateChange(
                          e,
                          customRange2,
                          "startDate",
                          setCustomRange2,
                          "range2"
                        );
                      }}
                      name="dateInput"
                      placeholder="dd/mm/yyyy"
                      InputProps={{
                        style: { fontSize: 10, color: "#BAB7B7", width: 54 },
                      }}
                    />
                    <span style={{ color: "#BAB7B7", marginLeft: 0 }}>-</span>
                    <TextField
                      sx={{ width: 54 }}
                      variant="standard"
                      type="text"
                      id="dateInput"
                      value={customRange2.endDate}
                      onChange={(e) => {
                        handleCustomDateChange(
                          e,
                          customRange2,
                          "endDate",
                          setCustomRange2,
                          "range2"
                        );
                      }}
                      name="dateInput"
                      placeholder="dd/mm/yyyy"
                      InputProps={{
                        style: { fontSize: 10, color: "#BAB7B7", width: 54 },
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  bottom: "20px",
                  gap: "15px",
                }}
              >
                <CustomButtonWithIconComponent
                  color="#b0acac"
                  hoverColor="#12794F"
                  titleColor="#373F41"
                  hoverTitleColor={"white"}
                  height={"20px"}
                  width="64px"
                  borderRadius="20px"
                  textStyle={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "14.52px",
                    textAlign: "center",
                  }}
                  variant="outlined"
                  translate="no"
                  onClick={handleApplyDates}
                >
                  Apply
                </CustomButtonWithIconComponent>
                <CustomButtonWithIconComponent
                  color="#b0acac"
                  hoverColor="#12794F"
                  titleColor="#373F41"
                  hoverTitleColor={"white"}
                  height={"20px"}
                  width="64px"
                  borderRadius="20px"
                  textStyle={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "14.52px",
                    textAlign: "center",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setOpenDateRange(false);
                  }}
                >
                  Cancel
                </CustomButtonWithIconComponent>
              </Box>
            </Box>
            <DateRangePicker
              onChange={(item) => {
                if (Object.keys(item)[0] === "selection") {
                  setSelection({ ...item.selection });
                  setCustomRange1({
                    startDate: format(item.selection.startDate, "dd/MM/yyyy"),
                    endDate: format(item.selection.endDate, "dd/MM/yyyy"),
                  });
                  setCurrentSelection("customRange");
                } else {
                }
              }}
              months={2}
              weekStartsOn={1}
              rangeColors={["#61D5A9", "grey"]}
              minDate={minDate}
              maxDate={maxDate}
              direction="vertical"
              scroll={{ enabled: true }}
              fixedHeight={true}
              ranges={compareWithState ? [selection, compare] : [selection]}
              locale={enGB}
              inputRanges={[]}
              showDateDisplay={false}
              staticRanges={[]}
            />{" "}
          </Box>
        </Box>
      )}
    </Box>
  );
};
