  import React, { useState, useEffect, useCallback } from 'react';
  import { useParams } from "react-router-dom";
  import { Grid, TextField, Select, MenuItem, Button, Typography, FormControl, FormHelperText } from '@mui/material';
  import { validateReportForm } from '../../validation/reportValidation';
  import { sanitizeString } from '../../../../utils/functions/sanitize';
  import { fetchService } from '../../Services/fetchService';
  import Tags from "@yaireo/tagify/dist/react.tagify";
  import { fetchFtp, fetchHotels, fetchGroups, fetchUserInfo, fetchWhiteLabelEmail } from '../../Services/apiServices';
  import { APP_REPORT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
  import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
  import DmpIcons from "components/DmpIcons/icons";
  import SlidingPanel from 'react-sliding-side-panel';
  import LevelSelection  from '../../components/LevelSelection';
  import { handleApiResponse } from '../../Services/handleApiResponse'; 
  import '../../reportCenter.scss'

  const Billing = ({ formData,  showSnackbar, onClose, isEdit= false }) => {
    const [reportRecipients, setreportRecipients] = useState([]);
    const [ftp, setftp] = useState([]);
    const [whiteLabelEmail, setWhiteLabelEmail] = useState([]);
    const [errors, setErrors] = useState({});
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]); 
    const [userinfo, setUserinfo] = useState([]); 
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]); 
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
    const { idaccount } = useParams();

    useEffect(() => {
      if (formData?.recipients) {
          setreportRecipients(
              formData.recipients.map((email) => ({ value: email }))
          );
      }
  }, [formData]);

    const [reportData, setReportData] = useState({
      name: formData?.name || "",
      report_id: formData?.report_id || "",
      account: idaccount,
      level: formData?.level || "",
      level_choices: formData?.level_choices || [],
      delivery_frequency: formData?.delivery_frequency || "",
      day_month: formData?.day_month || "",
      export_method: formData?.export_method || "",
      delevery_method_ftp:formData?.delevery_method_ftp || "",
      folder: formData?.folder || "",
      recipients :formData?.recipients || [],
      subject: formData?.subject || "",
      export_name: formData?.export_name || "",
      export_file_type: formData?.export_file_type || "",
      attached_file: formData?.attached_file || "",
      whitelabel_email : formData?.whitelabel_email || "",
    });

    const handleCreateReport = async () => {
      const sanitizedData = {
        report_id: reportData.id,
        account: parseInt(idaccount, 10),
        name: sanitizeString(reportData.name),
        level: sanitizeString(reportData.level),
        level_choices: reportData.level_choices,
        delivery_frequency: reportData.delivery_frequency,
        day_month: reportData.day_month,
        export_method: reportData.export_method,
        delevery_method_ftp:reportData.delevery_method_ftp,
        folder: reportData.folder,
        recipients :reportData.recipients,
        subject: reportData.subject,
        export_name: reportData.export_name,
        export_file_type: reportData.export_file_type,
        attached_file: reportData.attached_file,
        whitelabel_email : reportData.whitelabel_email,
      };
      
      const validationErrors = validateReportForm(sanitizedData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);  
      }

      try {
        const API_URL = isEdit ? APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/edit" : APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/add";
        const API_METHOD = isEdit ? "PATCH" : "POST";
        const response = await fetchService(API_URL, API_METHOD, JSON.stringify(sanitizedData));
        handleApiResponse(response, { isEdit, showSnackbar, setErrors, onClose});
        
      } catch (error) {
        if (showSnackbar) {
          showSnackbar("Something went wrong while creating the report.", "error");
        }
        console.error('Error creating report:', error);
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        const fetchServiceInstance = fetchService;

        try {
          // Parallel API calls
          const [ftpData, hotels, groups, userInfo, whiteLabelEmails] = await Promise.all([
            fetchFtp(fetchServiceInstance, idaccount, setErrors),
            fetchHotels(fetchServiceInstance, idaccount, setErrors),
            fetchGroups(fetchServiceInstance, idaccount, setErrors),
            fetchUserInfo(fetchServiceInstance, setErrors),
            fetchWhiteLabelEmail(fetchServiceInstance, idaccount, setErrors),
          ]);

          // Update state only for successful calls
          setftp(ftpData || []);
          setHotels(hotels || []);
          setGroups(groups || []);
          setUserinfo(userInfo?.[0] || null);
          setWhiteLabelEmail(whiteLabelEmails || []);
        } catch (error) {
          console.error('Error during data fetching:', error);
        }
      };

      fetchData();
    }, [idaccount]);

    useEffect(() => {
      setReportData(prev => ({
          ...prev,
          ...formData,
      }));
  }, [formData]);

    useEffect(() => {
      if (!reportData.level) {
        setReportData((prevData) => ({ ...prevData, level: 'ACCOUNT' }));  
      }
      if (!reportData.export_method) {
        setReportData((prevData) => ({ ...prevData, export_method: "EMAIL" })); 
      }
      if (!reportData.export_file_type) {
        setReportData((prevData) => ({ ...prevData, export_file_type: "csv" })); 
      }
      if (!reportData.attached_file) {
        setReportData((prevData) => ({ ...prevData, attached_file: "ZIP_COMPRESSION" })); 
      }
      if (!reportData.delivery_frequency) {
        setReportData((prevData) => ({ ...prevData, delivery_frequency: "ONCE" })); 
      }
      
    }, [reportData, setReportData]);

    const handleRecipientsChange = useCallback((e) => {
      let parsedValue = [];
      try {
          parsedValue = JSON.parse(e.detail.value);
      } catch (error) {
          console.error("Parsing error:", error);
      }

      const updatedRecipients = parsedValue
          .map(tag => tag.value.split(',').map(email => email.trim()))
          .flat();

      setReportData((prevData) => ({
          ...prevData,
          recipients: updatedRecipients,
      }));
      setreportRecipients(updatedRecipients);
    }, [setReportData]);

    const handleLevelChange = (newLevel) => {
      setReportData((prevState) => ({
        ...prevState,
        level: newLevel,
        level_choices: newLevel === 'HOTEL' ? selectedHotels : newLevel === 'GROUP' ? selectedGroups : [],
      }));
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setReportData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    
    };

    const handleOpenModal = () => {
      setIsSlidingPanelOpen(true)
    };

    useEffect(() => {
      if (reportData.level === "HOTEL" && reportData.level_choices?.length > 0) {
        const preselectedHotels = reportData.level_choices
          .map((id) => hotels.find((hotel) => hotel.id === id))
          .filter((hotel) => hotel); 
        setSelectedHotels(preselectedHotels);
      }
      if (reportData.level === "GROUP" && reportData.level_choices?.length > 0) {
        const preselectedGroups = reportData.level_choices
          .map((id) => groups.find((group) => group.id === id))
          .filter((group) => group); 
        setSelectedGroups(preselectedGroups);
      }
    }, [reportData.level, reportData.level_choices, groups, hotels]);

    const handleHotelSelect = useCallback((newSelectedHotels) => {
      const selectedValues = newSelectedHotels.map((h) => h.id ); 
      setSelectedHotels(newSelectedHotels);
      setReportData((prevData) => ({
        ...prevData,
        level_choices: selectedValues,
      }));
    }, []);

    const handleGroupSelect = useCallback((newSelectedGroups) => {
      const selectedValues = newSelectedGroups.map((h) => h.id ); 
      setSelectedGroups(newSelectedGroups);
      setReportData((prevData) => ({
        ...prevData,
        level_choices: selectedValues,
      }));
    }, []);


  const handleToggleSettings = () => {
      setShowAdditionalSettings(!showAdditionalSettings);
    };

    return (
    <div className="report-form-container">
      <Grid item xs={12}>
       <Grid item xs={10} container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={8}>
              <TextField
                  fullWidth
                  name="name"
                  className="form-input"
                  placeholder='Report Name:'
                  value={reportData.name}
                  onChange={handleInputChange}
                  error={!!errors.name} 
                  helperText={errors.name || ''}
                />
              </Grid>
          </Grid>
          <Grid item xs={4} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.level}>
                  <Typography variant="body2">Report Level Breakdown</Typography>
                  <Select
                    fullWidth
                    value={reportData.level}
                    name="level"
                    className="form-select"
                    onChange={(e) => {
                      handleInputChange(e);
                      handleLevelChange(e.target.value);
                    }}
                    error={!!errors.level} 
                  >
                    <MenuItem value=""><em>Select Report Level</em></MenuItem>
                    <MenuItem value="ACCOUNT">Account</MenuItem>
                    {userinfo?.canUseGroup && (
                      <MenuItem value="GROUP">Group</MenuItem>
                    )}
                    <MenuItem value="HOTEL">Hotel</MenuItem>
                  </Select>
                  {errors.level && <FormHelperText>{errors.level}</FormHelperText>}
                </FormControl>
              </Grid>
              {(reportData.level === 'HOTEL' ||  reportData.level === 'GROUP') && (
                <Grid item xs={12}>
                  <div>
                    <label style={{ fontSize: "14px" }}>Selected {reportData.level === 'HOTEL' ? 'Hotel' : 'Group' }: </label>
                    <span style={{ fontSize: "12px" }}> 
                      {reportData.level === 'HOTEL' ? selectedHotels.length : '' } 
                      {reportData.level === 'GROUP' ? selectedGroups.length : '' } 
                    </span>
                  </div>
                  <CustomButtonWithIcon style={{ marginTop: '6px'}}
                      color="#b0acac"
                      hoverColor="#12794F"
                      titleColor="#373F41"
                      hoverTitleColor={"white"}
                      Icon={DmpIcons.ActionsIconsSVG.AddIconSVG}
                      iconSize={"14px"}
                      width={"120px"}
                      height={"24px"}
                      borderRadius="20px"
                      textStyle={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '14.52px',
                          textAlign: 'center',
                      }}                  
                      onClick={handleOpenModal}>
                      Add {reportData.level === 'HOTEL' ? 'Hotel' : 'Group' }
                  </CustomButtonWithIcon>
                </Grid>
              )}

                {/* Hotel Selection Dialog */}
                <SlidingPanel type="right" isOpen={isSlidingPanelOpen} size={ 40} backdropClicked={() => setIsSlidingPanelOpen(false)}>
                  {reportData.level === 'HOTEL' && 
                    <LevelSelection
                      level="HOTEL"
                      levelChoices={selectedHotels}
                      data={hotels}
                      onHotelSelect={handleHotelSelect}
                      onClose={() => setIsSlidingPanelOpen(false)}
                    />
                  }
                  {reportData.level === 'GROUP' && 
                    <LevelSelection
                      level="GROUP"
                      levelChoices={selectedGroups}
                      data={groups}
                      onHotelSelect={handleGroupSelect}
                      onClose={() => setIsSlidingPanelOpen(false)}
                    />
                  }
                </SlidingPanel>
            </Grid>
        
        {/* Row 2 */}
        <Grid container spacing={2} item xs={12}> 
        
          <Grid item xs={4} container>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.export_method}>
                <Typography variant="body2">Choose Delivery Method</Typography>
                <Select
                  fullWidth
                  value={reportData.export_method}
                  name="export_method"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_method: e.target.value });
                  }}
                >
                  <MenuItem value="EMAIL">Email</MenuItem>
                  {ftp.length !== 0 && ( <MenuItem value="FTP">FTP</MenuItem> )}
                </Select>
                {errors.export_method && <FormHelperText>{errors.export_method}</FormHelperText>}
              </FormControl>
            </Grid>
            {reportData.export_method === 'FTP' && (
                <>
                <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.delevery_method_ftp}>
                    <Typography variant="body2">Choose FTP</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.delevery_method_ftp || ''}
                      onChange={(e) => setReportData({ ...reportData, delevery_method_ftp: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select FTP
                      </MenuItem>

                      {ftp.length > 0 ? (
                        ftp.map((ftp) => (
                          <MenuItem key={ftp.id} value={ftp.id}>
                            {ftp.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No ftp Available</MenuItem>
                      )}
                    </Select>
                    {errors.delevery_method_ftp && <FormHelperText>{errors.delevery_method_ftp}</FormHelperText>}
                  </FormControl>
                  </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2"> FTP Folder</Typography>
                      <TextField
                          name="folder"
                          fullWidth
                          className="form-input"
                          value={reportData.folder || ''}
                          onChange={(e) => setReportData({ ...reportData, folder: String(e.target.value) })}
                          displayempty="true"
                        />
                    </FormControl>
                  </Grid>
                </>
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.date_range}>
                <Typography variant="body2">Delivery Frequency</Typography>
                <Select
                  fullWidth
                  value={reportData.delivery_frequency}
                  name="delivery_frequency"
                  className="form-select"
                  onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, delivery_frequency: e.target.value });
                    }}
                  error={!!errors.delivery_frequency}
                >
                  <MenuItem value="ONCE">Once</MenuItem>
                  <MenuItem value="MONTHLY">Monthly</MenuItem>
                </Select>
                {errors.delivery_frequency && <FormHelperText>{errors.delivery_frequency}</FormHelperText>}
              </FormControl>
            </Grid>
              {reportData.delivery_frequency === 'MONTHLY' && (
                  <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.day_month}>
                    <Typography variant="body2">Day of Month</Typography>
                    <Select
                        fullWidth
                        className="form-select"
                        value={reportData.day_month || 1}
                        onChange={(e) => setReportData({ ...reportData, day_month: String(e.target.value) })}
                        displayEmpty={true}
                        renderValue={(selected) => {
                          if (!selected) {
                              return (
                                  <span style={{
                                      fontSize: "12px",
                                      color: "#BAB7B7"
                                  }}>Select a day</span>
                              );
                          }
                          return selected;
                      }}
                      >
                        <MenuItem value="" disabled>
                          Select Day
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                          <MenuItem key={'day-of-month-'+index + 1}  value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.day_month && <FormHelperText>{errors.day_month}</FormHelperText>}
                  </FormControl>
                </Grid>
              )}
            </Grid>
        </Grid>

          {/* Row 3 */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.recipients}>
                <Typography variant="body2">Recipients</Typography>
                <Tags
                      whitelist={reportRecipients}
                      placeholder="Recipients"
                      settings={{
                          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      }}
                      value={reportRecipients}
                      defaultValue={reportRecipients.join(',')}
                      onChange={handleRecipientsChange}
                  />
                {errors.recipients && <FormHelperText>{errors.recipients}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          {/* Subject */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
            <FormControl fullWidth error={!!errors.subject}>
                  <Typography variant="body2">Subject</Typography>
                  <TextField
                    name="subject"
                    fullWidth
                    placeholder="Subject"
                    className="form-input"
                    value={reportData.subject}
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, subject: e.target.value });
                    }}
                  />
                  {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
                </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File name */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.export_name}>
                <Typography variant="body2">Export File Name</Typography>
                <TextField
                  name="export_name"
                  fullWidth
                  placeholder="[TYPE_REPORT]-[ID_REPORT]-[ACCOUNT]-[DATETIME]"
                  className="form-input"
                  value={reportData.export_name}
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_name: e.target.value });
                  }}
                />
                {errors.export_name && <FormHelperText>{errors.export_name}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File Type */}
          <Grid container item spacing={3}  xs={12}>
            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.export_file_type}>
                <Typography variant="body2">Export File Type</Typography>
                <Select
                  fullWidth
                  value={reportData.export_file_type}
                  name="export_file_type"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_file_type: e.target.value });
                  }}
                  error={!!errors.export_file_type}
                >
                  <MenuItem value="csv">CSV</MenuItem>
                  <MenuItem value="xlsx">Excel</MenuItem>
                </Select>
                {errors.export_file_type && (
                  <FormHelperText>{errors.export_file_type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} container item spacing={1} sx={{ marginTop: '10px' }}>
          <Grid  item xs={9}> 
            <Grid container item xs={10}>
                  {showAdditionalSettings && (
                  <>
                  <Grid item xs={5}  sx={{ marginBottom: '10px' }}>
                  <FormControl fullWidth error={!!errors.attached_file}>
                    <Typography variant="body2">Attached File</Typography>
                    <Select
                      fullWidth
                      value={reportData.attached_file}
                      name="attached_file"
                      className="form-select"
                      onChange={(e) => {
                        handleInputChange(e);
                        setReportData({ ...reportData, attached_file: e.target.value });
                      }}
                      error={!!errors.attached_file}
                    >
                      <MenuItem value="ZIP_COMPRESSION">Zip file</MenuItem>
                        <MenuItem value="NO_COMPRESSION">Uncompressed file</MenuItem>
                        <MenuItem value="NO_ATTACHMENT">No attachment</MenuItem>
                    </Select>
                    {errors.attached_file && (
                      <FormHelperText>{errors.attached_file}</FormHelperText>
                    )}
                  </FormControl>
                  </Grid>
                  { whiteLabelEmail.length !== 0 &&  ( 
                      <Grid item xs={5} sx={{ marginLeft: '1rem' }}>
                        <FormControl fullWidth>
                          <Typography variant="body2">WhiteLabel Email</Typography>
                          <Select
                            fullWidth
                            className="form-select"
                            value={reportData.whitelabel_email || 'None Selected'}
                            onChange={(e) => setReportData({ ...reportData, whitelabel_email: String(e.target.value) })}
                            displayempty="true"
                          >
                          <MenuItem value="None Selected" disabled>
                            None Selected
                          </MenuItem>
                          {whiteLabelEmail.length > 0 ? (
                            whiteLabelEmail.map((whiteLabelEmail) => (
                              <MenuItem key={whiteLabelEmail.id} value={whiteLabelEmail.id}>
                                {whiteLabelEmail.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Whitelabel Email Available</MenuItem>
                          )}
                        </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </>
                  )}
              <Grid container  spacing={2} item xs={12}  className="report-form-footer-section">
                <Grid  item xs={12}   > 
                    <div className="button-container">
                        <CustomButtonWithIcon className="report-form-button" onClick={handleCreateReport} >
                            {isEdit ? 'Update Report' : 'Create Report'} 
                        </CustomButtonWithIcon>
                        <CustomButtonWithIcon className="report-form-button" onClick={onClose} >
                            Cancel
                        </CustomButtonWithIcon>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
          <Grid  item xs={3}  sx={ {marginTop: '18px' }}>
            <Button className="additional-settings" onClick={handleToggleSettings}  sx={{ marginRight: 2 }} >
              <DmpIcons.ActionsIcons.CogsGrayIcon className="cogicon" />
              Additional Settings
            </Button>
          </Grid>
        </Grid>
        {errors.unspecified && (
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <FormControl className="error-message" fullWidth error={!!errors.unspecified}>
                {errors.unspecified && <FormHelperText>{errors.unspecified}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
    );
  };

  export default Billing;
