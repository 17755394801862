import React, {useEffect, useMemo, useRef, useState} from 'react';
import './biddingList.scss';
import Icons from "../../../components/DmpIcons/icons";
import BiddingRuleManager from "../BiddingRule/BiddingRuleManager";
import SlidingPanel from "../SlidingPanel";
import axios from "axios";
import {APP_BIDDING_CENTER_API_ENDPOINT} from "../../../constants/api_urls";
import {useParams} from "react-router-dom";
import HotelListPanel from "../HotelTable/HotelsListPanel";
import {Chip} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DmpIcons from "../../../components/DmpIcons/icons";
import { getUserToken } from "utils/functions/userToken";

const BiddingList = ({ biddingRules, onRefresh }) => {
    const [expandedRule, setExpandedRule] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);
    const [isHotelsSelectionOpen, setIsHotelsSelectionOpen] = useState(false);
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ruleLogo, setRuleLogo] = useState(null);
    const { idaccount } = useParams();
    const [isEditBackdropDisabled, setIsEditBackdropDisabled] = useState(false);
    const [isLinkedHotelsBackdropDisabled, setIsLinkedHotelsBackdropDisabled] = useState(true);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const switchBackdropDisabled = () => {
        setIsEditBackdropDisabled(!isEditBackdropDisabled);
        setIsLinkedHotelsBackdropDisabled(!isLinkedHotelsBackdropDisabled);
    }

    const showSnackbar = (message, severity='success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    }

    const token = getUserToken();

    const menuRef = useRef(null);
    const settingsIconRefs = useRef({});

    const hotelColumns = useMemo(() => [
        { accessorKey: 'id', header: 'ID', sortable: true },
        { accessorKey: 'name', header: 'Hotel Name', sortable: true },
    ], []);

    const groupColumns = useMemo(() => [
        { accessorKey: 'id', header: 'ID', sortable: true },
        { accessorKey: 'name', header: 'Group Name', sortable: true },
    ], []);

    const assignLogo = (rule) => {
        if (!rule) return;
        if (rule.bidding_rule_type === "GOOGLE_BIDDING_RULE") {
            setRuleLogo(Icons.MetasearchIconsSVG.GoogleHotelAdsIconSVG);
        } else if (rule.bidding_rule_type === "TRIVAGO_BIDDING_RULE") {
            setRuleLogo(Icons.MetasearchIconsSVG.TrivagoIconSVG);
        } else if (rule.bidding_rule_type === "TRIPADVISOR_BIDDING_RULE") {
            setRuleLogo(Icons.MetasearchIconsSVG.TripadvisorIconSVG);
        }
    };

    const toggleEditForm = (rule) => {
        if (!rule) return;
        setSelectedRule(rule);
        const newState = !isEditFormOpen;
        assignLogo(rule);
        setIsEditFormOpen(newState);
        setIsSlidingPanelOpen(newState);
    };

    const handleCloseParentPanel = () => {
        setIsEditFormOpen(false);
        setIsSlidingPanelOpen(false);
    };

    const handleLinkedHotelsClick = () => {
        switchBackdropDisabled();
        setIsModalOpen(true);
        fetchData();
    };

    const handleCloseChildPanel = () => {
        switchBackdropDisabled()
        setIsModalOpen(false);
    };

    const toggleExpand = (ruleId) => {
        setExpandedRule(expandedRule === ruleId ? null : ruleId);
    };

    const getActionPhrase = (bidChangeType) => {
        switch (bidChangeType) {
            case 0: return 'Increase by';
            case 1: return 'Decrease by';
            case 2: return 'Increase by';
            case 3: return 'Decrease by';
            default: return '';
        }
    };

    const fetchData = async () => {
        if (!selectedRule) return; // Assurez-vous que selectedRule est défini
        try {
            const [hotelsResponse, groupsResponse] = await Promise.all([
                axios.get(
                    `${APP_BIDDING_CENTER_API_ENDPOINT}/api/get-linked-hotels?id=${selectedRule.id}&id_account=${idaccount}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ),
                axios.get(
                    `${APP_BIDDING_CENTER_API_ENDPOINT}/api/get-linked-groups?id=${selectedRule.id}&id_account=${idaccount}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            ]);
            setHotels(Array.isArray(hotelsResponse.data) ? hotelsResponse.data : []);
            setGroups(Array.isArray(groupsResponse.data) ? groupsResponse.data : []);
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        }
    };

    const generateBiddingUpdate = (steps, bidChangeType, bidChangeValue) => {
        if (!steps || steps.length === 0) return [];
        const action = getActionPhrase(bidChangeType);
        return steps.map(step => {
            const value = bidChangeValue;
            const percentageSymbol = (bidChangeType === 0 || bidChangeType === 1) ? '%' : '';
            return `${action} ${value}${percentageSymbol} from ${step.step_start} to ${step.step_end}`;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideMenu =
                menuRef.current && menuRef.current.contains(event.target);
            const isClickOnSettingsIcon = Object.values(settingsIconRefs.current).some(
                (ref) => ref && ref.contains(event.target)
            );

            if (!isClickInsideMenu && !isClickOnSettingsIcon && !isSlidingPanelOpen) {
                setIsHotelsSelectionOpen(false);
                setSelectedRule(null);
            }
        };

        if (isHotelsSelectionOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isHotelsSelectionOpen, isSlidingPanelOpen]);

    const renderLogo = (biddingRuleType) => {
        switch (biddingRuleType) {
            case "TRIVAGO_BIDDING_RULE":
                return <Icons.MetasearchIcons.TrivagoIcon alt="Trivago logo" />;
            case "TRIPADVISOR_BIDDING_RULE":
                return <Icons.MetasearchIcons.TripadvisorIcon alt="Tripadvisor logo" />;
            case "GOOGLE_BIDDING_RULE":
                return <Icons.MetasearchIcons.GoogleHotelAdsIcon alt="Google Hotel Ads logo" />;
            default:
                return null;
        }
    };

    return (
        <div className="bidding-list">
            {biddingRules.map((rule, index) => {
                const biddingUpdateTriggered = generateBiddingUpdate(
                    rule.steps_rule_triggered,
                    rule.bid_change_type,
                    rule.bid_change
                );

                const biddingUpdateNotTriggered = generateBiddingUpdate(
                    rule.steps_rule_not_triggered,
                    rule.bid_change_type_inv,
                    rule.bid_change_inv
                );

                // Détermine si la section Triggered doit être affichée
                const showTriggeredSection = rule.bid_change !== undefined && rule.bid_change !== null;

                // Détermine si la section Not Triggered doit être affichée
                const showNotTriggeredSection = rule.bid_change_inv !== undefined && rule.bid_change_inv !== null;

                return (
                    <div key={index} className="bidding-rule">
                        <div className="rule-header" onClick={() => toggleExpand(rule.id)}>
                            <div className="source-logo">
                                {renderLogo(rule.bidding_rule_type)}
                            </div>
                            <div className="rule-title">
                                <h3>BIDDING RULE #{rule.id} | {rule.bidding_rule_name}</h3>
                            </div>
                            <div className="expand-icon">
                                <span className="arrow-icon">
                                    <div className={expandedRule === rule.id ? 'rotate-up' : 'rotate-down'}>
                                        <DmpIcons.AccessibilityIcons.ThinDownArrowCustomFillColorIcon className="custom-color-arrow"  />
                                    </div>
                              </span>
                            </div>
                            <div
                                className="settings-icon"
                                ref={el => settingsIconRefs.current[rule.id] = el}
                            >
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    toggleEditForm(rule);
                                }}>
                                    <DmpIcons.ActionsIcons.CogsFillColorIcon className="custom-color-cogs"  />
                                </div>

                            </div>
                        </div>

                        {expandedRule === rule.id && (
                            <div className="rule-details-container">
                                <div className="separator"></div>
                                <div className="rule-details">
                                    <div className="details-first-section">
                                        {/* Contenu éventuel */}
                                    </div>
                                    <div className="details-second-section">
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Metric Comparison:</div>
                                            <div className="bidding-rule-section-content">Last {rule.metrics_compare} days</div>
                                        </div>
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Metric Frequency:</div>
                                            <div className="bidding-rule-section-content">Every {rule.bidding_frequency_daily} days</div>
                                        </div>

                                        {/* Section Bidding Update with Rules Triggered */}
                                        {showTriggeredSection && (
                                            <div className="bidding-update-section">
                                                <div className="bidding-rule-section-title" style={{lineHeight: "18px"}}>
                                                    Bidding Update with Rules Triggered:
                                                </div>
                                                <div className="bidding-rule-section-content">
                                                    <div className="base-value">Base value: {rule.bid_change}</div>
                                                    {biddingUpdateTriggered.length > 0 && (
                                                        biddingUpdateTriggered.map((phrase, idx) => (
                                                            <div key={`triggered-${idx}`}>{phrase}</div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="details-third-section">
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Bidding Conditions:</div>
                                            <div className="bidding-rule-section-content">{rule.rules}</div>
                                        </div>
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Min/Max Value:</div>
                                            <div className="bidding-rule-section-content">{rule.bid_min} / {rule.bid_max}</div>
                                        </div>

                                        {/* Section Bidding Update with Rules Not Triggered */}
                                        {showNotTriggeredSection && (
                                            <div className="bidding-update-section">
                                                <div className="bidding-rule-section-title" style={{lineHeight: "18px"}}>Bidding Update with Rules Not Triggered:</div>
                                                <div className="bidding-rule-section-content">
                                                    <div className="base-value">Base value: {rule.bid_change_inv}</div>
                                                    {biddingUpdateNotTriggered.length > 0 && (
                                                        biddingUpdateNotTriggered.map((phrase, idx) => (
                                                            <div key={`nottriggered-${idx}`}>{phrase}</div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="details-fourth-section">
                                        <div className="recipients-container">
                                            <div className="recipients-title">
                                                <div className="bidding-rule-section-title">Recipients:</div>
                                            </div>
                                            <div className="recipients-list">
                                                <div className="bidding-rule-section-content">
                                                    {rule.alert_recipient[0]
                                                        .split(', ')
                                                        .map((email, index) => (
                                                            <div key={index}>{email}</div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}

            {/* SlidingPanel en dehors de la boucle */}
            <SlidingPanel
                isOpen={isEditFormOpen}
                onClose={handleCloseParentPanel}
                size={50}
                backdropDisabled={isEditBackdropDisabled}
                sx={{ right: 0 }}
            >
                {selectedRule && (
                    <div className="bidding-panel-container">
                        <div className="platform-container" style={{ width: '100%' }}>
                            <div className="panel-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <div
                                        onClick={handleCloseParentPanel}
                                        className="close-panel-circle"
                                    >
                                        <DmpIcons.ActionsIcons.ClosePanelFillColorIcon className="close-panel-icon" />
                                    </div>
                                    <h2 style={{
                                        fontSize: '20px',
                                        textAlign: 'left',
                                        fontWeight: 400,
                                        fontFamily: 'Quincy CF, serif',
                                    }}>
                                        Edit Bidding Rule
                                    </h2>
                                </div>
                                <Chip
                                    label="Linked Hotels"
                                    variant="outlined"
                                    className="linked-hotel-button"
                                    onClick={handleLinkedHotelsClick}
                                />
                            </div>
                            <div className="form-container">
                                <div className="platform-container">
                                    <div className="collapsible-form">
                                        <div className="bidding-center-collapsible-header expanded">
                                            <h2 className="platform-title">{selectedRule.bidding_rule_shortname} | {selectedRule.bidding_rule_name}</h2>
                                            <div style={{ display: "flex", gap: '5px' }}>
                                                <img src={ruleLogo} alt="logo" />
                                                <div className="bidding-rule-currency">{selectedRule.currency}</div>
                                            </div>
                                        </div>
                                        <BiddingRuleManager
                                            biddingRuleData={selectedRule}
                                            action="rule-action"
                                            actionType="edit"
                                            onClose={handleCloseParentPanel}
                                            onRefresh={onRefresh}
                                            showSnackbar={showSnackbar}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectedRule && (
                    <HotelListPanel
                        backdropDisabled={isLinkedHotelsBackdropDisabled}
                        open={isModalOpen}
                        handleClose={handleCloseChildPanel}
                        hotels={hotels}
                        groups={groups}
                        hotelColumns={hotelColumns}
                        groupColumns={groupColumns}
                    />
                )}
            </SlidingPanel>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BiddingList;
