import axios from "axios";
import { APP_GOOGLE_PMAX_API_ENDPOINT } from "../../../../constants/api_urls";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Update Campaign Status API Call
 *
 * This function sends a `PUT` request to update the campaign's status.
 *
 * @param {object} payload - The payload containing campaign details.
 * @param {number} payload.campaign_id - The ID of the campaign to be updated.
 * @param {string} payload.campaign_status - The new campaign status.
 *     Available values: "PAUSED", "ENABLED".
 *
 * @returns {Promise<object>} - API response data if successful.
 * @throws {object|string} - Error response from the API or a default error message.
 */
export const updateCampaignStatus = async (payload) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.put(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/edit-campaign/update-status/`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error.response?.data || "Failed to update campaign status.";
    }
};