import React from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Grid, Tooltip } from "@mui/material";
import chroma from "chroma-js";

const TopMarketsMap = ({ selectedMarkets, worldMap, selectedOption, currencySymbol = null }) => {
    const darkestGreen = "#12794F";
    const lightestGreen = "#EEFCF6";
    const white = "#FFFFFF";

    const values = [
        ...selectedMarkets.map((market) => market[selectedOption] ?? 0),
        0
    ];

    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    const colorScale = chroma
        .scale([lightestGreen, darkestGreen])
        .domain([minValue, maxValue]);

    return (
        <Grid item xs={12} className="top-market-map-container">
            <ComposableMap
                projection="geoMercator"
                projectionConfig={{ scale: 150 }}
                style={{ width: "100%", height: "300px" }}
            >
                <ZoomableGroup center={[2.2137, 46.2276]}>
                    <Geographies geography={worldMap}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const matchingMarket = selectedMarkets.find(
                                    (market) => market.market_code === geo.id
                                );

                                const fillColor =
                                    matchingMarket && matchingMarket[selectedOption] !== null
                                        ? colorScale(Math.min(matchingMarket[selectedOption], maxValue)).hex()
                                        : white;

                                return matchingMarket ? (
                                    <Tooltip
                                        key={geo.rsmKey}
                                        title={['cost', 'generated', 'cpc'].some(opt => selectedOption.includes(opt))
                                            ? `${matchingMarket.market_name}: 
                                                ${currencySymbol}${matchingMarket[selectedOption]} ${selectedOption.replace("_kpi", "")}`
                                            : `${matchingMarket.market_name} : 
                                                ${matchingMarket[selectedOption]} ${selectedOption.replace("_kpi", "")}`}
                                        arrow
                                    >
                                        <Geography
                                            geography={geo}
                                            fill={fillColor}
                                            stroke="#000"
                                            style={{
                                                default: { fill: fillColor, outline: "none" },
                                                hover: { fill: fillColor, outline: "none" },
                                                pressed: { fill: fillColor, outline: "none" },
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={fillColor}
                                        stroke="#000"
                                        style={{
                                            default: { fill: fillColor, outline: "none" },
                                            hover: { fill: fillColor, outline: "none" },
                                            pressed: { fill: fillColor, outline: "none" },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </Grid>
    );
}

export default TopMarketsMap;