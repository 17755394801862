import React, { useState } from "react";
import CampaignAccordion from "../googleSearchAdsFormComponents/Campaign/CampaignAccordion";
import ObjectiveAccordion from "../googleSearchAdsFormComponents/Objective/ObjectiveAccordion";
import KeywordsAccordion from "../googleSearchAdsFormComponents/Keywords/KeywordsAccordion";
import AdsCreativeAccordion from "../googleSearchAdsFormComponents/AdsCreative/AdsCreativeAccordion";
import CampaignSettingsAccordion from "../googleSearchAdsFormComponents/CampaignSettings/CampaignSettingsAccordion";
import {
  validateAllKeywords,
  validateCampaignSettings,
  validateFullCampaignSettings,
  validateKeywords,
} from "../googleSearchAdsFormComponents/Utils/validators";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const GoogleSearchAdsCampaignCreator = ({
  expandedPanel,
  setExpandedPanel,
  selectedObjective,
  setSelectedObjective,
  visitedPanels,
  handleNext,
  selectedCountries,
  setSelectedCountries,
  selectedLanguage,
  setSelectedLanguage,
  targetValue,
  setTargetValue,
  biddingStrategy,
  setBiddingStrategy,
  campaignName,
  setCampaignName,
  targetCpa,
  setTargetCpa,
  targetRoas,
  setTargetRoas,
  maxCpcLimit,
  setMaxCpcLimit,
  impressionShare,
  setImpressionShare,
  adLocation,
  setAdLocation,
  monthlyBudget,
  setMonthlyBudget,
  keywords,
  setKeywords,
  negativeKeywords,
  setNegativeKeywords,
  headlines,
  setHeadlines,
  descriptions,
  setDescriptions,
  siteLinks,
  setSiteLinks,
  callOuts,
  setCallOuts,
  structuredSnippets,
  setStructuredSnippets,
  finalURL,
  setFinalURL,
  businessName,
  setBusinessName,
  calls,
  setCalls,
  displayPaths,
  setDisplayPaths,
  dailyBudgetCap,
  setDailyBudgetCap,
  currencySymbol
}) => {
  const accountId = useSelector((state) => state?.account?.account?.account_id);
  const { hotel_id = [] } = useSelector((state) => state?.account);
  const [errors, setErrors] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const handleNextWithValidation = async (currentPanel) => {
    let validationResults = {};
    let results = {};

    if (currentPanel === "settings") {
      const bidValues = {
        MAXIMIZE_CONVERSIONS: targetCpa,
        MAXIMIZE_CONVERSION_VALUE: targetRoas,
        MAXIMIZE_CLICKS: maxCpcLimit,
        MANUAL_CPC: maxCpcLimit,
        MANUAL_CPC_ENHANCED: maxCpcLimit,
        TARGET_IMPRESSION_SHARE: impressionShare,
      };
      const payload = {
        campaign_type: selectedObjective,
        campaign_name: campaignName,
        bidding_strategy: {
          bidding_strategy_option: biddingStrategy,
          bidding_value: bidValues?.[biddingStrategy],
          bidding_max_value:
            biddingStrategy === "TARGET_IMPRESSION_SHARE"
              ? maxCpcLimit
              : undefined,
          ad_location: adLocation ?? undefined,
        },
        budget: {
          default_budget: Number(targetValue),
          is_daily_budget: dailyBudgetCap,
          ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
            acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
            return acc;
          }, {}),
        },
        campaign_lang: selectedLanguage,
        point_of_sales: selectedCountries,
      };
      results = await validateFullCampaignSettings(
        payload,
        accountId,
        hotel_id?.[0]
      );

      validationResults = validateCampaignSettings({
        campaignName,
        biddingStrategy,
        targetCpa,
        targetRoas,
        maxCpcLimit,
        impressionShare,
        adLocation,
        targetValue,
      });
    } else if (currentPanel === "keywords") {
      results = await validateAllKeywords({
        keywords: keywords.map((keyword) => ({
          keywordText: keyword.keyword,
          matchType: keyword?.matchType,
        })),
        keywords_negative: negativeKeywords.map((keyword) => ({
          keywordText: keyword.keyword,
          matchType: keyword?.matchType,
        })),
      });
      validationResults = validateKeywords({ keywords, negativeKeywords });

      if (results?.data?.keywords?.[0]?.errorMessage) {
        setToastMessage(results?.data?.keywords?.[0]?.errorMessage);
        setToastType("error");
        setShowToast(true);
        return;
      }
    }

    setErrors(results?.error || validationResults);
    handleNext(currentPanel);
  };

  return (
    <>
      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="1. Select Your Objective"
        expanded={expandedPanel === "objective"}
        onChange={() => setExpandedPanel("objective")}
        onNext={() => handleNext("objective")}
        disabled={!visitedPanels.includes("objective")}
        visited={visitedPanels.includes("objective")}
      >
        <ObjectiveAccordion
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
        />
      </CampaignAccordion>

      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="2. Campaign Settings"
        expanded={expandedPanel === "settings"}
        onChange={() => setExpandedPanel("settings")}
        onNext={() => handleNextWithValidation("settings")}
        disabled={!visitedPanels.includes("settings")}
        visited={visitedPanels.includes("settings")}
      >
        <CampaignSettingsAccordion
          biddingStrategy={biddingStrategy}
          setBiddingStrategy={setBiddingStrategy}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          targetCpa={targetCpa}
          setTargetCpa={setTargetCpa}
          targetRoas={targetRoas}
          setTargetRoas={setTargetRoas}
          maxCpcLimit={maxCpcLimit}
          setMaxCpcLimit={setMaxCpcLimit}
          impressionShare={impressionShare}
          setImpressionShare={setImpressionShare}
          adLocation={adLocation}
          setAdLocation={setAdLocation}
          targetValue={targetValue}
          setTargetValue={setTargetValue}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          monthlyBudget={monthlyBudget}
          setMonthlyBudget={setMonthlyBudget}
          errors={errors}
          dailyBudgetCap={dailyBudgetCap}
          setDailyBudgetCap={setDailyBudgetCap}
          currencySymbol={currencySymbol}
        />
      </CampaignAccordion>

      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="3. Keywords"
        expanded={expandedPanel === "keywords"}
        onChange={() => setExpandedPanel("keywords")}
        onNext={() => handleNextWithValidation("keywords")}
        disabled={!visitedPanels.includes("keywords")}
        visited={visitedPanels.includes("keywords")}
      >
        <KeywordsAccordion
          keywords={keywords}
          setKeywords={setKeywords}
          negativeKeywords={negativeKeywords}
          setNegativeKeywords={setNegativeKeywords}
          errors={errors}
        />
      </CampaignAccordion>

      <CampaignAccordion
        expandIcon={<ExpandMore />}
        title="4. Ads Creative"
        expanded={expandedPanel === "adsCreative"}
        onChange={() => setExpandedPanel("adsCreative")}
        onNext={() => handleNext("adsCreative")}
        disabled={!visitedPanels.includes("adsCreative")}
        visited={visitedPanels.includes("adsCreative")}
        isCreateButton={true}
      >
        <AdsCreativeAccordion
          headlines={headlines}
          setHeadlines={setHeadlines}
          descriptions={descriptions}
          setDescriptions={setDescriptions}
          siteLinks={siteLinks}
          setSiteLinks={setSiteLinks}
          callOuts={callOuts}
          setCallOuts={setCallOuts}
          structuredSnippets={structuredSnippets}
          setStructuredSnippets={setStructuredSnippets}
          finalURL={finalURL}
          setFinalURL={setFinalURL}
          businessName={businessName}
          setBusinessName={setBusinessName}
          calls={calls}
          setCalls={setCalls}
          displayPaths={displayPaths}
          setDisplayPaths={setDisplayPaths}
        />
      </CampaignAccordion>
      <Snackbar
        open={showToast}
        autoHideDuration={7000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GoogleSearchAdsCampaignCreator;
