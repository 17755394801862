import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { APP_TRIVAGO_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import MarketsFilterMenu from "components/MarketFilterMenu";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";
import { trivagoMultiplierFormFields } from "./options";
import "./multipier.scss";
import {
    setOpenEditBidding,
    updateEditBidHotels,
    updateEditBidMarkets,
} from "store/slices/editBid/editBid";
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";
import {validateMultipliersPayload} from "../formValidator";
import {getUserToken} from "../../../../../utils/functions/userToken";
const TrivagoMultipliers = () => {
  const token = getUserToken();
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const { hotels = [], markets = [] } = useSelector((state) => state?.editBid);
  const dispatch = useDispatch();
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState(hotels);
  const [selectedMarkets] = useState(markets);

  const [updateBidPayload, setUpdateBidPayload] = useState({
    account_id: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const {
    hotel_list = [],
    group_list = [],
    market_list = [],
    can_use_group,
  } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: " Select a hotel ",
      options: hotel_list,
    },

    {
      id: "market_id",
      label: "Market",
      type: "select",
      placeholder: " Select a value ",
      options: market_list,
    },
  ];

  const [errors, setErrors] = useState({});

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      axios
        .get(
          `${APP_TRIVAGO_BIDDING_API_ENDPOINT}/api/trivago-meta/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id,token]);

  const handleChangeFieldValue = useCallback((field, value) => {
    setUpdateBidPayload((prev) => ({ ...prev, [field]: value }));
  }, []);

    useEffect(() => {
        handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id));
        handleChangeFieldValue("market_id",markets.map((s) => s.market_id));
    }, [hotels, markets,handleChangeFieldValue,selectedHotelOrGroupType]);

  const handleSave = useCallback(() => {
      if(!validateMultipliersPayload(updateBidPayload,setErrors)) {
          axios
              .put(
                  `${APP_TRIVAGO_BIDDING_API_ENDPOINT}/api/trivago-meta/update-bid/`,
                  updateBidPayload,
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  }
              )
              .then((res) => {
                  setShowToast(true);
              });
      }
  }, [updateBidPayload,token]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "market_id") {
                return (
                  <div className="form-grid-item">
                    {market_list?.length ? (
                      <MarketsFilterMenu
                        allMarkets={market_list?.map((market) =>
                          selectedMarkets?.find(
                            (m) => market?.market_id === m?.market_id
                          )
                            ? { ...market, isSelected: true }
                            : { ...market, isSelected: false }
                        )}
                        onApply={(selected) => {
                          dispatch(updateEditBidMarkets(selected));

                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id)
                          );
                        }}
                        error={errors.marketId}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }

              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item bidding_multiplier_height">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          backgroundColor: "white",
                          fontSize: "12px",
                          fontWeight: "400",
                          paddingLeft: "4px",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                        error={errors.hotelId}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list?.map((hotel) =>
                          selectedHotelsorGroups?.find((h) => h.id === hotel.id) || hotel_list.length ===1
                            ? { ...hotel, isSelected: true }
                            : { ...hotel, isSelected: false }
                        )}
                        allGroups={group_list?.map((group) =>
                          selectedHotelsorGroups?.find((h) => h.id === group.id)
                            ? { ...group, isSelected: true }
                            : { ...group, isSelected: false }
                        )}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          dispatch(updateEditBidHotels(selected));

                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            "hotel_id",
                            selected.map((s) => s.id)
                          );
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL" : "GROUP"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="fields-container bidding_multiplier_height">
            {trivagoMultiplierFormFields?.map((field) => {
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={field?.options || []}
                    hotelMenuOpen={openHotelMenu}
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    onChange={(val) => {
                      handleChangeFieldValue("bidding_strategy", {
                        bidding_type: val,
                      });
                    }}
                    labelKey={"label"}
                    errors={errors}
                  />
                </div>
              );
            })}
            {updateBidPayload?.bidding_strategy?.bidding_type && (
              <div className="text-container">
                {updateBidPayload?.bidding_strategy?.bidding_type ===
                "NO_MULTIPLIERS" ? (
                  <p className="pre-formatted-text">
                    {"Remove all existing multipliers of each breakout."}
                  </p>
                ) : (
                  <pre className="pre-formatted-text">
                    {`This strategy automatically raises bid modifiers to increase the visibility of your hotel on Trivago.
The system will automatically increase the percentage of bid modifiers from your standard CPC to bring you the maximum potential clicks.
This will result in a higher CPC.`}
                  </pre>
                )}
              </div>
            )}
          </div>
        </div>
        {/* {updateBidPayload?.market_id?.length &&
          updateBidPayload?.[
            selectedHotelOrGroupType === "hotels" ? "hotel_id" : "group_id"
          ]?.length &&
          !!updateBidPayload?.bidding_strategy?.bidding_type && ( */}
          <div style={{display: 'flex', gap: 10, marginBottom:'-20px'}}>
              <CustomButtonWithIcon
                  color="#b0acac"
                  hoverColor="#12794F"
                  titleColor="#373F41"
                  hoverTitleColor={"white"}
                  width={"85px"}
                  height={"24px"}
                  borderRadius="20px"
                  textStyle={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '14.52px',
                      textAlign: 'center',
                  }}
                  onClick={handleSave}
              >
                  Save
              </CustomButtonWithIcon>
              <CustomButtonWithIcon
                  color="#b0acac"
                  hoverColor="#12794F"
                  titleColor="#373F41"
                  hoverTitleColor={"white"}
                  width={"85px"}
                  height={"24px"}
                  borderRadius="20px"
                  textStyle={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '14.52px',
                      textAlign: 'center',
                  }}
                  onClick={() => {
                      dispatch(setOpenEditBidding(false));
                  }}
              >
                  Cancel
              </CustomButtonWithIcon>
          </div>
          {/* )} */}
      </div>
        <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => {
                setShowToast(false);
            }}
        >
            <Alert severity="success" variant="filled" sx={{width: "100%"}}>
                Multipliers were saved
            </Alert>
        </Snackbar>
    </div>
  );
};

export default TrivagoMultipliers;
