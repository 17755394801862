export const isEmpty = (value) =>
    !value ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0);

export const validateBiddingOptionsPayload = (updateBidDesktopPayload, updateBidMobilePayload, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidDesktopPayload?.bidding_level === 'HOTEL_META' && isEmpty(updateBidDesktopPayload?.hotel_id)) || (updateBidDesktopPayload?.bidding_level=== 'GROUP_META' && isEmpty(updateBidDesktopPayload?.group_id));
    errors.marketId = isEmpty(updateBidDesktopPayload?.market_id);
    errors.bidding_type_desktop = isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type);
    errors.bidding_type_mobile = isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_type) && isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type);
    errors.bidding_amount_desktop = !isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_type) &&
        ['MANUAL_BID', 'VOLUME'].includes(updateBidDesktopPayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_value);
    errors.bidding_amount_mobile = !isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        ['MANUAL_BID', 'VOLUME'].includes(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_value);
    errors.bidding_upper_limit_desktop = !isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_type) &&
        ['AUTO_BID'].includes(updateBidDesktopPayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidDesktopPayload?.bidding_strategy?.bidding_upper_limit);
    errors.bidding_upper_limit_mobile = !isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        ['AUTO_BID'].includes(updateBidMobilePayload?.bidding_strategy?.bidding_type) &&
        isEmpty(updateBidMobilePayload?.bidding_strategy?.bidding_upper_limit);

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateBiddingRulesPayload = (updateBidPayload,setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL_META' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP_META' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.bidding_rule_id = isEmpty(updateBidPayload?.bidding_strategy?.bidding_type);
    setErrors(errors);
    return Object.values(errors).some((error) => error);
};

export const validateMultipliersPayload = (updateBidPayload, multiplierPayload, activeButton, setErrors) => {
    const errors = {};
    errors.hotelId = (updateBidPayload?.bidding_level === 'HOTEL_META' && isEmpty(updateBidPayload?.hotel_id)) || (updateBidPayload?.bidding_level=== 'GROUP_META' && isEmpty(updateBidPayload?.group_id));
    errors.marketId = isEmpty(updateBidPayload?.market_id);
    errors.device_identifier = isEmpty(updateBidPayload?.device_identifier);

    const isEmptyMultiplier = multiplierPayload.length === 0;
    const hasMissingValues =
        (activeButton === "CHECKIN_DAY" || activeButton === "DATE_TYPE") &&
        multiplierPayload.filter((p) => p?.bidding_strategy?.multiplier_value).length === 0;

    if (isEmptyMultiplier || hasMissingValues) {
        switch (activeButton) {
            case "DAYS_TO_ARRIVAL":
                errors.days = true;
                errors.bidMultiplier = true;
                break;

            case "LENGTH_OF_STAY":
                errors.numberOfNights = true;
                errors.multiplierValue = true;
                break;

            case "CHECKIN_DAY":
                errors.MONDAY = true;
                errors.TUESDAY = true;
                errors.WEDNESDAY = true;
                errors.THURSDAY = true;
                errors.FRIDAY = true;
                errors.SATURDAY = true;
                errors.SUNDAY = true;
                break;

            case "DATE_TYPE":
                errors.default = true;
                errors.user_selected = true;
                break;

            default:
                break;
        }
    }

    setErrors(errors);
    return Object.values(errors).some((error) => error);
};
