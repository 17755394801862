import React, { useState } from 'react';
import {Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox} from '@mui/material';
import './MoveSelectionModal.scss';

const MoveSelectionModal = ({ open, onClose, onConfirm, groupName, loading, error }) => {
    const [selectedOption, setSelectedOption] = useState(null); // Track the selected option

    // Handle confirmation and pass the selected option
    const handleConfirm = () => {
        onConfirm(selectedOption); // Pass the selected option when confirming
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ textTransform: 'uppercase', fontWeight:'500', fontFamily:'inter', fontSize:'12px' }}>Move Selection</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{fontFamily:'inter', fontSize:'12px' }}>
                    Do you want to save bids and budgets from <strong>{groupName}</strong>?
                </DialogContentText>

                {/* Error message in red, if any */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {/* Use Group Bids/Budgets option */}
                <div className="checkbox-options">
                    <div className="checkbox-group">
                        <label style={{display: "flex", alignItems: "center", fontFamily:'inter', fontSize:'12px' }}>
                            <Checkbox
                                checked={selectedOption === 'useGroupBids'}
                                onChange={() => setSelectedOption('useGroupBids')}
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 16,
                                    },
                                }}
                            />

                            Use Group Bids/Budgets
                        </label>
                        <p className="warning-text">
                            **Warning**: If the Group bids/budgets aren't set, then the hotel's bids/budgets will be removed and the hotel’s campaigns will close.
                        </p>
                    </div>

                    {/* Keep Hotel Bids/Budgets option */}
                    <div className="checkbox-group">
                        <label style={{display: "flex", alignItems: "center", fontFamily:'inter', fontSize:'12px' }}>
                            <Checkbox
                                checked={selectedOption === 'keepHotelBids'}
                                onChange={() => setSelectedOption('keepHotelBids')}
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 16,
                                    },
                                }}
                            />
                            Keep hotel current bids/budgets
                        </label>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                {/* Show loader instead of buttons while loading */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                            className='cancelBtn'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleConfirm}
                            disabled={!selectedOption}
                            className={`${!selectedOption ? 'inActiveCnfBtn' : 'activeCnfBtn'}`}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default MoveSelectionModal;
