import React from "react";
import {Grid, Box, Select} from "@mui/material";
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from "recharts";
import { CHART_OPTIONS } from "../../Utils/ChartOptions";
import MenuItem from "@mui/material/MenuItem";

const CustomTooltip = ({ active, payload, selectedMetric, currencySymbol }) => {
    if (active && payload && payload.length) {
        const shouldShowCurrency = ["cost", "generated", "cpc"].includes(selectedMetric);
        return (
            <div className="custom-tooltip">
                <p>{`${payload[0].payload.name}: ${shouldShowCurrency ? currencySymbol : ""}${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const PieChartDevice = ({ selectedMetric, handleMetricChange, chartData, currencySymbol }) => (
    <Grid item xs={4}>
        <Grid container>
            <Grid item xs={12}>
                <Box className="metric-dropdown-container">
                    <Select
                        id="metricDropdown"
                        value={selectedMetric}
                        onChange={handleMetricChange}
                        variant="outlined"
                        displayEmpty
                        sx={{
                            color: "black",
                            fontSize: "12px",
                            padding: "0",
                            border: "none",
                            borderRadius: "4px",
                            height: "28px",
                            fontWeight: 600,
                            background: "transparent",
                            display: "flex",
                            flexDirection: "row-reverse",
                            "& .MuiSelect-icon": {
                                color: "#BAB7B7",
                                position: "absolute",
                                left: "8px",
                                top: "50%",
                                transform: "translateY(-50%)",
                            },
                            "& .MuiSelect-select": {
                                padding: "0 8px 0 32px",
                                display: "flex",
                                alignItems: "center",
                            },
                            "& fieldset": {
                                border: "none",
                            },
                        }}
                    >
                        {CHART_OPTIONS.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    lineHeight: "1.2",
                                    color: "black"
                                }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="chart-container">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                                data={chartData}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                innerRadius={70}
                                paddingAngle={2}
                                labelLine={false}
                                label={({ cx, cy, midAngle, innerRadius, outerRadius, percentage }) => {
                                    const RADIAN = Math.PI / 180;
                                    const radius = innerRadius + (outerRadius - innerRadius) * -1.5;
                                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                    return (
                                        <text
                                            x={x}
                                            y={y}
                                            fill="#000"
                                            textAnchor="middle"
                                            dominantBaseline="central"
                                            className="text-percentage"
                                        >
                                            {`${percentage}%`}
                                        </text>
                                    );
                                }}
                            >
                                {chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip selectedMetric={selectedMetric} currencySymbol={currencySymbol} />} />
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </Grid>
    </Grid>
);

export default PieChartDevice;