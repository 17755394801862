import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {APP_KAYAK_BIDDING_API_ENDPOINT} from "constants/api_urls";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Snackbar, Chip} from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import HotelsFilterMenu from "components/hotelFilterMenu";
import {ArrowDropDown} from "@mui/icons-material";
import TextInput from "components/TextInput";
import {allFields, kayakButtons, kayakMultiplierFormFields} from "./options";
import "./multipier.scss";
import {useForm} from "react-hook-form";
import closeIcon from "assets/images/close.svg";
import {
    setOpenEditBidding,
    updateEditBidHotels,
} from "store/slices/editBid/editBid";
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";
import {validateMultipliersPayload} from "../formValidator";
import {getUserToken} from "../../../../../utils/functions/userToken";

const KayakMultipliers = () => {
    const token = getUserToken();
    // Ref
    const hotelButtonRef = useRef(null);
    //Selectors
    const {account_id = ""} = useSelector(
        (state) => state?.account?.account || {}
    );
    const {hotels = []} = useSelector((state) => state?.editBid);
    const dispatch = useDispatch();
    const [openHotelMenu, setOpenHotelMenu] = useState(false);
    const [activeButton, setActiveButton] = useState(kayakButtons[0]);
    const [formFields, setFormFields] = useState([]);
    const [activeMultipliers, setActiveMultipliers] = useState([]);
    const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] = useState("hotels");
    const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState(hotels);
    const [updateBidPayload, setUpdateBidPayload] = useState({
        id_account: account_id,
        bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
    });
    const [weekendStayPayload, setWeekendStayPayload] = useState([]);
    const showChips = activeButton.id === "lengthOfStay" || activeButton.id === "daysToArrival";
    // List Data
    const [biddingList, setBiddingList] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const {hotel_list = [], group_list = [], can_use_group} = biddingList || {};
    const COMMONFORMFIELDS = [
        {
            id: "hotel",
            label: "Hotel",
            type: "select",
            placeholder: " Select a hotel ",
            options: hotel_list,
        },
    ];

    const [errors, setErrors] = useState({});

    const handleChangeFieldValue = useCallback((field, value) => {
        setUpdateBidPayload((prev) => ({...prev, [field]: value}));
    }, []);

    useEffect(() => {
        handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id));
    }, [hotels,handleChangeFieldValue,selectedHotelOrGroupType]);

    // Service to render Bidding List based on Account Id
    useEffect(() => {
        if (account_id) {
            axios
                .get(
                    `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/bidding-panel-info/${account_id}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setBiddingList(res?.data || {});
                });
        }
    }, [account_id,token]);

    useEffect(() => {
        const currentSelection = allFields[activeButton.id];
        const fields = kayakMultiplierFormFields
            ?.filter((field) => {
                return (
                    currentSelection?.findIndex(
                        (item) => item?.toLowerCase() === field.id?.toLowerCase()
                    ) !== -1
                );
            })
            .sort(
                (a, b) =>
                    currentSelection?.findIndex((item) => item === a.id) -
                    currentSelection?.findIndex((item) => item === b.id)
            );
        setFormFields(fields);
    }, [activeButton]);

    const sendUpdateRequest = useCallback((payload) => {
        axios
            .put(
                `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/update-bids/`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setToastMessage("Multipliers were saved successfully");
                setToastType("success");
                setShowToast(true);
            })
            .catch((err) => {
                setToastMessage(
                    err?.response?.data[0]?.errorMessage || err?.[0]?.errorMessage || err?.message || "Something went wrong"
                );
                setToastType("error");
                setShowToast(true);
            });
    }, [token]);

    const {control, watch, setValue, reset} = useForm({
        defaultValues: formFields
            ?.map((i) => i.id)
            .reduce((acc, item) => {
                acc[item] = "";
                return acc;
            }, {}),
    });

    const handleSave = useCallback(() => {
        if (activeButton.value === "WEEK_END_STAY") {
            if (!validateMultipliersPayload(updateBidPayload, weekendStayPayload, activeButton.value, setErrors)) {
                weekendStayPayload
                    .filter((p) => p?.bidding_strategy?.multiplier_value)
                    .forEach((payload) => {
                        sendUpdateRequest(payload);
                    });
            }
        } else if (activeButton.value === "LENGTH_OF_STAY") {
            if (!validateMultipliersPayload(updateBidPayload, activeMultipliers, activeButton.value, setErrors)) {
                activeMultipliers.forEach((multiplier) => {
                    const [range, percentage] = multiplier.split(" Nights ");

                    const rangeNights = range?.split("-").map((v) => Number(v));
                    let allRangeNights = [];

                    for (let i = rangeNights?.[0]; i <= rangeNights?.[1]; i++) {
                        allRangeNights.push(i);
                    }

                    if (rangeNights.filter((n) => n).length === 2) {
                        const biddingStrategyPayload = allRangeNights.map((nightVal) => ({
                            bidding_type: activeButton.value,
                            multiplier_identifier: String(nightVal),
                        }));

                        const finalPayload = biddingStrategyPayload.map((pl) => ({
                            ...updateBidPayload,
                            bidding_strategy: pl,
                        }));

                        const mappedBiddingPayload = finalPayload?.map((bid) => ({
                            ...bid,
                            bidding_strategy: {
                                ...bid?.bidding_strategy,
                                multiplier_value: Number(percentage.split("%")[0]),
                            },
                        }));

                        mappedBiddingPayload.forEach((payload) => {
                            sendUpdateRequest(payload);
                        });
                    }
                });
                if (activeMultipliers.length) {
                    setValue("device_identifier", "");
                    setShowToast(true);
                } else {
                    return;
                }
            }
        } else if (activeButton.value === "DAYS_TO_ARRIVAL") {
            if (!validateMultipliersPayload(updateBidPayload, activeMultipliers, activeButton.value, setErrors)) {
                activeMultipliers.forEach((multiplier) => {
                    const [range, percentage] = multiplier.split(" Days ");

                    const payload = {
                        ...updateBidPayload,
                        bidding_strategy: {
                            bidding_type: activeButton.value,
                            multiplier_identifier: range,
                            multiplier_value: Number(percentage.split("%")[0]),
                        },
                    };

                    sendUpdateRequest(payload);
                });
            }
        } else {
            if (!validateMultipliersPayload(updateBidPayload, updateBidPayload, activeButton.value, setErrors)) {
                sendUpdateRequest(updateBidPayload);
            }
        }
    }, [updateBidPayload, activeMultipliers, weekendStayPayload, activeButton,sendUpdateRequest, setValue]);

    const handleAddMultiplier = (id) => {
        const rangeId =
            activeButton.id === "lengthOfStay" ? "numberOfNights" : "days";
        const range = control._formValues[rangeId];
        const percentage = control._formValues[id];

        if (rangeId === "days" && !range?.includes("-") && !percentage) {
            setToastMessage("Incorrect multiplier value");
            setToastType("error");
            setShowToast(true);
            return;
        }

        if (
            !range ||
            range?.[0] === "-" ||
            ((!range?.split("-")[0] || !range?.split("-")[1]) && !percentage)
        ) {
            setToastMessage("Incorrect number of "+rangeId );
            setToastType("error");
            setShowToast(true);
            return;
        }
        if (!percentage) {
            setToastMessage("Incorrect multiplier value");
            setToastType("error");
            setShowToast(true);
            return;
        }
        const from = range?.split("-")[0];
        const to = range?.split("-")[1];
        if (Number(from) > Number(to)) {
            setToastMessage("Incorrect number of nights range for start value");
            setToastType("error");
            setShowToast(true);
            return;
        } else if (Number(from) >= 31 || Number(to) >= 31) {
            setToastMessage(
                Number(from) >= 31
                    ? `Incorrect number of nights range for start value`
                    : "Incorrect number of nights range for end value"
            );
            setToastType("error");
            setShowToast(true);
            return;
        }

        //--Validate Days to arrival
        if (activeButton.value === "DAYS_TO_ARRIVAL") {
            for (let i = 0; i < activeMultipliers.length; i++) {
                const tempRange = activeMultipliers[i]?.split(" ")[0];
                if ( Number(range) <= Number(tempRange) ) {
                    setToastMessage(
                        `Incorrect days or days range value`
                    );
                    setToastType("error");
                    setShowToast(true);
                    return;
                }
            }
        }

        if (activeButton.value === "LENGTH_OF_STAY") {
            for (let i = 0; i < activeMultipliers.length; i++) {
                const tempRange = activeMultipliers[i]?.split(" ")[0];
                const tempFrom = tempRange?.split("-")[0];
                const tempTo = tempRange?.split("-")[1];

                if (
                    (Number(from) >= Number(tempFrom) &&
                        Number(from) <= Number(tempTo)) ||
                    (Number(to) >= Number(tempFrom) && Number(to) <= Number(tempTo))
                ) {
                    setToastMessage(
                        `The range ${from}-${to} nights can't be added , it cross with range ${tempFrom}-${tempTo} nights`
                    );
                    setToastType("error");
                    setShowToast(true);
                    return;
                }
            }
        }

        if (activeButton.value === "LENGTH_OF_STAY") {
            setActiveMultipliers((prev) => [...prev, `${range} Nights ${percentage}%`]);
        }else{
            setActiveMultipliers((prev) => [...prev, `${range} Days ${percentage}%`]);
        }

        setValue("numberOfNights", "");
        setValue("multiplierValue", "");
        setValue("days", "");
        setValue("bidMultiplier", "");
    };

    const handleChange = (id, val) => {
        setValue(id, val);
    };

    return (
        <div style={{position: "relative"}}>
            <div className="mul-title-button-container">
                <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "-18px",
                        marginRight: "-18px",
                    }}
                >
                    {kayakButtons.map((button) => {
                        return (
                            <Button
                                onClick={() => {
                                    setActiveButton(button);
                                    setUpdateBidPayload((prev) => ({
                                        ...prev,
                                        bidding_strategy: {},
                                    }));
                                    reset();
                                    setActiveMultipliers([]);
                                }}
                                sx={{
                                    backgroundColor:
                                        button.id === activeButton.id ? "#B9F2DB" : "inherit",
                                    "&:hover": {
                                        backgroundColor:
                                            activeButton.id === button.id ? "#B9F2DB" : "inherit",
                                    },
                                }}
                                variant="outlined"
                                className="topBtn"
                                disableFocusRipple
                            >
                                {button.label}
                            </Button>
                        );
                    })}
                </div>
            </div>
            <div className="form-button-container">
                <div className="formGrid">
                    <div className="common-field-container">
                        {COMMONFORMFIELDS.map((field) => {
                            if (field.id === "hotel") {
                                return (
                                    <div className="form-grid-item bidding_multiplier_height">
                                        <div className="mul-form-label">Hotels</div>
                                        <div ref={hotelButtonRef}>
                                            <TextInput
                                                placeholder={field.placeholder}
                                                type={"text"}
                                                readOnly
                                                value={
                                                    selectedHotelsorGroups.length > 0
                                                        ? selectedHotelsorGroups.length === 1
                                                            ? selectedHotelsorGroups[0].name
                                                            : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                                                        : ""
                                                }
                                                sx={{
                                                    backgroundColor: "white",
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    paddingLeft: "4px",
                                                }}
                                                endAdornment={<ArrowDropDown/>}
                                                onClick={(e) => {
                                                    setOpenHotelMenu(true);
                                                }}
                                                error={errors.hotelId}
                                            />
                                        </div>
                                        {hotel_list?.length ? (
                                            <HotelsFilterMenu
                                                allHotels={hotel_list?.map((hotel) =>
                                                    selectedHotelsorGroups?.find((h) => h.id === hotel.id) || hotel_list.length === 1
                                                        ? {...hotel, isSelected: true}
                                                        : {...hotel, isSelected: false}
                                                )}
                                                allGroups={group_list?.map((group) =>
                                                    selectedHotelsorGroups?.find((h) => h.id === group.id)
                                                        ? {...group, isSelected: true}
                                                        : {...group, isSelected: false}
                                                )}
                                                showGroups={group_list.length !== 0 && can_use_group}
                                                anchorEl={hotelButtonRef.current}
                                                open={openHotelMenu}
                                                onClose={() => {
                                                    setOpenHotelMenu(false);
                                                }}
                                                onApply={(selected) => {
                                                    dispatch(updateEditBidHotels(selected));
                                                    setSelectedHotelsorGroups(selected);
                                                    handleChangeFieldValue(
                                                        "hotel_id",
                                                        selected.map((s) => s.id)
                                                    );
                                                }}
                                                setSelectedHotelOrGroupType={(type) => {
                                                    setSelectedHotelOrGroupType(type);
                                                    handleChangeFieldValue(
                                                        "bidding_level",
                                                        type === "hotels" ? "HOTEL" : "GROUP"
                                                    );
                                                }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                );
                            }
                            return null;
                        })}
                        <div className="form-grid-item">
                            <div className="form-title">THIS APPLIES TO ALL MARKETS</div>
                        </div>
                    </div>
                    <div
                        className="fields-container bidding_multiplier_height"
                        style={{
                            flex: showChips ? 2.25 : 3,
                            maxWidth: "212px",
                            gridTemplateColumns: `repeat(${
                                showChips
                                    ? Math.ceil(formFields.length / 2)
                                    : Math.ceil(formFields.length / 2) + 1
                            },1fr)`,
                        }}
                    >
                        {formFields?.map((field) => {
                            return (
                                <div className="form-grid-item">
                                    <FormFieldRenderer
                                        item={field}
                                        options={field?.options || []}
                                        hotelMenuOpen={openHotelMenu}
                                        onHotelMenuClose={() => {
                                            setOpenHotelMenu(false);
                                        }}
                                        value={watch(field.id) || ""}
                                        onChange={(val) => {
                                            if (field.id === "device_identifier") {
                                                handleChangeFieldValue("device_identifier", val);
                                                setActiveMultipliers([]);
                                            }

                                            if (activeButton.value === "WEEK_END_STAY") {
                                                if (field.id !== "device_identifier") {
                                                    const initialPayload = {
                                                        ...updateBidPayload,
                                                        bidding_strategy: {
                                                            ...updateBidPayload?.bidding_strategy,
                                                            bidding_type: activeButton.value,
                                                            multiplier_identifier: field.id,
                                                            multiplier_value: Number(val),
                                                        },
                                                    };

                                                    setWeekendStayPayload((prev) => {
                                                        const index = prev.findIndex(
                                                            (pl) =>
                                                                pl?.bidding_strategy?.multiplier_identifier ===
                                                                field.id
                                                        );
                                                        if (index !== -1) {
                                                            prev.splice(index, 1, {
                                                                ...prev[index],
                                                                bidding_strategy: {
                                                                    ...prev[index].bidding_strategy,
                                                                    multiplier_value: Number(val),
                                                                },
                                                            });
                                                            return prev;
                                                        } else {
                                                            return prev.concat(initialPayload);
                                                        }
                                                    });
                                                }
                                            }
                                            // DAYS_TO_ARRIVAL
                                            if (activeButton.value === "DAYS_TO_ARRIVAL") {
                                                if (field.id === "days") {
                                                    handleChangeFieldValue("bidding_strategy", {
                                                        ...updateBidPayload?.bidding_strategy,
                                                        bidding_type: activeButton.value,
                                                        multiplier_identifier: val,
                                                    });
                                                }

                                                if (field.id === "bidMultiplier") {
                                                    handleChangeFieldValue("bidding_strategy", {
                                                        ...updateBidPayload?.bidding_strategy,
                                                        bidding_type: activeButton.value,
                                                        multiplier_value: Number(val),
                                                    });
                                                }
                                            }
                                            handleChange(field.id, val);
                                        }}
                                        handleAddMultiplier={() => handleAddMultiplier(field.id)}
                                        labelKey={"label"}
                                        errors={errors}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    style={{display: !showChips ? "none" : "block"}}
                    className="mul-chips-container"
                >
                    <div className="active-mul-container">
                        <div className="mul-form-label">Active Multipliers</div>
                    </div>
                    <div className="mul-chips">
                        {activeMultipliers.map((item, index) => {
                            return (
                                <Chip
                                    label={item}
                                    variant="filled"
                                    onDelete={() => {
                                        const newMultipliers = [...activeMultipliers];
                                        newMultipliers.splice(index, 1);
                                        setActiveMultipliers(newMultipliers);
                                    }}
                                    deleteIcon={
                                        <img alt='Close' src={closeIcon} style={{height: "14px"}}/>
                                    }
                                    sx={{
                                        margin: "4px 4px 4px 14px",
                                        height: "20px",
                                        fontSize: "10px",
                                        backgroundColor: "#BAB7B7",
                                        "&:hover": {
                                            outline: "1px solid #587A78",
                                        },
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
                {/* {(updateBidPayload?.[0]?.hotel_id?.length > 0 ||
          isSaveButtonVisible) && ( */}
                <div style={{display: 'flex', gap: 10, marginBottom: '-20px'}}>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"85px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </CustomButtonWithIcon>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"85px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={() => {
                            dispatch(setOpenEditBidding(false));
                        }}
                    >
                        Cancel
                    </CustomButtonWithIcon>
                </div>
                {/* )} */}
            </div>
            <Snackbar
                open={showToast}
                autoHideDuration={3000}
                onClose={() => {
                    setShowToast(false);
                }}
            >
                <Alert severity={toastType} variant="filled" sx={{width: "100%"}}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default KayakMultipliers;
