import axios from 'axios';
import {Grid} from "@mui/material";
import ReservationsTable from "features/reservations/ReservationsTable";
import React, {useCallback, useEffect, useState} from 'react';
import {APP_RESERVATIONS_API_ENDPOINT} from "../../constants/api_urls";
import { getUserToken } from "../../utils/functions/userToken";

const Reservations = ({idAccount,selectedFilters, page,resetPage}) => {
    const [data, setData] = useState([]);
    const [accountCurrency, setAccountCurrency] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useState({
        nameSearch: '',
        codeResa: ''
    });

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null); // Reset error state on fetch
        const token = getUserToken();
        if (!token) {
            setError('No authentication token found.');
            setLoading(false);
            return;
        }

        const params = {
            id_account: idAccount,
            code_resa: searchParams.codeResa,
            hotel_name: searchParams.nameSearch,
            id_platform: selectedFilters.idPlatForm,
            booking_month: selectedFilters.bookingMonth,
            checkout_month: selectedFilters.checkOutMonth,
            booking_status: selectedFilters.bookingStatus,
            billing_mode: selectedFilters.billingMode,
        };

        try {
            const { data: responseData } = await axios.get(`${APP_RESERVATIONS_API_ENDPOINT}/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params,
            });
            setData(responseData.reservations);
            setAccountCurrency(responseData.account_currency);
        } catch (err) {
            const message = err.response?.data?.message || 'Failed to fetch reservations';
            setError(message);
        } finally {
            setLoading(false);
        }
    }, [idAccount, selectedFilters, searchParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = ({ nameHotel, codeResa }) => {
        setSearchParams({ nameSearch: nameHotel, codeResa });
    };

    return (
        <>
            <Grid sx={{ maxWidth: "100%"}}>
                <ReservationsTable
                    loading={loading}
                    idAccount={idAccount}
                    accountCurrency={accountCurrency}
                    handleSearch={handleSearch}
                    fetchData={fetchData}
                    rows={data}
                    setData={setData}
                    page={page}
                    resetPage={resetPage}
                    error={error}
                />
            </Grid>
        </>
    );
};

export default Reservations;