export const trivagoBiddingOptionsFormFields = [
  {
    id: "bidStrategy",
    label: "Bid strategy",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No Bid",
        label: "No Bid",
        value: "NO_BID",
      },
      {
        id: "Manual Bid",
        label: "Manual Bid",
        value: "MANUAL",
      },
      { id: "CPA_MIN", label: "CPA MIN", value: "CPA_MIN" },

      { id: "CPA_MIN +1%", label: "CPA MIN +1%", value: "CPA_MIN +1%" },

      { id: "CPA_MIN +2%", label: "CPA MIN +2%", value: "CPA_MIN +2%" },

      { id: "CPA_MIN +3%", label: "CPA MIN +3%", value: "CPA_MIN +3%" },

      { id: "CPA_MIN +4%", label: "CPA MIN +4%", value: "CPA_MIN +4%" },

      { id: "CPA_MIN +5%", label: "CPA MIN +5%", value: "CPA_MIN +5%" },
    ],
  },
  {
    id: "baseBid",
    label: "Bid Amount",
    type: "textField",
    placeholder: "Please enter a value",
    option: [],
  },
];
export const COMMONFORMFIELDS = [
  {
    id: "hotel",
    label: "Hotel",
    type: "multiSelect",
    placeholder: "Select a hotel",
  },

  {
    id: "market",
    label: "Market",
    type: "multiSelect",
    placeholder: "Select a value",
  },
];
