import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import "./EditBudgetForm.scss";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import { MONTHS } from "features/google-search-ads-campaign/constants/months";
import TextInput from "components/TextInput";
import {getUserToken} from "../../../../utils/functions/userToken";

export const initialMonthlyBudget = {
  budget_month_1: 0,
  budget_month_2: 0,
  budget_month_3: 0,
  budget_month_4: 0,
  budget_month_5: 0,
  budget_month_6: 0,
  budget_month_7: 0,
  budget_month_8: 0,
  budget_month_9: 0,
  budget_month_10: 0,
  budget_month_11: 0,
  budget_month_12: 0,
};

const EditBudgetForm = ({ row, handleFieldChange, onCampaignLoaded }) => {
  const token = getUserToken();
  const [selectedRow, setSelectedRow] = useState(row);
  const [budgetName, setBudgetName] = useState("");
  const [dailyBudgetCap, setDailyBudgetCap] = useState(false);
  const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
  const [targetValue, setTargetValue] = useState("");
  const [currency, setCurrency] = useState("");
  console.log("Row : ", row)

  useEffect(() => {
    axios
        .get(
            `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/details/?shared_budget_id=${row?.shared_budget_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        )
        .then((res) => {
            console.log('response : ' + JSON.stringify(res))
          setSelectedRow(res?.data);
          setTargetValue(res?.data?.budget?.default_budget);
          setBudgetName(res?.data?.shared_budget_name);
          setDailyBudgetCap(res?.data?.budget?.is_daily_budget);
            setCurrency(res?.data?.budget?.currency_symbol);
          console.log("Currency :", currency)
          setMonthlyBudget(
              Object.keys(res?.data?.budget)
                  .filter((key) => key.includes("month"))
                  .reduce((acc, curr) => {
                    acc[curr] = res?.data?.budget?.[curr];
                    return acc;
                  }, {})
          );
          onCampaignLoaded({
            campaign_list: res?.data?.campaign_list,
            campaign_movable: res?.data?.campaign_movable,
          });
        })
        .catch((err) => {
          console.error(err?.message);
        });
  }, []);

  return (
      <Box display="flex" flexDirection="column" gap="12px">
        <Box display="flex" width={"100%"} gap={"20px"}>
          <Box display="flex" flexDirection="column" gap={"8px"}>
              <Typography variant="caption" sx={{ fontSize: "12px" }}>{"HOTEL NAME : " + row?.hotel_name || ""}</Typography>
            <CustomOutlinedInput
                placeholder={"Enter Shared Budget Name"}
                sx={{
                  minWidth: "300px",
                  "& .MuiInputBase-input": {
                    fontSize: "12px",
                  },
                }}
                inputProps={{
                  sx: {
                    minHeight: "28px",
                    height: "28px",
                    fontSize: "12px",
                    autocomplete:'off',
                  },
                }}
                value={budgetName}
                helperText={"Shared Budget Name"}
                onChange={(e) => {
                  setBudgetName(e.target.value);
                  handleFieldChange("shared_budget_name", e.target.value);
                }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Box display="flex" flexDirection="column" gap={"8px"}>
            <Typography variant="caption" sx={{ fontSize: "12px" }}>{"MONTHLY BUDGET CAP (" + currency + ")"}</Typography>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                  paddingRight: "15px",
                }}
            >
              <CustomOutlinedInput
                  placeholder="Enter Monthly Budget Cap"
                  value={targetValue}
                  onChange={(e) => {
                    setTargetValue(e.target.value);
                    handleFieldChange("default_budget", Number(e.target.value));
                  }}
                  className="no-border-focus"
                  type="number"
                  fullWidth
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*", sx: { fontSize: "12px",autocomplete:'off',paddingRight:'2px' } }}
                  sx={{
                    maxWidth: "140px",
                  }}
              />

              <FormControlLabel
                  sx={{
                    marginLeft: "1px",
                    "& .MuiTypography-root": {
                      fontSize: "12px", // Adjust label font size for checkbox
                    },
                  }}
                  control={
                    <Checkbox
                        //size="small"
                        sx={{
                          fontSize:"12px",
                          color: "#BAB7B7",
                          "&.Mui-checked": {
                            color: "#61D5A9",
                          },
                          "& .MuiSvgIcon-root": {
                            height: "18px",
                            width: "18px",
                          },
                        }}
                        onChange={(e) => {
                          setDailyBudgetCap(e.target.checked);
                          handleFieldChange("is_daily_budget", e.target.checked);
                        }}
                        checked={dailyBudgetCap}
                        value={"DEVICE"}
                    />
                  }
                  label="Daily Budget Cap"
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={"8px"}>
            <Typography variant="caption" sx={{ fontSize: "12px" }}>{"MONTHLY VARIABLES (" + currency + ")"}</Typography>
            <Box
                display="grid"
                gridTemplateColumns={`repeat(${4}, 1fr)`}
                columnGap={"12px"}
                rowGap={"4px"}
            >
              {MONTHS.map((month, idx) => (
                  <Box
                      key={month}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"center"}
                      sx={{
                        gap: 1,
                        justifyContent: "flex-end"
                      }}
                      className="monthField"
                  >
                    <Typography
                        variant="body2"
                        sx={{
                          textAlign: "right",
                          color: "#373f41",
                          fontSize: "12px", // Adjust label font size for month
                          fontFamily: "Inter",
                          fontWeight: 400,
                        }}
                        className="monthLabel"
                    >
                      {month}
                    </Typography>
                    <TextInput
                        minWidth={"60px"}
                        numberUnit={""}
                        sx={{
                          width: "60px",
                          fontSize: "12px",
                          paddingRight:"2px",
                        }}
                        value={monthlyBudget?.[`budget_month_${idx + 1}`] || ""}
                        className="no-border-focus monthInput"
                        type="number"
                        fullWidth
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          sx: { fontSize: "12px" },
                          autoComplete: "off",
                        }}
                        onChange={(e) => {
                          setMonthlyBudget((prev) => ({
                            ...prev,
                            [`budget_month_${idx + 1}`]: e.target.value,
                          }));
                          handleFieldChange(
                              `budget_month_${idx + 1}`,
                              Number(e.target.value)
                          );
                        }}
                    />
                  </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
  );
};

export default EditBudgetForm;