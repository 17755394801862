import React from "react";
import { Box } from "@mui/material";
import CampaignSettingsDetails from "./CampaignSettingsDetails";
import MonthlyBudgetCap from "./MonthlyBudgetCap";
import LanguageSelection from "./LanguageSelection";
import TargetLocations from "./TargetLocations";
import "./CampaignSettingsAccordion.scss";

const CampaignSettingsAccordion = ({
     biddingStrategy,
     setBiddingStrategy,
     campaignName,
     setCampaignName,
     targetCpa,
     setTargetCpa,
     targetRoas,
     setTargetRoas,
     maxCpcLimit,
     setMaxCpcLimit,
     impressionShare,
     setImpressionShare,
     adLocation,
     setAdLocation,
     selectedLanguage,
     setSelectedLanguage,
     selectedCountries,
     setSelectedCountries,
     targetValue,
     setTargetValue,
     monthlyBudget,
     setMonthlyBudget,
     errors,
     dailyBudgetCap,
     setDailyBudgetCap,
     isEdit = false,
     currencySymbol,
     campaignId = null
}) => {
    return (
        <Box className="google-search-ads-form-campaign-settings">
            <CampaignSettingsDetails
                biddingStrategy={biddingStrategy}
                setBiddingStrategy={setBiddingStrategy}
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                targetCpa={targetCpa}
                setTargetCpa={setTargetCpa}
                targetRoas={targetRoas}
                setTargetRoas={setTargetRoas}
                maxCpcLimit={maxCpcLimit}
                setMaxCpcLimit={setMaxCpcLimit}
                impressionShare={impressionShare}
                setImpressionShare={setImpressionShare}
                adLocation={adLocation}
                setAdLocation={setAdLocation}
                errors={errors}
                isEdit={isEdit}
                currencySymbol={currencySymbol}
                campaignId={campaignId}
            />

            <MonthlyBudgetCap
                monthlyBudget={monthlyBudget}
                setMonthlyBudget={setMonthlyBudget}
                targetValue={targetValue}
                setTargetValue={setTargetValue}
                errors={errors}
                dailyBudgetCap={dailyBudgetCap}
                setDailyBudgetCap={setDailyBudgetCap}
                isEdit={isEdit}
                currencySymbol={currencySymbol}
            />
            <LanguageSelection selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <TargetLocations selectedCountries={selectedCountries} setSelectedCountries={setSelectedCountries} />
        </Box>
    );
};

export default CampaignSettingsAccordion;
