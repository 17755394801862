import SingleSelect from "components/SingleSelect";
import "./FormFieldRenderer.scss";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import TextInput from "components/TextInput";
import { IconButton } from "@mui/material";
import DateInput from "components/DateInput";
import { useEffect, useState } from "react";
function DaysRange({ item, value, onChange }) {
  const [start, setStart] = useState(value?.split("-")[0]);
  const [end, setEnd] = useState(value?.split("-")[1]);
  useEffect(() => {
   
    setStart(value?.split("-")[0] ?? "");
    setEnd(value?.split("-")[1] ?? "");
  }, [value]);
  return (
    <div className="mul-item">
      <div className="mul-form-label">{item.label}</div>
      <div>
        <div style={{ flex: 2 }}>
          <div className="rangeContainer">
            <TextInput
              autoComplete="off"
              value={start ?? ""}
              type={"number"}
              numberUnit=""
              onChange={(val) => {
                setStart(val.target.value);
                val.target.focus();
                onChange(`${val.target.value}-${end}`);
              }}
              sx={{
                  fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px'
              }}
            />{" "}
            <span className="mul-form-label">to</span>
            <TextInput
              autoComplete="off"
              value={end ?? ""}
              type="number"
              numberUnit=""
              onChange={(val) => {
                setEnd(val.target.value);
                val.target.focus();

                onChange(`${start}-${val.target.value}`);
              }}
              sx={{
                  fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const FormFieldRenderer = ({
  item,
  value,
  onChange,
  handleAddMultiplier,
  options,
  valueKey,
  labelKey,
  disabled,
  errors = false
}) => {
  if (item.type === "select") {
    return (
      <div className="mul-item">
        <div className="mul-form-label">{item.label || item.name}</div>
        <SingleSelect
          placeholder={item?.placeholder}
          sx={{
            backgroundColor: "white", fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px',width:"212px",
          }}
          options={options}
          value={value}
          onChange={onChange}
          valueKey={valueKey || "value"}
          labelKey={labelKey || "label"}
          menuProps={{
              transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
              },
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              },
          }}
          error={errors?.[item.id] || false}
        />
      </div>
    );
  } else if (item.type === "multiSelect") {
    return null;
  } else if (item.type === "range") {
    return <DaysRange item={item} value={value} onChange={onChange} />;
  } else if (item.type === "textField") {
    if (item?.isAdder) {
      return (
        <div className="mul-item">
          <div className="mul-form-label">{item.label}</div>
          <div className="multiplier-submit">
            <div style={{ flex: 2, marginRight: "11px" }}>
              <TextInput
                type={item?.subType ?? "number"}
                placeholder={item.placeholder}
                sx={{
                  width: "212px",
                  backgroundColor: "white",fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px',
                }}
                disabled={disabled}
                autoComplete="off"
                value={value}
                onChange={(val) => {
                  onChange(val.target.value);
                }}
                error={errors?.[item.id] || false}
              />
            </div>

            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                handleAddMultiplier();
              }}
            >
              <ControlPointOutlinedIcon  sx={{ stroke: '#F1F1F1',fontSize:"21px"}} />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <div className="mul-item">
        <div className="mul-form-label">{item.label}</div>
        <TextInput
          placeholder={item.placeholder}
          type={item?.subType ?? "number"}
          sx={{
            backgroundColor: "white",fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px',width:"212px",
          }}
          value={value}
          disabled={disabled}
          autoComplete="off"
          onChange={(val) => {
            onChange(val.target.value);
          }}
          numberUnit={item?.numberUnit}
          error={errors?.[item.id] || false}
        />
      </div>
    );
  } else if (item.type === "date") {
    return (
      <div className="mul-item">
        <div className="mul-form-label">{item.label}</div>
        <DateInput
          value={value}
          onChange={(val) => {
            onChange(val.target.value);
          }}
        />
      </div>
    );
  }
};
export default FormFieldRenderer;
