import React from 'react';
import AlertBaseForm from './MetricsMetasearch';
import {useParams} from "react-router-dom";
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
import { getUserToken } from "utils/functions/userToken";

const AlertMetasearchTrivagoSlForm = ({alertData, actionType, onClose, showSnackbar, refreshData}) => {

    const { idaccount } = useParams();
    const availableSegments = ["MARKET"];
    let method = 'POST';
    let apiActionEndpoint = '';
    const apiEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/trivago-sl/metrics-list`;
    if(actionType === 'edit') {
        method = 'PUT';
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/trivago-sl/edit/${idaccount}/` + alertData.id;
    }
    else {
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/trivago-sl/add/${idaccount}`;
    }
    const jwtToken = getUserToken();

    const submitTrivagoSlAlert = (requestBody) => {
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                showSnackbar('Alert saved successfully');
                if (refreshData) {
                    refreshData();
                }
                onClose();
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                showSnackbar('Error saving alert' + error.message, 'error');
            });
    };

    return (
        <div>
            <AlertBaseForm
                apiEndpoint={apiEndpoint}
                alertData={alertData}
                jwtToken={jwtToken}
                submitCallback={submitTrivagoSlAlert}
                availableSegments={availableSegments}
                onClose={onClose}
            />
        </div>
    );
};

export default AlertMetasearchTrivagoSlForm;
