import React, {useCallback, useEffect, useRef, useState} from 'react';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import TextField from '@mui/material/TextField';
import Tags from "@yaireo/tagify/dist/react.tagify";
import '@yaireo/tagify/dist/tagify.css'
import '../AlertForm.scss';
import HotelListTable from "../../HotelsListTable";
import SlidingPanel from "../SlidingPanel";
import {useParams} from "react-router-dom";
import Icons from "components/DmpIcons/icons";
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";
import SelectedHotels from "../../Tools/SelectedHotels";
import {InputAdornment, Typography} from "@mui/material";
import { getUserToken } from "utils/functions/userToken";

const AlertBaseForm = ({alertData, actionType, onClose, showSnackbar, refreshData }) => {

    const { idaccount } = useParams();
    let method = 'POST';
    let apiActionEndpoint = '';
    if(actionType === 'edit') {
        method = 'PUT';
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/edit/${idaccount}/` + alertData.id;
    }
    else {
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/add/${idaccount}`;
    }
    const jwtToken = getUserToken();

    const [alertName, setAlertName] = useState(alertData?.name || '');
    const [alertRecipients, setAlertalertRecipients] = useState(alertData?.recipients || []);
    const [alertCondition, setAlertCondition] = useState(alertData?.alertCondition || '');
    const [alertSubject, setAlertSubject] = useState(alertData?.alertSubject || '');
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const tagifyRef = useRef(null);

    const [errors, setErrors] = useState({
        alertName: false,
        alertRecipients: false,
        alertCondition: false,
        alertSubject: false,
    });

    const handleHotelSelect = useCallback((selectedHotels) => {
        setSelectedHotels(selectedHotels);
    }, []);

    useEffect(() => {
        if (alertData) {
            setAlertName(alertData.name || '');
            setAlertalertRecipients(alertData.recipients || []);
            setAlertCondition(alertData.alertCondition || '');
            setAlertSubject(alertData.alertSubject || '');
        }
    }, [alertData]);


    const submitBudgetGoogleSearchAds = (requestBody) => {
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                showSnackbar('Alert saved successfully', 'success');
                if (refreshData) {
                    refreshData();
                }
                onClose();
            })
            .catch((error) => {
                showSnackbar('Error saving alert' + error.message, 'error');
                const newErrors = {
                    alertName: !requestBody.alert_name,
                    alertRecipients: requestBody.alert_recipients.length === 0,
                    alertCondition: !requestBody.alert_condition,
                    alertSubject: !requestBody.alert_subject,
                };
                setErrors(newErrors);
            });
    };

    const getLinkedHotels = (hotelId) => {
        fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/list-hotels-by-alert/${hotelId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) { // Vérifie si le statut de réponse n'est pas OK
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSelectedHotels(result);
                const newRowSelection = {};
                result.forEach(hotel => {
                    newRowSelection[hotel.id] = true;
                });
                setRowSelection(newRowSelection);

            })
            .catch((error) => {
                console.error('Error fetching linked hotels:', error);
            });
    };

    useEffect(() => {
        if (alertData && actionType === 'edit') {
            getLinkedHotels(alertData.id);
        }
    });

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value); // Parser la chaîne JSON
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value);
        setAlertalertRecipients(updatedRecipients);
    }, []);

    const handleRemoveHotel = (hotelId) => {
        setSelectedHotels(prevHotels => prevHotels.filter(h => h.id !== hotelId));
        setRowSelection(prevSelection => {
            const newSelection = { ...prevSelection };
            delete newSelection[hotelId];
            return newSelection;
        });
    };

    useEffect(() => {
    }, [selectedHotels]);

    const handleSubmit = async () => {
        const body = {
            alert_name: alertName,
            alert_recipients: alertRecipients,
            alert_condition: alertCondition,
            hotel_id: selectedHotels.map(hotel => hotel.id),
            group_id: [],
            alert_level: "HOTEL",
            alert_subject: alertSubject
        };
        submitBudgetGoogleSearchAds(body);
    };

    return (
        <div className="alert-form">
                <div className="top-side">
                    <TextField
                        placeholder="Alert Name:"
                        variant="standard"
                        value={alertName}
                        onChange={(e) => setAlertName(e.target.value)}
                        required
                        error={errors.alertName}
                        helperText={errors.alertName ? "This field is required" : ""}
                        sx={{
                            '& ::placeholder': { fontSize: '12px' },
                            width: '55%',
                        }}
                    />
                </div>
            <div className="selected-hotels-view">
                <div className="button-container">
                    <CustomButtonWithIcon
                        color="#f9f9f9"
                        hoverColor="#f9f9f9"
                        titleColor="#BAB7b7"
                        hoverTitleColor={"#BAB7b7"}
                        icon={Icons.ActionsIcons.AddIcon}
                        iconSize={"14px"}
                        width={"120px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={() => setIsSlidingPanelOpen(true)}>
                        <span style={{ fontSize: "10px", fontFamily: "Inter, sans-serif", fontWeight: 400 }} >Add Hotel</span>
                    </CustomButtonWithIcon>
                </div>
                     <SelectedHotels selectedHotels={selectedHotels} handleRemoveHotel={handleRemoveHotel} />
                <SlidingPanel size={40} isOpen={isSlidingPanelOpen} onClose={() => setIsSlidingPanelOpen(false)}>
                    <HotelListTable
                        idaccount={idaccount}
                        jwtToken={jwtToken}
                        onHotelSelect={handleHotelSelect}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        onClose={() => setIsSlidingPanelOpen(false)}
                    />
                </SlidingPanel>
            </div>

            <TextField
                placeholder="Alert me when my budget has reached"
                variant="standard"
                type="number"
                value={alertCondition}
                onChange={(e) => setAlertCondition(e.target.value)}
                required
                error={errors.alertCondition}
                helperText={errors.alertCondition ? "This field is required" : ""}
                sx={{
                    '& ::placeholder': { fontSize: '12px' },
                    width: '55%',
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#373F41" }}>
                                %
                            </Typography>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                placeholder="Subject"
                variant="standard"
                value={alertSubject}
                onChange={(e) => setAlertSubject(e.target.value)}
                required
                error={errors.alertSubject}
                helperText={errors.alertSubject ? "This field is required" : ""}
                sx={{
                    '& ::placeholder': { fontSize: '12px' },
                    width: '55%',
                }}
            />

            <div className="bottom-side">
                <label>RECIPIENTS</label>
                <Tags
                    ref={tagifyRef}
                    whitelist={alertRecipients}
                    placeholder='Recipients:'
                    settings={{
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    }}
                    onChange={handleRecipientsChange}
                    defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                    className={errors.alertRecipients ? 'error-tagify' : ''}
                />
                {errors.alertRecipients && <span style={{ color: '#d32f2f', fontSize: '12px' }}>This field is required</span>}
            </div>
            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Alert
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>

        </div>
    );
};

export default AlertBaseForm;
