import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../constants/api_urls";
import {removeCropParameters} from "../../googlePmaxFormComponent/Utils/imageTools/imageCropper";
import {extractErrorMessages} from "../../../../pages/pMaxCampaignCreator/utils/createCampaignPMax";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Create Ads Creative Payload
 * @param {object} params - Ads creative data
 * @returns {object} payload - Formatted payload for the API request
 */
export const createAdsCreativePayload = ({
    campaignId,
    finalURL,
    businessName,
    headlines = [],
    descriptions = [],
    longHeadlines = [],
    marketingImages = [],
    squareMarketingImages = [],
    portraitMarketingImages = [],
    logos = [],
    landscapeLogos = [],
}) => ({
    campaign_id: campaignId,
    campaign_ads_creative: {
        final_url: {
            url: finalURL,
        },
        business_name: {
            assetContent: businessName,
        },
        headline: headlines.map((headline) => ({
            assetContent: headline.text,
        })),
        description: descriptions.map((description) => ({
            assetContent: description.text,
        })),
        long_headline: longHeadlines.map((longHeadline) => ({
            assetContent: longHeadline.text,
        })),
        marketing_image: marketingImages.map((image) => ({
            assetContent: removeCropParameters(image),
        })),
        square_marketing_image: squareMarketingImages.map((image) => ({
            assetContent: removeCropParameters(image),
        })),
        portrait_marketing_image: portraitMarketingImages.map((image) => ({
            assetContent: removeCropParameters(image),
        })),
        logo_image: logos.map((logo) => ({
            assetContent: removeCropParameters(logo),
        })),
        landscape_logo_image: landscapeLogos.map((logo) => ({
            assetContent: removeCropParameters(logo),
        })),
    },
});

/**
 * Save Ads Creative API Call
 * @param {object} payload - API request payload
 * @returns {Promise<object>} - API response
 */
export const saveAdsCreative = async (payload) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.put(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/edit-campaign/ads-creative/`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        const errors = extractErrorMessages(error?.response?.data || {});
        const customError = new Error("Failed to save ads creative");
        customError.success = false;
        customError.errors = errors;
        throw customError;
    }
};
