import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel, InputAdornment,
} from "@mui/material";
import { MONTHS } from "../../../constants/months";
import { validateBudget } from "../../Utils/validators";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const MonthlyBudgetCap = ({
  monthlyBudget,
  setMonthlyBudget,
  targetValue,
  setTargetValue,
  errors,
  dailyBudgetCap,
  setDailyBudgetCap,
  isEdit = false,
  currencySymbol
}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [budgetStatus, setBudgetStatus] = useState(null);
    const handleMonthlyBudgetChange = (month, value) => {
        if (/^\d*$/.test(value)) {
            setMonthlyBudget((prev) => ({
                ...prev,
                [month]: value,
            }));
        }
    };

    const currency = currencySymbol;

    const handleBudgetCapBlur = async () => {
        const payload = {
            default_budget: Number(targetValue),
            is_daily_budget: dailyBudgetCap,
            ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
                acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
                return acc;
            }, {}),
        };
        const result = await validateBudget(payload);
        setBudgetStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.errors['default_budget'] || "Invalid budget cap value" }
        );
    };

    const handleMonthlyBudgetBlur = async (month) => {
        const payload = {
            default_budget: Number(targetValue),
            is_daily_budget: dailyBudgetCap,
            ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
                acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
                return acc;
            }, {}),
        };
        const result = await validateBudget(payload);

        if (result.success) {
            setValidationErrors((prev) => ({ ...prev, [month]: null }));
        } else {
            setValidationErrors((prev) => ({
                ...prev,
                [month]: result.errors[`budget_month_${MONTHS.indexOf(month) + 1}`],
            }));
        }
    };

    const handleTargetValueChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setTargetValue(value);
        }
    };

    const toggleDailyBudgetCap = () => {
        setDailyBudgetCap((prev) => !prev);
    };

    const monthsPerRow = isEdit ? 3 : 4;

    return (
        <Box className="monthlyBudgetCap">
            <Box display="flex" alignItems="flex-start" flexDirection="row">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{ paddingRight: "15px" }}
                >
                    <Typography className="sectionTitle"> Monthly Budget Cap ({currency})</Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ paddingLeft: "30px" }}>
                    <Typography className="sectionTitleVariable"> Monthly Variables ({currency})</Typography>
                </Box>
            </Box>

            <Box display="flex" alignItems="flex-start" p={2}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{
                        paddingRight: "15px",
                    }}
                >
                    <CustomOutlinedInput
                        inputPaddingLeft={"0px"}
                        placeholder="Enter Monthly Budget Cap"
                        value={targetValue}
                        onChange={handleTargetValueChange}
                        className="no-border-focus"
                        type="number"
                        fullWidth
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        minWidth="221px"
                        helperText={
                            <Typography
                                variant="caption"
                                sx={{
                                    whiteSpace: "nowrap",
                                    color: budgetStatus?.error ? 'rgb(211, 47, 47)' :  "#373F41",
                                }}
                            >
                                {
                                    budgetStatus?.error ? budgetStatus.error : ``
                                }
                            </Typography>
                        }
                        error={errors.targetValueError || (budgetStatus && budgetStatus.success === false)}
                        success={budgetStatus && budgetStatus.success === true}
                        onBlur={handleBudgetCapBlur}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography variant="body2" sx={{ fontSize: "12px", color: "#373F41" }}>
                                        {currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <FormControlLabel
                        sx={{
                            marginLeft: "1px",
                        }}
                        control={
                            <Checkbox
                                size="small"
                                sx={{
                                    color: "#BAB7B7",
                                    "&.Mui-checked": {
                                        color: "#61D5A9",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        height: "14px",
                                        width: "14px",
                                    },
                                }}
                                onChange={toggleDailyBudgetCap}
                                checked={dailyBudgetCap}
                                value={"DEVICE"}
                            />
                        }
                        label="Daily Budget Cap"
                    />
                </Box>

                <Box
                    display="grid"
                    gridTemplateColumns={`repeat(${monthsPerRow}, 1fr)`}
                >
                    {MONTHS.map((month) => (
                        <Box
                            key={month}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            sx={{ paddingRight: "16px" }}
                            className="monthField"
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    display: "flex",
                                    textAlign: "right",
                                    color: "#373F41",
                                    fontSize: "10px",
                                    paddingBottom: "35px",
                                    paddingInline: "5px",
                                    width: '20px'
                                }}
                                className="monthLabel"
                            >
                                {month}
                            </Typography>
                            <CustomOutlinedInput
                                inputPaddingLeft={"5px"}
                                minWidth={isEdit ? "150px" : "200px"}
                                inputPadding={"0px"}
                                value={monthlyBudget?.[month] || ""}
                                onChange={(e) => handleMonthlyBudgetChange(month, e.target.value)}
                                className="no-border-focus monthInput"
                                type="number"
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                onBlur={() => handleMonthlyBudgetBlur(month)}
                                error={!!validationErrors[month]}
                                helperText={validationErrors[month] || ""}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default MonthlyBudgetCap;
