import React, {useState} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import GOOGLE_SEARCH_ADS_LOCATION_CRITERION
  from "../../../constants/search-ads-criterion/googleSearchAdsLocationCriterion";
import {validatePos} from "../../Utils/validators";

const TargetLocations = ({ selectedCountries, setSelectedCountries }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedContinents, setSelectedContinents] = useState([]);

  const handleCountryChange = (locationName) => {
    setSelectedCountries((prev) =>
      prev.includes(locationName)
        ? prev.filter((id) => id !== locationName)
        : [...prev, locationName]
    );
  };

  const handleContinentChange = (continent, countries) => {
    const countryIds = countries.map(
      (country) => country.location_criterion_id
    );
    const allSelected = countryIds.every((id) =>
      selectedCountries.includes(id)
    );

    if (allSelected) {
      setSelectedCountries((prev) =>
        prev.filter((id) => !countryIds.includes(id))
      );
      setSelectedContinents((prev) => prev.filter((c) => c !== continent));
    } else {
      setSelectedCountries((prev) => [
        ...prev,
        ...countryIds.filter((id) => !prev.includes(id)),
      ]);
      setSelectedContinents((prev) => [...prev, continent]);
    }
  };

  const handleSelectAll = () => {
    const allCountries = Object.values(GOOGLE_SEARCH_ADS_LOCATION_CRITERION)
      .flat()
      .map((country) => country.location_criterion_id);
    const allContinents = Object.keys(GOOGLE_SEARCH_ADS_LOCATION_CRITERION);

    if (selectedCountries.length === allCountries.length) {
      setSelectedCountries([]);
      setSelectedContinents([]);
      setIsSelectAll(false);
    } else {
      setSelectedCountries(allCountries);
      setSelectedContinents(allContinents);
      setIsSelectAll(true);
    }
  };

  const handleDeselectAll = () => {
    setSelectedCountries([]);
    setSelectedContinents([]);
    setIsSelectAll(false);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    setIsExpanded(!!value);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setIsExpanded(false);
  };

  const toggleExpandAllContinents = () => {
    setIsExpanded(!isExpanded);
  };

  const [_posStatus, setPosStatus] = useState(null);

  const handlePOSBlur = async () => {
    const result = await validatePos(selectedCountries);
    setPosStatus(
      result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid Point of sales" }
    );
  };

  return (
    <Box className="targetLocations" sx={{ minHeight: '240px;' }}>
      <Box
        display="flex"
        alignItems="center"
        className="sectionTitle selectActions"
        justifyContent="space-between"
      >
        <Typography>Select Your Points of Sale</Typography>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <SearchIcon
              fontSize="small"
              style={{ color: "gray", marginRight: "8px" }}
            />
            <TextField
              variant="standard"
              placeholder="Search Country"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={clearSearch}>
                      <ClearIcon fontSize="small" style={{ color: "gray" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="countrySearchField"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{
                  paddingBlock: "2px",
                  color: "#BAB7B7",
                  "&.Mui-checked": {
                    color: "#61D5A9",
                  },
                  "& .MuiSvgIcon-root": {
                    height: "14px",
                    width: "14px",
                  },
                }}
                checked={isSelectAll}
                onChange={handleSelectAll}
              />
            }
            label="Select All"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{
                  paddingBlock: "2px",
                  color: "#BAB7B7",
                  "&.Mui-checked": {
                    color: "#61D5A9",
                  },
                  "& .MuiSvgIcon-root": {
                    height: "14px",
                    width: "14px",
                  },
                }}
                checked={
                  selectedCountries.length === 0 &&
                  selectedContinents.length === 0
                }
                onChange={handleDeselectAll}
              />
            }
            label="Deselect All"
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" paddingLeft={3}>
        <Grid container spacing={2} wrap="nowrap">
          {Object.entries(GOOGLE_SEARCH_ADS_LOCATION_CRITERION).map(
            ([continent, countries]) => (
              <Grid item xs={6} sm={6} md={6} key={continent}>
                <Box className="countryGroup">
                  <FormControlLabel
                    className="continentLabel"
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          paddingBlock: "2px",
                          color: "#BAB7B7",
                          "&.Mui-checked": {
                            color: "#61D5A9",
                          },
                          "& .MuiSvgIcon-root": {
                            height: "14px",
                            width: "14px",
                          },
                        }}
                        checked={selectedContinents.includes(continent)}
                        onChange={() =>
                          handleContinentChange(continent, countries)
                        }
                      />
                    }
                    onBlur={() => handlePOSBlur()}
                    label={continent}
                  />
                  <FormGroup>
                    {(isExpanded ? countries : countries.slice(0, 5))
                      .filter((country) =>
                        country.location_canonical_name
                          .toLowerCase()
                          .includes(searchQuery)
                      )
                      .map(
                        ({
                          location_criterion_id,
                          location_canonical_name,
                        }) => (
                          <FormControlLabel
                            key={location_criterion_id}
                            onBlur={() => handlePOSBlur()}
                            control={
                              <Checkbox
                                size="small"
                                sx={{
                                  paddingBlock: "2px",
                                  color: "#BAB7B7",
                                  "&.Mui-checked": {
                                    color: "#61D5A9",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    height: "14px",
                                    width: "14px",
                                  },
                                }}
                                checked={selectedCountries.includes(
                                  location_criterion_id
                                )}
                                onChange={() =>
                                  handleCountryChange(location_criterion_id)
                                }
                              />
                            }
                            label={location_canonical_name}
                          />
                        )
                      )}
                  </FormGroup>
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </Box>

      {!searchQuery && (
        <Box display="flex" justifyContent="left" paddingBlock={1}>
          <Button
            onClick={toggleExpandAllContinents}
            className="loadMoreButton"
            startIcon={
              isExpanded ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )
            }
          >
            {isExpanded ? "Show Less" : "Load More"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TargetLocations;
