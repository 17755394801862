import React, {useState} from "react";
import {MaterialReactTable} from "material-react-table";
import CustomPagination from "components/CustomPagination";
import "./CampaignTableWithPagination.scss";

const CampaignTableWithPagination = ({ columns = [], filteredRows = [] }) => {
  const rowsPerPage = useState(5);
  const [page, setPage] = useState(1);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Calculate paginated data
  const rowsCount = filteredRows.length;
  const pageCount = Math.ceil(rowsCount / rowsPerPage);
  const paginatedRows = filteredRows.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <div className="custom-hotels-table-container">
      <MaterialReactTable
        columns={[...columns]}
        data={paginatedRows}
        enablePagination={false}
        enableSorting={true}
        enableColumnVisibility={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        muiTableContainerProps={{
          sx: { boxShadow: "none", border: "none", width: "95%" },
        }}
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            textAlign: "left",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "normal",
            verticalAlign: "middle",
            padding: "0 16px",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            width: "150px",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "40px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "45px",
            padding: "0 16px",
          },
        }}
      />
      {/* Pagination Component */}
      <CustomPagination
        pageCount={pageCount}
        page={page}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default CampaignTableWithPagination;
