import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: null,
  level: null,
  hotel_id: [],
  group_id: [],
};
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: initialState,
  reducers: {
    updateAccount: (state, action) => {
      state.account = action.payload;
    },
    updateLevel: (state, action) => {
      state.level = action.payload;
    },
    updateHotelId: (state, action) => {
      state.hotel_id = action.payload;
    },
    updateGroupId: (state, action) => {
      state.group_id = action.payload;
    },
    resetAccount: (state) => initialState
  },
});

export const { updateAccount, updateGroupId,updateHotelId, updateLevel, resetAccount } = accountSlice.actions;
export default accountSlice.reducer;
