import {Grid} from "@mui/material";
import React, {useMemo} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {FilledCircleIcon} from "../../assets/images/Icons";
import {formatCash} from "../../utils/functions/common";

const colorMapper = {
    "#12794F": "#61D5A9",
    "#EA9196": "#FEBDB6",
    "#46508D": "#CACDFE",
    "#4470C1": "#84B8FD",
};

const symbolMapper = {
    cost: "€",
    spent: "€",
    generated: "€",
    cpc: "€",
    clicks: "",
    revenue_perclick: "€",
    bookingcount: "",
    conversion: "%",
    roi: "NET",
};

const BiAxialLineChart = ({ data, colors, compareSeries }) => {
    const lines = useMemo(() => {
        if (!data?.[0]) {
            return [];
        }
        return Object.keys(data?.[0])?.filter((line) => line !== "name") ?? [];
    }, [data]);

    const originalLines = lines.filter((line) => !line.startsWith("compare"));
    const compareLines = lines.filter((line) => line.startsWith("compare"));

    const CustomTooltip = ({ payload }) => {
        const originalLinesTooltip = payload.filter((line) => !line.dataKey.startsWith("compare"));
        const compareLinesTooltip = payload.filter((line) => line.dataKey.startsWith("compare"));

        return (
            <div
                style={{
                    border: "1px solid #61D5A9",
                    borderRadius: "10px",
                    paddingInline: "5px",
                    paddingBlock: "10px",
                    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
                    width: "fit-content",
                    backgroundColor: "#fff",
                }}
            >
                <Grid container xs={12}>
                    <div
                        style={{
                            display: "flex",
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "#9D9D9D",
                        }}
                    >
                        <div style={{ width: "130px" }}>&nbsp;</div>
                        <div style={{ width: "70px", textAlign: "end" }}>
                            {originalLinesTooltip[0]?.payload.name}
                        </div>
                        <div
                            style={{
                                width: compareLinesTooltip?.length === 0 ? "0px" : "70px",
                                textAlign: "end",
                                marginLeft: "20px",
                            }}
                        >
                            {compareLinesTooltip?.length > 0
                                ? compareSeries?.[
                                data?.findIndex((dt) => dt.name === compareLinesTooltip[0]?.payload.name)
                                ] || ""
                                : ""}
                        </div>
                    </div>
                </Grid>
                {originalLinesTooltip?.map((ol) => {
                    return (
                        <div
                            style={{
                                fontSize: "10px",
                                color: "#9D9D9D",
                                fontWeight: 400,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    width: "130px",
                                }}
                            >
                                <span style={{ marginTop: "4px", marginRight: "5px" }}>
                                    <FilledCircleIcon color={ol.stroke} />
                                </span>
                                <span>
                                    {ol.dataKey?.split("_").slice(1).join(" ")}{" "}
                                    {symbolMapper[ol.dataKey]
                                        ? `(${symbolMapper[ol.dataKey]})`
                                        : ""}{" "}
                                </span>
                            </div>
                            <div style={{ width: "70px", textAlign: "end" }}>{ol.value}</div>

                            <div
                                style={{
                                    width: compareLinesTooltip?.length === 0 ? "0px" : "70px",
                                    textAlign: "end",
                                    marginLeft: "20px",
                                }}
                            >
                                {compareLinesTooltip?.find((cl) => {
                                    const l = cl.dataKey?.split("compare")[1];
                                    return (
                                        originalLinesTooltip?.find((o) => o.dataKey === l) &&
                                        l === ol.dataKey
                                    ) || false;
                                })?.value ?? (
                                    <span
                                        style={{
                                            width: compareLinesTooltip?.length === 0 ? "0px" : "70px",
                                            textAlign: "end",
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const getLeftMargin = () => {
        return originalLines?.length <= 2 ? -20 : 30;
    };
    const getRightMargin = () => {
        return originalLines?.length === 2 ? -20 : 30;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={"100%"}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: getRightMargin(),
                    left: getLeftMargin(),
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} strokeWidth={0.5} />
                <XAxis
                    interval="preserveStartEnd"
                    style={{
                        marginTop: "10px",
                        fontSize: "8px",
                        fontWeight: 400,
                        fontFamily: "inter !important",
                        color: "#C4C4C4",
                    }}
                    padding={{ left: 20, right: 20 }}
                    stroke="#C4C4C4"
                    tickLine={{
                        strokeWidth: 0.5,
                        style: { transform: "rotateX(15deg)" }
                    }}
                    axisLine={{
                        strokeWidth: 0.5,
                    }}
                    dataKey="name"
                />

                {originalLines.length <= 2 &&
                    originalLines.map((lineKey, index) => (
                        <YAxis
                            key={`y-axis-${lineKey}`}
                            yAxisId={lineKey}
                            orientation={index % 2 === 0 ? "left" : "right"}
                            tickFormatter={formatCash}
                            tickLine={false}
                            axisLine={false}
                            style={{
                                fontSize: "8px",
                                fontFamily: "inter !important",
                                color: "#C4C4C4",
                                fontWeight: 400,
                            }}
                            tick={{ fill: "#C4C4C4" }}
                        />
                    ))}

                <Tooltip content={<CustomTooltip />} />

                {originalLines.map((lineKey, index) => (
                    <Line
                        key={lineKey}
                        yAxisId={lineKey}
                        type="linear"
                        dataKey={lineKey}
                        stroke={colors[index]}
                        strokeWidth={2}
                        dot={false}
                    />
                ))}

                {compareLines.map((lineKey) => {
                    const l = lineKey.split("compare")[1];
                    const originalLineIndex = originalLines.findIndex((ol) => ol === l);
                    return (
                        <Line
                            key={lineKey}
                            yAxisId={l}
                            type="linear"
                            dataKey={lineKey}
                            stroke={colorMapper[colors[originalLineIndex]]}
                            dot={false}
                            strokeDasharray={"3 3"}
                            strokeWidth={2}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default BiAxialLineChart;
