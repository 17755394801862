import React, {useRef, useState} from 'react';
import {Chip, Divider, IconButton, ListItem, ListItemText, Popover, Stack, Typography,} from '@mui/material';
import {FixedSizeList as VirtualList} from 'react-window';
import Icons from "components/DmpIcons/icons";
import CustomOutlinedInput from "../../../components/CustomOutlinedInput/CustomOutlinedInput";

const SelectedHotels = ({ selectedHotels = [], handleRemoveHotel }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleClose = () => {
        setAnchorEl(null);
        setSearchTerm('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredHotels = selectedHotels.filter(hotel =>
        hotel.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const Row = ({ index, style }) => {
        const hotel = filteredHotels[index];
        return (
            <ListItem style={style} key={hotel.id} disablePadding>
                <ListItemText
                    primary={
                        <Typography fontSize="12px" fontFamily="Inter">
                            {hotel.name}
                        </Typography>
                    }
                />
                <IconButton
                    onClick={() => handleRemoveHotel(hotel.id)}
                    size="small"
                    style={{ marginLeft: "8px" }}
                    aria-label={`Remove ${hotel.name}`}
                >
                    <Icons.ActionsIcons.DeleteIcon style={{ width: "12px", height: "12px" }} />
                </IconButton>
            </ListItem>
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'selected-hotels-popover' : undefined;

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className="selected-hotels">
            <div ref={anchorRef} style={{ position: 'relative' }}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    {(!selectedHotels || selectedHotels.length === 0) ? (
                        <Chip
                            label="No hotel"
                            color="primary"
                            style={{
                                minWidth: "80px",
                                height: "22px",
                                border: "1px solid #C5C2C2",
                                margin: '4px',
                                fontSize: "12px",
                                fontFamily: "Inter",
                                fontWeight: 400
                            }}
                        />
                    ) : (
                        // Sinon, afficher uniquement le chip "+X more"
                        <Chip
                            label={`${selectedHotels.length} ${selectedHotels.length === 1 ? 'Hotel' : 'Hotels'}`}
                            color="primary"
                            style={{
                                minWidth: "80px",
                                height: "22px",
                                border: "1px solid #C5C2C2",
                                margin: '4px',
                                fontSize: "10px",
                                fontFamily: "Inter",
                                fontWeight: 400,
                                cursor: "pointer"
                            }}
                            onClick={handleMoreClick}
                        />
                    )}

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '350px', maxHeight: '500px' },
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                                <div style={{ padding: '16px 16px 8px 16px', flexShrink: 0 }}>
                                    <Typography fontSize={"12px"} fontFamily={"Inter"} >ALL SELECTED HOTELS</Typography>
                                    <Divider style={{ marginTop: '8px' }} />
                                </div>

                                <div style={{ padding: '5px 16px 16px 16px', overflowY: 'auto', flexGrow: 1 }}>
                                    <CustomOutlinedInput
                                        placeholder={"Search hotels..."}
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    {filteredHotels.length > 0 ? (
                                        <VirtualList
                                            height={300}
                                            itemCount={filteredHotels.length}
                                            itemSize={35}
                                            width={'100%'}
                                        >
                                            {Row}
                                        </VirtualList>
                                    ) : (
                                        <Typography fontSize={"12px"} fontFamily={"Inter"} variant="body2">No hotels found.</Typography>
                                    )}
                                </div>
                            </div>
                        </Popover>

                </Stack>
            </div>
        </div>
    );
};

export default SelectedHotels;
