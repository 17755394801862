import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { APP_BING_BIDDING_API_ENDPOINT } from "constants/api_urls";
import {useDispatch, useSelector} from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import { bingBiddingOptionsFormFields } from "./options";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";
import {setOpenEditBidding, updateEditBidHotels} from "../../../../../store/slices/editBid/editBid";
import {validateBiddingOptionsPayload} from "../formValidator";
import {getUserToken} from "../../../../../utils/functions/userToken";

const fields = {
  baseBid: "bidding_value",
  bidStrategy: "bidding_type",
};

const Bing = () => {
  const token = getUserToken();
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const { hotels = [] } = useSelector((state) => state?.editBid);
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState(hotels);
  const [updateBidPayload, setUpdateBidPayload] = useState({
    account_id: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const { hotel_list = [], group_list = [], currency_symbol = "", can_use_group } = biddingList || {};
  const dispatch = useDispatch();
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: " Select a hotel ",
      options: hotel_list,
    },
  ];

    const [errors, setErrors] = useState({});

    const handleChangeFieldValue = useCallback((field, value) => {
        setUpdateBidPayload((prev) => ({ ...prev, [field]: value }));
    }, []);

    useEffect(() => {
        handleChangeFieldValue(selectedHotelOrGroupType === "hotels"? "hotel_id": "group_id",hotels.map((s) => s.id));
    }, [hotels,selectedHotelOrGroupType,handleChangeFieldValue]);

  useEffect(() => {
    if (account_id) {
      setUpdateBidPayload((prev) => ({ ...prev, account_id }));
      axios
        .get(
          `${APP_BING_BIDDING_API_ENDPOINT}/api/bing-hotel-ads/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id,token]);

  const handleSave = useCallback(() => {
      if (!validateBiddingOptionsPayload(updateBidPayload,setErrors)) {
          const finalPayload = {...updateBidPayload};
          if (selectedHotelOrGroupType === "hotels") {
              delete finalPayload?.group_id;
          }
          if (selectedHotelOrGroupType === "groups") {
              delete finalPayload?.hotel_id;
          }
          axios
              .put(
                  `${APP_BING_BIDDING_API_ENDPOINT}/api/bing-hotel-ads/update-bids/`,
                  finalPayload,
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  }
              )
              .then((res) => {
                  setToastMessage("Updated successfully");
                  setToastType("success");
                  setShowToast(true);
              })
              .catch((err) => {
                  setToastMessage(
                      err?.response?.data[0]?.errorMessage || err?.[0]?.errorMessage || err?.message || "Something went wrong"
                  );
                  setToastType("error");
                  setShowToast(true);
              });
      }
  }, [updateBidPayload,selectedHotelOrGroupType,token]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
        <div className="form-button-container">
            <div className="formGrid">
                <div className="common-field-container bidding_multiplier_height">
                    {COMMONFORMFIELDS.map((field) => {
                        if (field.id === "hotel") {
                            return (
                                <div className="form-grid-item">
                                    <div className="mul-form-label">Hotels</div>
                                    <div ref={hotelButtonRef}>
                                        <TextInput
                                            autoComplete="off"
                                            placeholder={field.placeholder}
                                            type={"text"}
                                            readOnly
                                            value={
                                                selectedHotelsorGroups.length > 0
                                                    ? selectedHotelsorGroups.length === 1
                                                        ? selectedHotelsorGroups[0].name
                                                        : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                                                    : ""
                                            }
                                            sx={{
                                                backgroundColor: "white",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                paddingLeft: "4px",
                                            }}
                                            endAdornment={<ArrowDropDown/>}
                                            onClick={(e) => {
                                                setOpenHotelMenu(true);
                                            }}
                                            error={errors.hotelId}
                                        />
                                    </div>
                                    {hotel_list?.length ? (
                                        <HotelsFilterMenu
                                            allHotels={hotel_list?.map((hotel) =>
                                                selectedHotelsorGroups?.find((h) => h.id === hotel.id) || hotel_list.length ===1
                                                    ? {...hotel, isSelected: true}
                                                    : {...hotel, isSelected: false}
                                            )}
                                            allGroups={group_list?.map((group) =>
                                                selectedHotelsorGroups?.find((h) => h.id === group.id)
                                                    ? {...group, isSelected: true}
                                                    : {...group, isSelected: false}
                                            )}
                                            showGroups={group_list.length !== 0 && can_use_group}
                                            anchorEl={hotelButtonRef.current}
                                            open={openHotelMenu}
                                            onClose={() => {
                                                setOpenHotelMenu(false);
                                            }}
                                            onApply={(selected) => {
                                                dispatch(updateEditBidHotels(selected));
                                                setSelectedHotelsorGroups(selected);
                                                handleChangeFieldValue(
                                                    selectedHotelOrGroupType === "hotels"
                                                        ? "hotel_id"
                                                        : "group_id",
                                                    selected.map((s) => s.id)
                                                );
                                            }}
                                            setSelectedHotelOrGroupType={(type) => {
                                                setSelectedHotelOrGroupType(type);
                                                handleChangeFieldValue(
                                                    "bidding_level",
                                                    type === "hotels" ? "HOTEL" : "GROUP"
                                                );
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        }
                        return null;
                    })}
                    <div className="form-grid-item">
                        <div className="form-title">THIS APPLIES TO ALL MARKETS</div>
                    </div>
                </div>
                <div className="fields-container bidding_multiplier_height">
                    {bingBiddingOptionsFormFields?.map((field) => {
                        let newField = { ...field };
                        if (
                            updateBidPayload?.bidding_strategy?.bidding_type === "CPC_FIXED_BID_PER_NIGHT"
                        ) {
                            newField = { ...newField, numberUnit: currency_symbol };
                        }
                        return (
                            <div className="form-grid-item">
                                <FormFieldRenderer
                                    item={newField}
                                    options={field?.options || []}
                                    value={
                                        field.id === "baseBid" &&
                                        (updateBidPayload?.bidding_strategy?.bidding_value || "")
                                    }
                                    hotelMenuOpen={openHotelMenu}
                                    disabled={
                                        field.id === "baseBid" &&
                                        !updateBidPayload?.bidding_strategy?.bidding_type
                                    }
                                    onHotelMenuClose={() => {
                                        setOpenHotelMenu(false);
                                    }}
                                    onChange={(val) => {
                                        const fieldName = fields[field.id];

                                        if (fieldName === "bidding_type") {
                                            handleChangeFieldValue("bidding_strategy", {
                                                ...updateBidPayload?.bidding_strategy,
                                                [fieldName]: val,
                                                bidding_value: undefined,
                                            });
                                        } else {
                                            handleChangeFieldValue("bidding_strategy", {
                                                ...updateBidPayload?.bidding_strategy,
                                                [fieldName]:
                                                    field.id === "baseBid" ? Number(val) : val,
                                            });
                                        }
                                    }}
                                    labelKey={"label"}
                                    errors={errors}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* {!!updateBidPayload?.hotel_id?.length &&
          !!updateBidPayload?.bidding_strategy?.bidding_type && ( */}
            <div style={{display: 'flex', gap: 10, marginBottom:'-20px'}}>
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"85px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSave}
                >
                    Save
                </CustomButtonWithIcon>
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"85px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={() => {
                        dispatch(setOpenEditBidding(false));
                    }}
                >
                    Cancel
                </CustomButtonWithIcon>
            </div>
            {/* )} */}
        </div>
        <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => {
                setShowToast(false);
            }}
        >
            <Alert severity={toastType} variant="filled" sx={{width: "100%"}}>
                {toastMessage}
            </Alert>
        </Snackbar>
    </div>
  );
};

export default Bing;
