import {SettingsIcon,} from "../../../assets/images/Icons";
import {Box} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import "./ChartComponent.scss";
import GraphOptions from "./graph_options";
import BiAxialLineChart from "../../../components/BiAxialLineChart";
import MetricSelector from "./MetricSelector";
import CustomDrawerComponent from "../Utils/CustomDrawerComponent";

export default function ChartComponent({
    comparitionData = [],
    rawChartData = [],
    backgroundColor = "white",
    menuOptions = [],
    axisData = [],
    errorMessage = null
}) {
    const [option1, setOption1] = useState("noMetric");
    const [option2, setOption2] = useState("noMetric");
    const [option3, setOption3] = useState("noMetric");
    const [option4, setOption4] = useState("noMetric");
    const [compareOption1, setCompareOption1] = useState("noMetric");
    const [compareOption2, setCompareOption2] = useState("noMetric");
    const [compareOption3, setCompareOption3] = useState("noMetric");
    const [compareOption4, setCompareOption4] = useState("noMetric");
    const [isChartCollapsed] = useState(false);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const filterRef = useRef(null);
    const [filter, setFilter] = useState("daily");
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        setIsHidden(window.innerWidth > 1200 && window.innerWidth < 1650);

        if (menuOptions.length > 0) {
            setOption1(menuOptions[0]?.id || "noMetric");
            setOption2(menuOptions[1]?.id || "noMetric");
        }
    }, [menuOptions]);

    const getColors = useMemo(() => {
        const colors = ["#12794F", "#4470C1", "#EA9196", "#46508D"];
        [option1, option2, option3, option4].forEach((i, index) => {
            if (i === "noMetric") {
                colors[index] = null;
            }
        });
        return colors.filter((i) => i !== null);
    }, [option1, option2, option3, option4]);

    const chartData = useMemo(() => {
        const data = axisData[filter]?.map((date, index) => {
            const lines = [option1, option2, option3, option4]
                .filter((op) => op !== "noMetric")
                .reduce((acc, metric) => {
                    // TODO : API backend need to cast value into int
                    const val = Number(rawChartData?.[filter]?.[metric]?.[index]);
                    return ({
                        ...acc,
                        [metric]: !Number.isNaN(val) ? val : undefined,
                    });
                }, {});

            const lines2 = [compareOption1, compareOption2, compareOption3, compareOption4]
                .filter((op) => op !== "noMetric")
                .reduce((acc, metric) => {
                    const val = Number(
                        comparitionData?.[filter]?.[metric]?.[index]
                    );
                    const key = "compare" + metric;
                    return ({
                        ...acc,
                        [key]: !Number.isNaN(val) ? val : undefined,
                    });
                }, {});

            return {
                name: date,
                ...lines,
                ...lines2,
            };
        });

        return data || [];
    }, [rawChartData, axisData, filter, option1, option2, option3, option4, compareOption1, compareOption2, compareOption3, compareOption4, comparitionData]);

    const isLoading = (chartData.length === 0);

    return (
        <Box
            className="chartComponent"
            position="relative"
            style={{ backgroundColor, overflow: "hidden" }}
        >
            <CustomDrawerComponent isLoading={isLoading} errorMessage={errorMessage} />
            <Box className={`chartHeader ${isChartCollapsed ? "collapsed" : ""}`}>
                {isChartCollapsed && <Box className="title"> Graph </Box>}
                <Box className="menuContainer">
                    <MetricSelector
                        option={option1}
                        setOption={setOption1}
                        compareOption={compareOption1}
                        setCompareOption={setCompareOption1}
                        menuOptions={menuOptions}
                        excludeOptions={[option2, option3, option4]}
                        color="#12794F"
                    />

                    <MetricSelector
                        option={option2}
                        setOption={setOption2}
                        compareOption={compareOption2}
                        setCompareOption={setCompareOption2}
                        menuOptions={menuOptions}
                        excludeOptions={[option1, option3, option4]}
                        color="#4470C1"
                    />

                    <MetricSelector
                        option={option3}
                        setOption={setOption3}
                        compareOption={compareOption3}
                        setCompareOption={setCompareOption3}
                        menuOptions={menuOptions}
                        excludeOptions={[option1, option2, option4]}
                        color="#EA9196"
                    />

                    <MetricSelector
                        option={option4}
                        setOption={setOption4}
                        compareOption={compareOption4}
                        setCompareOption={setCompareOption4}
                        menuOptions={menuOptions}
                        excludeOptions={[option1, option2, option3]}
                        color="#46508D"
                        isHidden={isHidden}
                    />

                    <Box className="filters">
                        <Box
                            onClick={() => {
                                setIsFilterMenuOpen(!isFilterMenuOpen);
                            }}
                            ref={filterRef}
                        >
                            <SettingsIcon color="#12794F" />
                            <GraphOptions
                                open={isFilterMenuOpen}
                                anchorEl={filterRef}
                                onClose={() => {
                                    setIsFilterMenuOpen(false);
                                }}
                                onChange={(value) => {
                                    setFilter(value);
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={`chartBody ${isChartCollapsed ? "hidden" : ""}`}>
                <BiAxialLineChart data={chartData} colors={getColors} />
            </Box>
        </Box>
    );
}