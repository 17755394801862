// CustomOutlinedInput.js
import React from "react";
import {
    FormControl,
    OutlinedInput,
    FormHelperText,
} from "@mui/material";
import "@react-awesome-query-builder/ui/css/styles.css";
import "@react-awesome-query-builder/ui/css/compact_styles.css";

const CustomOutlinedInput = ({
                                 value,
                                 onChange,
                                 placeholder,
                                 label,
                                 error,
                                 success,
                                 helperText,
                                 helperTextFontSize = "10px",
                                 sx,
                                 minWidth = "180px",
                                 className,
                                 inputPaddingLeft="10px",
                                 InputProps = {},
                                 inputProps = {},
                                 onBlur,
                                 type,
                                 fullWidth,
                                 size = "small",
                                 ...props
                             }) => {
    const getHelperTextColor = () => {
        if (error) return '#d32f2f';
        if (success) return 'green';
        return '#BAB7B7';
    };

    return (
        <FormControl
            sx={{
                minWidth: minWidth,
                width: fullWidth ? '100%' : 'auto',
                padding: 0,
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                ...sx,
            }}
            size={size}
            error={error}
            variant="outlined"
            className={className}
            fullWidth={fullWidth}
            {...props}
        >
            <OutlinedInput
                error={error}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                inputProps={inputProps}
                onBlur={onBlur}
                type={type}
                sx={{
                    height: '24.25px',
                    background: "white",
                    '& .MuiOutlinedInput-input': {
                        paddingLeft: inputPaddingLeft,
                        fontSize: '12px',
                        fontFamily: 'Inter',
                    },
                    // Error state styling
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f !important',
                        borderWidth: '1px',
                    },
                    // Hover state when in error
                    '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f !important',
                    },
                    // Focused state when in error
                    '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f !important',
                        borderWidth: '2px',
                    },
                    // Default states
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#C4C4C4',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#61D5A9',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#61D5A9',
                    },
                    ...InputProps.sx,
                }}
                {...InputProps}
                {...props}
            />
            <FormHelperText
                sx={{
                    error: error,
                    fontSize: helperTextFontSize,
                    marginLeft: "5px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: getHelperTextColor(),
                    minHeight: "34px",
                    maxWidth: "150px",
                    visibility: helperText ? 'visible' : 'hidden',
                }}
            >
                {helperText || " "}
            </FormHelperText>
        </FormControl>
    );
};

export default CustomOutlinedInput;
