import React, {useEffect} from "react";
import Layout from "../Layout";
import {useLocation, useNavigate} from "react-router-dom";


const useQuery = () => new URLSearchParams(useLocation().search)

const LostPassword = () => {
    const navigate = useNavigate();

    const query = useQuery()
    const token = query.get("token") || ""

    useEffect(() => {
        if (token) {
            sessionStorage.setItem("token", token)
            navigate("/reset-password")
        }
    },[navigate, token]);

    return (
        <Layout>
            <div className="box-2">
                <div style={{marginBottom: "20px"}}>
                    Navigating to Reset Password Page
                </div>

            </div>
        </Layout>
    );
};

export default LostPassword;
