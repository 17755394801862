// images
import IconMetaSearch from "../assets/images/IconMetaSearch.svg";
import IconSocialAds from "../assets/images/IconSocialAds.svg";
import IconSearchAds from "../assets/images/IconSearchAds.svg";
import IconOtaAds from "../assets/images/IconOtaAds.svg";
import IconPMax from "../assets/images/IconPMax.svg";
import IconSeo from "../assets/images/IconSeo.svg";
import IconCms from "../assets/images/IconCms.svg";
import IconRateMatch from "../assets/images/IconRateMatch.svg";
import IconPushTech from "../assets/images/IconPushTech.svg";
import IconHome from "../assets/images/IconHome.svg";

import {
  REACT_APP_ICONCMS_URL,
  REACT_APP_RATEMATCH_URL,
  REACT_APP_ICONSEO_URL,
  REACT_APP_PUSHTECH_URL,
} from "./external_urls";

export const homepageSideMyMenuItems = (accountId ) => [
  {
    name: "Meta Search",
    icon: IconMetaSearch,
    disabled: false,
    url: `/metasearch/${accountId}`,
  },
  {
    name: "Search ads",
    icon: IconSearchAds,
    disabled: false,
    url: `/searchads/${accountId}`,
  },
  {
    name: "PMax",
    icon: IconPMax,
    disabled: false,
    url: `/pmax-campaign-manager/${accountId}`,
  },
];
export const drawerItems = (accountId) => [
  {
    name: "Home",
    icon: IconHome,
    disabled: false,
    url: `/home/${accountId}`,
    action: "",
  },
  {
    name: "META",
    icon: IconMetaSearch,
    disabled: false,
    url: `/metasearch/${accountId}`,
    action: "metaSearch",
  },
  {
    name: "SEM",
    icon: IconSearchAds,
    disabled: false,
    url: `/searchads/${accountId}`,
    action: "SearchAdsPage",
  },
  {
    name: "PMAX",
    icon: IconPMax,
    disabled: false,
    url: `/pmax-campaign-manager/${accountId}`,
    action: "PMax",
  },
];

export const PRODUCT_MENU_LIST = [
  {
    heading: "Paid Ads",
    className: "split",
    menuItems: [
      {
        icon: IconMetaSearch,
        text: "META SEARCH",
        url: "/",
      },
      {
        icon: IconSearchAds,
        text: "SEARCH ADS",
        external: true,
        url: "/",
      },
      {
        icon: IconPMax,
        text: "PMAX",
        external: true,
        url: "/",
      },
    ],
  },
  {
    heading: "Website",
    menuItems: [
      {
        icon: IconSeo,
        text: "SEO",
        url: REACT_APP_ICONSEO_URL,
      },
      {
        icon: IconCms,
        text: "CMS",
        url: REACT_APP_ICONCMS_URL,
      },
    ],
  },
  {
    heading: "Rate Match",
    menuItems: [
      {
        icon: IconRateMatch,
        text: "RATE MATCH",
        url: REACT_APP_RATEMATCH_URL,
      },
    ],
  },
  {
    heading: "CRM",
    menuItems: [
      {
        icon: IconPushTech,
        text: "PUSHTECH",
        url: REACT_APP_PUSHTECH_URL,
      },
    ],
  },
];

export const homepageSideMoreMenuItems = [
  {
    name: "SOCIAL ADS",
    icon: IconSocialAds,
    disabled: false,
  },
  {
    name: "OTA ADS",
    icon: IconOtaAds,
    disabled: false,
  },
];

export const homepageMenuItems = (accoundId) => [
  {
    id: "settings",
    label: "Settings",

    children: [
      {
        id: "bidding-center",
        label: "Bidding Center",
        link: `/bidding-center/${accoundId}`,
      },
      {
        id: "bidding-offline",
        label: "Bidding Offline",
        link: `/bidding-offline/${accoundId}`,
      },
    ],
  },
  {
    id: "reports",
    label: "Reports",

    children: [
      {
        id: "report-center",
        label: "Report Center",
        link: `/report-center/${accoundId}?product=metaSearch`,
      },
      {
        id: "alert-center",
        label: "Alert Center",
        link: `/alert-center/${accoundId}?product=metaSearch`,
      },
    ],
  },

  { id: "groups", label: "Groups", link: `/groups/${accoundId}?product=metaSearch` },

  {
    id: "reservations",
    label: "Reservations",
    link: `/reservations/${accoundId}?product=metaSearch`,
  },
];

export const searchAdspageMenuItems = (accountId ) => [
  {
    id: "search-ads",
    label: "Search Ads",
    children: [
      {
        id: "keywords-reporting",
        label: "Keywords",
        link: `/keywords-reporting/${accountId }`,
      },
      {
        id: "search-terms-reporting",
        label: "Search Terms",
        link: `/search-terms-reporting/${accountId }`,
      },
      {
        id: "shared-budget-reporting",
        label: "Shared Budget",
        link: "sharedBudget",
      },
    ],
  },

  {
    id: "reports",
    label: "Reports",

    children: [
      {
        id: "report-center",
        label: "Report Center",
        link: `/report-center/${accountId}?product=SearchAdsPage`,
      },
      {
        id: "alert-center",
        label: "Alert Center",
        link: `/alert-center/${accountId}?product=SearchAdsPage`,
      },
    ],
  },
  { id: "groups", label: "Groups", link: `/groups/${accountId}?product=SearchAdsPage` },
];

export const pMaxpageMenuItems = (accountId ) => [
  {
    id: "reports",
    label: "Reports",

    children: [
      {
        id: "report-center",
        label: "Report Center",
        link: `/report-center/${accountId}?product=PMax`,
      },
      {
        id: "alert-center",
        label: "Alert Center",
        link: `/alert-center/${accountId}?product=PMax`,
      },
    ],
  },
  { id: "groups", label: "Groups", link: `/groups/${accountId}?product=PMax` },
];
