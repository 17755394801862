import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CallsSection = ({ calls, handleInputChange, onBlur, callStatus }) => {
  return (
    <Grid item md={6} xs={6} spacing={2}>
      <Box className="calls-section" mt={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="header-box"
        >
          <Typography variant="subtitle1">Calls</Typography>
          <Box display="flex" alignItems="center" gap={1}></Box>
        </Box>

        <Box className="content-box">
          {calls.map((call) => (
            <div>
              <PhoneInput
                className="phone-input-field no-border-focus"
                placeholder="Enter phone number"
                value={call.phoneNumber}
                onChange={(value) =>
                  handleInputChange(call.id, "phoneNumber", value)
                }
                international
                onCountryChange={(value) =>
                  handleInputChange(call.id, "countryCode", value)
                }
                defaultCountry={call?.countryCode}
                onBlur={(e) => onBlur(call.id)}
              />
              {callStatus?.[call.id] && (
                <span
                  style={{
                    fontSize: "8px",
                    marginLeft: "5px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: callStatus[call.id]
                      ? callStatus[call.id].success &&
                        !callStatus[call.id]?.data?.phone_call?.phoneNumber?.[0]
                          ?.errorMessage
                        ? "green"
                        : "#d32f2f"
                      : "#BAB7B7",
                  }}
                >
                  {callStatus[call.id]?.error ||
                    callStatus[call.id]?.data?.phone_call?.phoneNumber?.[0]
                      ?.errorMessage ||
                    "Enter phone number"}
                </span>
              )}
            </div>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default CallsSection;
