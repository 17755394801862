import React, { useState } from 'react';
import CustomCheckbox from "components/Checkbox";

function BiddingOfflineExportBreakdownBuilder({ title, checkboxItems, onChange }) {
    // État local pour les valeurs des checkboxes
    const initialCheckboxState = checkboxItems.reduce((acc, item) => {
        acc[item.value] = false; // Initialiser toutes les checkboxes à false
        return acc;
    }, {});

    const [checkboxValues, setCheckboxValues] = useState(initialCheckboxState);

    // Fonction pour gérer le changement de valeur d'une checkbox
    const handleCheckboxChange = (value) => {
        const newValues = {
            ...checkboxValues,
            [value]: !checkboxValues[value], // Bascule la valeur
        };
        setCheckboxValues(newValues);

        // Transforme les valeurs en tableau d'objets et les renvoie au parent
        const selectedBreakdowns = checkboxItems
            .filter(item => newValues[item.value]) // Filtrer les titres sélectionnés
            .map(item => (item.value)); // Créer le tableau d'objets

        onChange(selectedBreakdowns); // Appeler la fonction pour passer les valeurs au parent
    };

    return (
        <div className="row">
            <div className="row-label">{title}</div>
            <div className="row-item-container">
                {checkboxItems.map(({ value, title }) => (
                    <div className="row-item" key={value}>
                        <CustomCheckbox
                            title={title} // Afficher le titre
                            checked={checkboxValues[value]} // Vérifie si la checkbox est cochée
                            onChange={() => handleCheckboxChange(value)} // Gère le changement
                            labelStyles={{ fontSize: '12px', fontFamily: "Inter", fontWeight: "400" }} // Style du texte
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BiddingOfflineExportBreakdownBuilder
