import UnitBase from './UnitBase.js';
import getSymbolFromCurrency from "currency-symbol-map";

export default class CurrencyUnit extends UnitBase {
    constructor(context) {
        super(context,'', '');

        this.currencyMap = {
            EUR: { label: 'Euro', symbol: getSymbolFromCurrency('EUR') },
            USD: { label: 'Dollar US', symbol: getSymbolFromCurrency('USD') },
        };
    }

    getLabel() {
        return this.label
    }

    getSymbol() {
        return this.symbol
    }

    setContext(context = {}) {
        const { currencyCode } = context;
        const currency = this.currencyMap[currencyCode] || {};
        this.label = currency.label || 'Unknown Currency';
        this.symbol = currency.symbol || '';
    }
}
