import React from "react";
import { Box } from "@mui/material";
import { DecreaseIcon, IncreaseIcon } from "../../../assets/images/Icons";
import { mapper } from "components/DatePicker/DatePicker";
import './MetricsComponent.scss';
import CustomDrawerComponent from "../Utils/CustomDrawerComponent";

const MetricsComponent = ({ metrics = [], calender, errorMessage }) => {
    const isComparisonEnabled = Boolean(calender?.compareWith);
    const defaultLabels = ["Cost", "Generated", "Clicks", "CPC", "Conversion Rate", "ROAS"];

    const filledMetrics = [...metrics, ...defaultLabels.slice(metrics.length).map((label) => ({
        label,
        value: "--",
        color: "#E0E0E0",
    }))].slice(0, 6);

    const isLoading = metrics.length === 0;

    const renderComparisonInfo = (item, index) => (
        <Box>
            <span className="score-direction">
                {item?.direction === "up" ? <IncreaseIcon /> : <DecreaseIcon />}
            </span>
            <span
                className={`score-percentage ${
                    item?.direction === "up" ? "score-increase" : "score-decrease"
                }`}
            >
                {item.percentage}%{" "}
            </span>
            <span className="comparison-label">
                    vs {isComparisonEnabled
                ? mapper[calender?.comparitionSelection] ?? ""
                : "Previous Year"}
            </span>
        </Box>
    );

    return (
        <Box className="kpi-metrics-container">
            <Box className="metrics-row">
                {filledMetrics.map((item, index) => (
                    <Box
                        key={index}
                        className="metrics-box"
                        style={{
                            marginRight: index === 5 ? "0px" : "14px",
                        }}
                    >
                        <CustomDrawerComponent isLoading={isLoading} errorMessage={errorMessage} />
                        <Box
                            className="metrics-box-bottom-bar"
                            style={{
                                backgroundColor: item.color,
                            }}
                        />
                        <Box className="metrics-box-content">
                            <Box className="score-title">{item.label}</Box>
                            <Box className="score-value">{item.value}</Box>
                            {item.percentage && renderComparisonInfo(item, index)}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default MetricsComponent;