import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentDateRange: {
    startDate: null,
    endDate: null,
  },
  comparitionDateRange: {
    startDate: null,
    endDate: null,
  },
  currentSelection: "",
  comparitionSelection: "",
  compareWith: false,
};
export const calenderSlice = createSlice({
  name: "calenderSlice",
  initialState: initialState,
  reducers: {
    updateCurrentDateRange: (state, action) => {
      state.currentDateRange = action.payload;
    },
    updateComparitionDateRange: (state, action) => {
      state.comparitionDateRange = action.payload;
    },
    updateCurrentSelection: (state, action) => {
      state.currentSelection = action.payload;
    },
    updateComparitionSelection: (state, action) => {
      state.comparitionSelection = action.payload;
    },
    updateCompareWith: (state, action) => {
      state.compareWith = action.payload;
    },
    resetCalendar: () => initialState
  },
});

export const {
  updateComparitionDateRange,
  updateComparitionSelection,
  updateCurrentDateRange,
  updateCurrentSelection,
  updateCompareWith,
  resetCalendar
} = calenderSlice.actions;
export default calenderSlice.reducer;
