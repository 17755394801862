import React, { useState } from "react";
import PrimarySearchAppBar from "../../components/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import {
  homepageMenuItems,
  homepageSideMyMenuItems,
  homepageSideMoreMenuItems,
  searchAdspageMenuItems,
  pMaxpageMenuItems,
} from "../../constants/productmenu";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const DefaultLayout = ({ page, children,
    mainBoxSx = {
        display: "flex",
        height: "95.5vh",
        overflow: "hidden",
    },
    contentBoxSx = {
        backgroundColor: "#FAFAFA",
        flex: 1,
        paddingTop: 2,
        width: "calc(100vw - 180px)",
        height: "100%",
        overflow: "auto",
    }}) => {
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const [currentSection, setCurrentSection] = useState(useSelector((state) => state?.sidebar) ?? page);

  const menuItems = () => {
    switch(page){
      case "SearchAdsPage":
        return searchAdspageMenuItems(account_id);
      case "PMax":
        return pMaxpageMenuItems(account_id);
      default:
        return homepageMenuItems(account_id)
    }
  }
  console.log('currentSection', currentSection)
  return (
    <>
      <PrimarySearchAppBar />

      <Box
        className="mainBox"
        sx={{
            ...mainBoxSx
        }}
      >
        <Sidebar
          page={page}
          menuItems={
            menuItems()
          }
          moreItems={homepageSideMoreMenuItems}
          initialItems={homepageSideMyMenuItems(account_id)}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
        <Box
          sx={{
              ...contentBoxSx
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export default DefaultLayout;
