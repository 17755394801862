import React, {useState} from "react";
import {
    Box,
    Typography,
    MenuItem,
    InputAdornment,
    Grid,
} from "@mui/material";
import {PMAX_BIDDING_STRATEGIES} from "../../../constants/biddingStrategies";
import { validateCampaignName, validateBiddingStrategy } from "../../Utils/validators/campaignSettingsValidators";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import CustomFormControl from "../../../../alert-center/Dropdown/CustomFormControl";

const CampaignSettingsDetails = ({
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    errors,
    idaccount,
    idhotel,
    idCampaign = null,
    currencySymbol
}) => {
    const [campaignNameStatus, setCampaignNameStatus] = useState(null);
    const [targetRoasStatus, setTargetRoasStatus] = useState(null);
    const [targetCpaStatus, setTargetCpaStatus] = useState(null);

    const handleCampaignNameBlur = async () => {
        if (!campaignName.trim()) return;

        const result = await validateCampaignName(idaccount, idhotel, campaignName, idCampaign);
        setCampaignNameStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid campaign name" }
        );
    };

    const handleTargetRoasBlur = async () => {
        if (!targetRoas.trim()) return;

        const result = await validateBiddingStrategy(biddingStrategy, targetRoas);
        setTargetRoasStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid Target ROAS" }
        );
    };

    const handleTargetCpaBlur = async () => {
        if (!targetCpa.trim()) return;

        const result = await validateBiddingStrategy(biddingStrategy, targetCpa);
        setTargetCpaStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid Target CPA" }
        );
    };

    const handleBiddingStrategyChange = (event) => {
        setBiddingStrategy(event.target.value);
    };

    const renderMaximizeConversionsFields = () => (
        <CustomOutlinedInput
            minWidth={"221px"}
            inputPaddingLeft="0px"
            className="no-border-focus"
            placeholder="Target CPA"
            value={targetCpa}
            onChange={(e) => setTargetCpa(e.target.value)}
            onBlur={handleTargetCpaBlur}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            helperText={
                errors.biddingValue
                    ? errors.biddingValue
                    : targetCpaStatus
                        ? targetCpaStatus.error || "Target CPA"
                        : "Target CPA"
            }
            error={Boolean(errors.biddingValue) || (targetCpaStatus && targetCpaStatus.success === false)}
            success={targetCpaStatus && targetCpaStatus.success === true}
            InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
        />
    );

    const renderMaximizeConversionValueFields = () => (
        <CustomOutlinedInput
            minWidth={"221px"}
            className="no-border-focus"
            placeholder="Target ROAS"
            value={targetRoas}
            onChange={(e) => setTargetRoas(e.target.value)}
            onBlur={handleTargetRoasBlur}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            helperText={
                errors.biddingValue
                    ? errors.biddingValue
                    : targetRoasStatus
                        ? targetRoasStatus.error || "Target ROAS"
                        : "Target ROAS"
            }
            error={Boolean(errors.biddingValue) || (targetRoasStatus && targetRoasStatus.success === false)}
            success={targetRoasStatus && targetRoasStatus.success === true}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
        />
    );

    return (
        <Box className="campaignSettingsDetails">
            <Box display="flex" >
                <Typography className="sectionTitle">
                    Create Campaign Name and Select Your Bidding Strategy
                </Typography>
            </Box>
            <Box p={2}>
                <Grid container alignItems="flex-start">
                    {/* Campaign Name */}
                    <Grid sx={{display: "flex", justifyContent: "center", paddingRight: "30px"}}>
                        <CustomOutlinedInput
                            minWidth={"221px"}
                            className="no-border-focus"
                            placeholder="Campaign Name"
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            onBlur={handleCampaignNameBlur}
                            inputProps={{ inputMode: "text" }}
                            helperText={
                                errors.campaignName
                                    ? errors.campaignName
                                    : campaignNameStatus
                                        ? campaignNameStatus.error || "Campaign Name"
                                        : "Campaign Name"
                            }
                            error={Boolean(errors.campaignName) || (campaignNameStatus && campaignNameStatus.success === false)}
                            success={campaignNameStatus && campaignNameStatus.success === true}
                        />
                    </Grid>

                    {/* Bidding Strategy and Conditional Fields */}
                    <Grid  >
                        <Grid sx={{display: "flex", flexDirection: "row", gap: "30px"}}>
                            {/* Bidding Strategy Selector */}
                            <Grid item>
                                <CustomFormControl
                                    minWidth={"221px"}
                                    value={biddingStrategy}
                                    onChange={handleBiddingStrategyChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return (
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#bab7b7"
                                                }}>Choose a bidding strategy</span>
                                            );
                                        }
                                        return PMAX_BIDDING_STRATEGIES[selected];
                                    }}
                                    error={errors.biddingStrategy}
                                    helperText={errors.biddingStrategy || "Bidding Strategy"}
                                    helperTextFontSize={"10px"}
                                >
                                    <MenuItem value="" disabled>
                                        <span style={{
                                            fontSize: "12px",
                                            color: "#bab7b7"
                                        }}>Choose a bidding strategy</span>
                                    </MenuItem>
                                    {Object.entries(PMAX_BIDDING_STRATEGIES).map(([key, value]) => {
                                        const formattedValue = key;
                                        return (
                                            <MenuItem key={formattedValue} value={formattedValue}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#373F41"
                                                }}>{value}</span>
                                            </MenuItem>
                                        );
                                    })}
                                </CustomFormControl>
                            </Grid>

                            {/* Conditional Fields */}
                            <Grid item>
                                {biddingStrategy === "MAXIMIZE_CONVERSIONS" && renderMaximizeConversionsFields()}
                                {biddingStrategy === "MAXIMIZE_CONVERSION_VALUE" && renderMaximizeConversionValueFields()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CampaignSettingsDetails;