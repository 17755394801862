import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
    const [loading, setLoading] = useState(false); // State for loading spinner

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Error during confirmation:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: 300,
                    p: 4,
                    backgroundColor: 'white',
                    margin: 'auto',
                    marginTop: '10%',
                    borderRadius: 2,
                    boxShadow: 24,
                    textAlign: 'center', // Center the content inside the modal
                }}
            >
                <Typography  sx={{ fontSize:'14px',fontWeight: 500,fontFamily:'inter' }}>
                    {message}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5, mb:-2 }}>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"100px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </CustomButtonWithIcon>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"100px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={handleConfirm}
                        disabled={loading}
                    >
                        Confirm
                    </CustomButtonWithIcon>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;