import "./index.css";
import React  from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";
import { Provider } from "react-redux";
import { store } from "store/store";
import { SnackbarProvider } from "notistack";
import App from "./App";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <SnackbarProvider maxSnack={10}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </SnackbarProvider>
);

reportWebVitals();
