import React, {useMemo, useState} from "react";
import "./SharedTable.scss";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchTableInput from "components/SearchTableInput/SearchTableInput";
import SharedBudgetReportCustomPagination
  from "features/searchads/TableContainer/SharedBudgetReportCustomPagination/index";

function SharedTable({
  columns,
  data = [],
  loading,
  onSearch,
  initialOrderBy = "alert_name",
  initialOrder = "asc",
  selectedRows,
  setSelectedRows,
  uniqueId = "id",
}) {
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [order, setOrder] = useState(initialOrder);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRequestSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter the data based on the search query

  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    return data.filter((row) => {
      return columns.some((column) => {
        const value = column.accessorKey
          ? column.accessorKey
              .split(".")
              .reduce((obj, key) => (obj ? obj[key] : null), row)
          : null;
        return (
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    });
  }, [data, columns, searchQuery]);


  const userCount = filteredData.length;
  const pageCount = Math.ceil(userCount / rowsPerPage);

  // Sort the filtered data
  const sortedData = useMemo(() => {
    if (orderBy) {
      return [...filteredData].sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];
        if (aValue < bValue) {
          return order === "asc" ? -1 : 1;
        } else if (aValue > bValue) {
          return order === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      return filteredData;
    }
  }, [filteredData, orderBy, order]);

  const paginatedData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return sortedData.slice(start, end);
  }, [sortedData, page, rowsPerPage]);

  return (
    <>
      <Paper className="paper">
        <Box className="header">
          <SearchTableInput
            placeholder={"Search"}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        <Box sx={{ position: "relative", width: "100%" }}>
          {loading && (
            <Box className="loader">
              <CircularProgress />
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                Chargement...
              </Typography>
            </Box>
          )}

          <Box
            className="tableContainer"
            sx={{ overflowX: "auto", width: "100%" }}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{ height: "40px" }}>
                    {/* <TableCell padding="checkbox"></TableCell> */}
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sortDirection={
                          orderBy === column.accessorKey ? order : false
                        }
                        sx={{
                          padding: "4px 8px",
                          fontSize: "11px",
                          textAlign: column.align || "left",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        <Tooltip title={column.header || ""} placement="bottom">
                          <TableSortLabel
                            active={
                              orderBy === column.accessorKey &&
                              column.enableSorting !== false
                            }
                            direction={
                              orderBy === column.accessorKey ? order : "asc"
                            }
                            onClick={() =>
                              column.enableSorting !== false &&
                              handleRequestSort(column.accessorKey)
                            }
                            hideSortIcon={column.enableSorting === false}
                            sx={{
                              display: "flex",
                              flexDirection:
                                column.align === "left" ? "row" : "row-reverse",
                              justifyContent: column.align || "flex-start",
                              color:
                                column.enableSorting === false
                                  ? "inherit"
                                  : "inherit",
                            }}
                          >
                            {column.header}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.length === 0 ? (
                    <TableRow sx={{ height: "40px" }}>
                      <TableCell
                        colSpan={columns.length + 1}
                        sx={{ textAlign: "center", padding: 2 }}
                      >
                        <Typography sx={{ fontSize: "0.75rem" }}>
                          No records available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      paginatedData.map((row, rowIndex) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={true}
                          tabIndex={-1}
                          key={row[uniqueId]}
                          sx={{ height: "40px" }}
                          // Enlever onClick ici si vous ne voulez pas que la ligne entière soit cliquable
                          // onClick={(event) => handleClick(event, row)}
                        >
                          {columns.map((column, colIndex) => {
                            const value = column.accessorKey
                              ? column.accessorKey
                                  .split(".")
                                  .reduce(
                                    (obj, key) => (obj ? obj[key] : null),
                                    row
                                  )
                              : null;

                            return (
                              <TableCell
                                key={colIndex}
                                sx={{
                                  padding: "4px 8px",
                                  textAlign: column.align || "left",
                                  fontSize: "10px",
                                }}
                              >
                                {column.Cell
                                  ? column.Cell({ row, value })
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Paper>
      <Box>
        <SharedBudgetReportCustomPagination
            pageCount={pageCount}
            page={page + 1}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </>
  );
}

export default SharedTable;
