export default class MetricBase {
    constructor({ field, defaultVisibility, unit, chartName, serieLabel, tooltip, group }) {
        if (this.constructor === MetricBase) {
            throw new Error("MetricBase is an abstract class and cannot be instantiated directly.");
        }
        this.field = field;
        this.defaultVisibility = defaultVisibility;
        this.unit = unit;
        this.chartName = chartName;
        this.serieLabel = serieLabel;
        this.tooltip = tooltip;
        this.group = group;
    }

}
