import React, { useState } from "react";
import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import {initialMonthlyBudget} from "./utils/initialState";
import {Alert, Box, Snackbar} from "@mui/material";
import GooglePMaxCampaignCreator from "../../features/google-pmax-campaign/GooglePMaxCampaignCreator";
import {useNavigate, useParams} from "react-router-dom";
import {
    validatePMaxCampaignSettings
} from "../../features/google-pmax-campaign/googlePmaxFormComponent/Utils/validators/campaignSettingsValidators";
import {
    removeCropParameters
} from "../../features/google-pmax-campaign/googlePmaxFormComponent/Utils/imageTools/imageCropper";
import {createPMaxCampaign} from "./utils/createCampaignPMax";
import {useSelector} from "react-redux";

const PMaxCampaignCreator = () => {
    const navigate = useNavigate();
    const { idaccount, idhotel } = useParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [expandedPanel, setExpandedPanel] = useState("settings");
    const [visitedPanels, setVisitedPanels] = useState(["settings"]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [targetValue, setTargetValue] = useState("");
    const [biddingStrategy, setBiddingStrategy] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [targetCpa, setTargetCpa] = useState("");
    const [targetRoas, setTargetRoas] = useState("");
    const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
    const [longHeadlines, setLongHeadlines] = useState([
        { id: 'longHeadline1', text: ''},
        { id: 'longHeadline2', text: ''},
        { id: 'longHeadline3', text: ''}
    ]);
    const [headlines, setHeadlines] = useState([
        { id: 'headline1', text: ''},
        { id: 'headline2', text: ''},
        { id: 'headline3', text: ''},
        { id: 'headline4', text: ''},
        { id: 'headline5', text: ''},
        { id: 'headline6', text: ''}
    ]);
    const [descriptions, setDescriptions] = useState([
        { id: 'description1', text: ''},
        { id: 'description2', text: ''},
        { id: 'description3', text: ''}
    ]);
    const [finalURL, setFinalURL] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [landscape, setLandscape] = useState([]);
    const [square, setSquare] = useState([]);
    const [portrait, setPortrait] = useState([]);
    const [logo, setLogo] = useState([]);
    const [landscapeLogo, setLandscapeLogo] = useState([]);
    const [errors, setErrors] = useState({});
    const selectedAccount = useSelector((state) => state?.account?.account || {});
    const currencySymbol = selectedAccount["currency"] === 'EUR' ? '€' : '$';

    const createCampaignSettingsPayload = () => ({
        account_id: idaccount,
        hotel_id: idhotel,
        campaign_name: campaignName,
        bidding_strategy: {
            bidding_strategy_option: biddingStrategy,
            bidding_value: biddingStrategy === "MAXIMIZE_CONVERSIONS" ? targetCpa : targetRoas,
        },
        budget: {
            default_budget: targetValue,
            budget_month_1: monthlyBudget.Jan,
            budget_month_2: monthlyBudget.Feb,
            budget_month_3: monthlyBudget.Mar,
            budget_month_4: monthlyBudget.Apr,
            budget_month_5: monthlyBudget.May,
            budget_month_6: monthlyBudget.Jun,
            budget_month_7: monthlyBudget.Jul,
            budget_month_8: monthlyBudget.Aug,
            budget_month_9: monthlyBudget.Sep,
            budget_month_10: monthlyBudget.Oct,
            budget_month_11: monthlyBudget.Nov,
            budget_month_12: monthlyBudget.Dec,
        },
        campaign_lang: selectedLanguage,
        point_of_sales: selectedCountries,
    });

    const createCampaignAdsCreativePayload = () => ({
        account_id: idaccount,
        hotel_id: idhotel,
        final_url: { url: finalURL },
        business_name: { assetContent: businessName },
        headline: headlines.map((headline) => ({ assetContent: headline.text })),
        description: descriptions.map((description) => ({ assetContent: description.text })),
        long_headline: longHeadlines.map((longHeadline) => ({ assetContent: longHeadline.text })),
        marketing_image: landscape.map((img) => ({ assetContent: removeCropParameters(img) })),
        square_marketing_image: square.map((img) => ({ assetContent: removeCropParameters(img) })),
        portrait_marketing_image: portrait.map((img) => ({ assetContent: removeCropParameters(img) })),
        logo_image: logo.map((img) => ({ assetContent: removeCropParameters(img) })),
        landscape_logo_image: landscapeLogo.map((img) => ({ assetContent: removeCropParameters(img) })),
    });

    const handleValidateCampaignSettings = async (campaignSettings) => {
        const validationResponse = await validatePMaxCampaignSettings(campaignSettings, idaccount, idhotel);
        if (!validationResponse.success) {
            setErrors(validationResponse.errors || {});
            const globalError = validationResponse.errors.global || "Please fix the highlighted errors.";
            setMessage(globalError);
            setSnackbarOpen(true);
            return false;
        }
        setErrors({});
        return true;
    };

    const handleCreateCampaign = async (campaignSettings, campaignAdsCreative) => {
        const response = await createPMaxCampaign({
            accountId: idaccount,
            hotelId: idhotel,
            campaignSettings,
            campaignAdsCreative,
        });

        if (response.success) {
            setMessage("PMax Campaign created successfully!");
            navigate(`/pmax-campaign-manager/${idaccount}`);
        } else {
            setErrors(response.errors || {});
            setMessage("Please fix the highlighted errors.");
        }

        setSnackbarOpen(true);
    };

    const handleNext = async (currentPanel) => {
        if (currentPanel === "settings") {
            const campaignSettings = createCampaignSettingsPayload();
            const isValid = await handleValidateCampaignSettings(campaignSettings);
            if (isValid) {
                setExpandedPanel("adsCreative");
                setVisitedPanels((prev) => [...prev, "adsCreative"]);
            }
        } else if (currentPanel === "adsCreative") {
            const campaignSettings = createCampaignSettingsPayload();
            const campaignAdsCreative = createCampaignAdsCreativePayload();
            await handleCreateCampaign(campaignSettings, campaignAdsCreative);
        }
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    return (
        <DefaultLayout page="PMax">
            <PageTitles
                pageTitle="PMax"
                pageSubTitle="Campaign Creator"
            />

            <Box p={2} className="google-pmax-campaign-creator-container">
                <GooglePMaxCampaignCreator
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                    visitedPanels={visitedPanels}
                    handleNext={handleNext}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    biddingStrategy={biddingStrategy}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas}
                    setTargetRoas={setTargetRoas}
                    monthlyBudget={monthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    headlines={headlines}
                    setHeadlines={setHeadlines}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    finalURL={finalURL}
                    setFinalURL={setFinalURL}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    longHeadlines={longHeadlines}
                    setLongHeadlines={setLongHeadlines}
                    idaccount={idaccount}
                    idhotel={idhotel}
                    landscape={landscape}
                    setLandscape={setLandscape}
                    square={square}
                    setSquare={setSquare}
                    portrait={portrait}
                    setPortrait={setPortrait}
                    logo={logo}
                    setLogo={setLogo}
                    landscapeLogo={landscapeLogo}
                    setLandscapeLogo={setLandscapeLogo}
                    errors={errors}
                    currencySymbol={currencySymbol}
                />

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </DefaultLayout>
    );
};

export default PMaxCampaignCreator;
