import React from "react";
import {Grid, Box, Select} from "@mui/material";
import { DeviceOptions } from "../../Utils/Options";
import { DeviceAvailableMetrics } from "../../Utils/AvailableMetrics";
import MenuItem from "@mui/material/MenuItem";
import DmpIcons from "../../../../components/DmpIcons/icons";

const TableDevice = ({ selectedOption, handleDropdownChange, tableMetrics, handleTableMetricChange, tableData }) => (
    <Grid item xs={8} sx={{ paddingLeft: '20px !important'}}>
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                }}>
                    <Select
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        variant="outlined"
                        displayEmpty
                        sx={{
                            color: "black",
                            fontSize: "12px",
                            padding: "0",
                            border: "none",
                            height: "28px",
                            background: "transparent",
                            display: "flex",
                            fontWeight: 600,
                            fontFamily: "Inter",
                            "& .MuiSelect-icon": {
                                color: "#BAB7B7",
                            },
                            "& .MuiSelect-select": {
                                padding: 0
                            },
                            "& fieldset": {
                                border: "none",
                                padding: 0
                            }
                        }}
                    >
                        {DeviceOptions.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    lineHeight: "1.2",
                                    fontFamily: "Inter"
                                }}
                            >
                                {option.key}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={12} className="metrics-table">
                <table>
                    <thead>
                    <tr>
                        <th className="color-th">
                            {" "}
                        </th>
                        <th>{" "}</th>
                        {Object.keys(tableMetrics).map((metricKey, index) => (
                            <th key={index}>
                                <Select
                                    value={tableMetrics[metricKey]}
                                    onChange={(event) => handleTableMetricChange(event, metricKey)}
                                    sx={{
                                        color: "black",
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        padding: "0",
                                        border: "none",
                                        fontWeight: 600,
                                        height: "28px",
                                        minWidth: "auto",
                                        background: "transparent",
                                        "& .MuiSelect-icon": {
                                            color: "#BAB7B7",
                                        },
                                        "& .MuiSelect-select": {
                                            paddingBottom: '0',
                                            paddingTop: '0',
                                        },
                                        "& fieldset": {
                                            border: "none",
                                            padding: 0
                                        },
                                    }}
                                >
                                    {Object.entries(DeviceAvailableMetrics).map(([metric, label]) => (
                                        <MenuItem
                                            key={metric}
                                            value={metric}
                                            disabled={Object.values(tableMetrics).includes(metric) && tableMetrics[metricKey] !== metric}
                                            sx={{
                                                fontSize: "12px",
                                                padding: "4px 8px",
                                                lineHeight: "1.2",
                                                fontFamily: "Inter"
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.length > 0 ? (
                        tableData.map((row, index) => (
                            <tr key={index}>
                                <td className="color-td">
                                    <span
                                        className="device-color"
                                        style={{ backgroundColor: row.color }}
                                    ></span>
                                </td>
                                <td className="first-td">
                                    {
                                        row.name === "Desktop" &&
                                            <img src={DmpIcons.DeviceIcons.DesktopIcon}
                                                 alt="Desktop logo"
                                                 className="iconDevice desktop"
                                            />
                                    }
                                    {
                                        row.name === "Mobile" &&
                                            <img src={DmpIcons.DeviceIcons.SmartphoneIcon}
                                                 alt="Smartphone logo"
                                                 className="iconDevice"
                                            />
                                    }
                                    {
                                        row.name === "Tablet" &&
                                            <img src={DmpIcons.DeviceIcons.TabletIcon}
                                                 alt="TabletIcon logo"
                                                 className="iconDevice tablet"
                                            />
                                    }
                                    {row.name}
                                </td>
                                <td align="right"><span>{row.metric1}</span></td>
                                <td align="right"><span>{row.metric2}</span></td>
                                <td align="right"><span>{row.metric3}</span></td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td align="center" className="color-td">
                                {" "}
                            </td>
                            <td colSpan={tableData.length + 1} align="center" className="no-data">
                                No data available
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    </Grid>
);

export default TableDevice;