import { useEffect } from "react";
import {APP_ENABLE_ZENDESK_CHAT, APP_ZENDESK_CHAT_SCRIPT} from "../../constants/api_urls";

const ZendeskChat = () => {
    const isEnabled = APP_ENABLE_ZENDESK_CHAT === "true";
    const scriptUrl = APP_ZENDESK_CHAT_SCRIPT;
    useEffect(() => {
        if (isEnabled && scriptUrl) {
            const script = document.createElement("script");
            script.id = "ze-snippet";
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isEnabled, scriptUrl]);

    return null;
};
export default ZendeskChat;