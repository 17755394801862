import { combineReducers, configureStore } from "@reduxjs/toolkit";
import calenderSlice from "./slices/calender/calenderSlice";
import metaSearchSlice from "./slices/metaSearch/metaSearch";
import accountSlice from "./slices/account/accountSlice";
import searchAdsSlice from "./slices/searchAds/searchAds";
import sidebarSlice from "./slices/sidebar/sidebar";
import editBidSlice from "./slices/editBid/editBid";
import pMaxSlice from "./slices/pMax/pMax";

const reducers = combineReducers({
  calender: calenderSlice,
  metaSearch: metaSearchSlice,
  account: accountSlice,
  searchAds: searchAdsSlice,
  sidebar: sidebarSlice,
  editBid: editBidSlice,
  pMax: pMaxSlice,
});
export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
