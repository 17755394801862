import React, {useCallback, useEffect, useState} from "react";
import AlertTitle from "features/alert-center/AlertTitle/index";
import AlertSelectionForm from "features/alert-center/AlertSelectionForm/index";
import "./AlertTable.scss";
import AlertManager from "../AlertForm/AlertManager";
import AlertActionMenu from "features/alert-center/AlertActionMenu";
import SlidingPanel from "../AlertForm/SlidingPanel/index";
import {formatAlertData} from "../Functions";
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";
import DmpIcons from "components/DmpIcons/icons";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertTableDesigned from "./AlertTableDesigned";

const AlertTable = ({
  idaccount,
  columns,
  jwtToken,
  menuVisible,
  menuPosition,
  alertData,
  onCloseMenu,
  menuRef,
}) => {
  const [alerts, setAlerts] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isAlertFormVisible, setIsAlertFormVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(menuVisible);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handlePauseAlert = async (result) => {
    const newStatus =
      alertData.status.toUpperCase() === "PAUSED" ? "ENABLED" : "PAUSED";
    showSnackbar(
      `Alert ${newStatus === "PAUSED" ? "paused" : "activated"} with success.`,
      "success"
    );
  };

  const handleTogglePanel = () => {
    setIsEditPanelOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setIsMenuVisible(false);
    if (onCloseMenu) onCloseMenu();
  };

  const handleAlertTypeSelect = (alertType) => {
    setSelectedAlert(alertType);
    setIsAlertFormVisible(false);
  };

  const handleHideAlertForm = () => setIsAlertFormVisible(false);
  const toggleAlertForm = () => setIsAlertFormVisible((prev) => !prev);

  const handleEditAlert = (alertData) => {
    setSelectedAlert(alertData);
    setIsEditPanelOpen(true);
    handleCloseMenu(); // Fermer le menu lorsque l'on édite
  };

  const fetchAlerts = useCallback(async () => {
    try {
      const response = await fetch(
          `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center/list/${idaccount}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des données");
      }

      const data = await response.json();
      const formattedData = formatAlertData(data);
      setAlerts(formattedData);
    } catch (error) {

    }
  }, [idaccount, jwtToken]);

  useEffect(() => {
    fetchAlerts();
  }, [idaccount, fetchAlerts]);

  // Synchroniser l'état local avec la prop menuVisible
  useEffect(() => {
    setIsMenuVisible(menuVisible);
  }, [menuVisible]);

  return (
    <div className="container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="main-content">
        <div className="content">
          <AlertTitle
            onShowAlertForm={toggleAlertForm}
            refreshData={fetchAlerts}
          />
          <div className="alert-table-container">
            <AlertTableDesigned data={alerts} columns={columns} />

            <AlertActionMenu
              show={isMenuVisible}
              position={menuPosition}
              alertData={alertData}
              onEdit={handleEditAlert}
              onPause={handlePauseAlert}
              onDelete={fetchAlerts}
              onClose={handleCloseMenu}
              menuRef={menuRef}
            />
          </div>
        </div>
      </div>
      {isAlertFormVisible && (
        <div className="dropdown-menu">
          <AlertSelectionForm
            onAlertTypeSelect={handleAlertTypeSelect}
            onCancel={handleHideAlertForm}
            refreshData={fetchAlerts}
          />
        </div>
      )}
      <SlidingPanel isOpen={isEditPanelOpen} onClose={handleTogglePanel}>
        <div className="alert-panel-container">
          <div className="alert-form-container">
            <div
              className="panel-header"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div
                  onClick={handleTogglePanel}
                  className="close-panel-circle"
              >
                <DmpIcons.ActionsIcons.ClosePanelFillColorIcon className="close-panel-icon" />
              </div>
              <h2
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: 400,
                  fontFamily: "Quincy CF, serif",
                }}
              >
                Edit - {selectedAlert ? selectedAlert.name : ""}
              </h2>
            </div>
            <div className="platform-container">
              <div
                className={`collapsible-form expanded`}
              >
                <div
                  className={`collapsible-header expanded`}
                >
                  <div
                    className="header"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <h2 className="platform-title">
                      1. UPDATE EXISTING FIELDS
                    </h2>
                  </div>
                </div>
                  <AlertManager
                    onClose={handleTogglePanel}
                    alertData={selectedAlert}
                    actionType={"edit"}
                    showSnackbar={showSnackbar}
                    refreshData={fetchAlerts}
                  />
              </div>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </div>
  );
};

export default AlertTable;
