import React, {useState} from "react";
import {Alert, Box, IconButton, Snackbar, Typography,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SharedBudgetPanel.scss";
import CampaignAccordion
  from "features/google-search-ads-campaign/googleSearchAdsFormComponents/Campaign/CampaignAccordion";
import SharedBudgetTable from "./sharedBudgetTable/SharedBudgetTable";
import axios from "axios";
import {APP_GOOGLE_SEM_API_ENDPOINT} from "constants/api_urls";
import {useSelector} from "react-redux";
import EditBudgetForm, {initialMonthlyBudget,} from "./EditBudgetForm/EditBudgetForm";
import CampaignSelection from "./CampaignSelection/CampaignSelection";
import {ExpandMore} from "@mui/icons-material";
import CustomButtonWithIconComponent from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";
import CreateBudgetPanel from "../CreateBudgetPanel/CreateBudgetPanel";
import {getUserToken} from "../../../utils/functions/userToken";

const SharedBudgetPanel = ({ isOpen, onClose }) => {
  const [showToast, setShowToast] = useState(false);
  const [showCreateBudgetPanel, setShowCreateBudgetPanel] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [data, setData] = useState([]);
  const [setShowCreate] = useState(false);
  const [payload, setPayload] = useState({});
  const [campaigns, setCampaigns] = useState({});
  const [editPayload, setEditPayload] = useState({
    shared_budget_name: "",
    budget: {
      ...initialMonthlyBudget,
      is_daily_budget: false,
      default_budget: 0,
    },
  });
  const [editedRow, setEditedRow] = useState(null);

  const token = getUserToken();
  const accountId = useSelector((state) => state?.account?.account?.account_id);

  const getBudgetList = () => {
    if (accountId) {
      axios
        .get(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/list/?account_id=${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("Required Response" + res);
          setData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getCampaigns = () => {
    axios
      .get(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/details/?shared_budget_id=${editedRow?.shared_budget_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCampaigns({
          campaign_list: res?.data?.campaign_list,
          campaign_movable: res?.data?.campaign_movable,
        });
      })
      .catch((err) => {
        console.error(err?.message);
      });
  };

  React.useEffect(() => {
      if(isOpen){
        getBudgetList();
      }
    }, [accountId, isOpen]);

  const handleAddToBudget = (finalCampaign) => {
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/edit-campaign-list/`,
        {
          shared_budget_id: editedRow?.shared_budget_id,
          campaign_list: finalCampaign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setToastMessage("Campaign added successfully.");
        setToastType("success");
        setShowToast(true);
        getCampaigns();
      })
      .catch((err) => {
        console.error(err?.message);
      });
  };

  const handleRemoveCampaign = (finalCampaign) => {
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/edit-campaign-list/`,
        {
          shared_budget_id: editedRow?.shared_budget_id,
          campaign_list: finalCampaign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setToastMessage("Campaign removed successfully.");
        setToastType("success");
        setShowToast(true);
        getCampaigns();
      })
      .catch((err) => {
        console.error(err?.message);
      });
  };

  const handleSave = () => {
    if (payload?.shared_budget_name && payload?.hotel_id) {
      axios
        .put(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/add/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setPayload({});
          getBudgetList();
          setToastMessage("Shared budget created successfully.");
          setToastType("success");
          setShowToast(true);
          setShowCreate(false);
          setShowCreateBudgetPanel(false);
        })
        .catch((err) => {
          console.error(err.message);
          setToastMessage(err.message || "Something went wrong");
          setToastType("error");
          setShowToast(true);
        });
    } else {
      if (!payload?.shared_budget_name && !payload?.hotel_id) {
        setToastMessage("Please enter both the fields.");
        setToastType("error");
        setShowToast(true);
        return;
      }

      if (!payload?.shared_budget_name) {
        setToastMessage("Please enter shared budget name.");
        setToastType("error");
        setShowToast(true);
        return;
      }

      if (!payload?.hotel_id) {
        setToastMessage("Please select hotel to proceed.");
        setToastType("error");
        setShowToast(true);
        return;
      }
    }
  };

  const handleEditSave = () => {
    const finalPayload = {
      ...Object.keys(editPayload).reduce((acc, curr) => {
        if (
          curr?.includes("budget_month") ||
          ["is_daily_budget", "default_budget"].includes(curr)
        ) {
          acc["budget"] = {
            ...(acc?.["budget"] || {}),
            [curr]: editPayload?.[curr],
          };
        } else {
          acc[curr] = editPayload?.[curr];
        }

        return acc;
      }, {}),
    };
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/edit-info/`,
        { ...finalPayload, shared_budget_id: editedRow?.shared_budget_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setToastMessage("Shared budget updated successfully.");
        setToastType("success");
        setShowToast(true);
        setEditedRow(null);
        onClose();
        getBudgetList();
      })
      .catch((err) => {
        console.error(err.message);
        setToastMessage(err.message || "Something went wrong");
        setToastType("error");
        setShowToast(true);
      });
  };

  return (
    <>
      {isOpen && !showCreateBudgetPanel && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1100,
          }}
        />
      )}

      {isOpen && showCreateBudgetPanel && (
          <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1102,
              }}
          />
      )}
      <Box className={`editCampaignPanel ${isOpen ? "open" : ""}`}>
        <Box
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={onClose} className="closeButton">
              <CloseIcon fontSize="small" />
            </IconButton>
            <Typography variant="body1" className="title">
              {editedRow ? "Edit Shared Budget Report" : "Shared Budget Report"}
            </Typography>
          </Box>
          {/* Toggle Button */}
          {!editedRow && (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <CustomButtonWithIconComponent
                color="#b0acac"
                hoverColor="#61D5A9"
                width="140px"
                height="30px"
                titleColor="#373F41"
                borderRadius="20px"
                title="Click to create budget"
                icon={DmpIcons.ActionsIcons.AddIconBlack}
                iconBorder="0.5px solid #373F41"
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "14.52px",
                  textAlign: "center",
                }}
                onClick={() => {
                  setShowCreateBudgetPanel(true);
                }}
              >
                Create budget{" "}
              </CustomButtonWithIconComponent>
            </Box>
          )}
        </Box>
        <Box className="content">
          {editedRow && (
            <CampaignAccordion
              expandIcon={<ExpandMore />}
              title="SHARED BUDGET"
              disabled={false}
              onSave={handleEditSave}
              isSaveButton={true}
              isCancelButton={true}
              onCancel={() => {
                setEditedRow(null);
              }}
            >
              <EditBudgetForm
                row={editedRow}
                handleFieldChange={(field, val) => {
                  setEditPayload((prev) => ({ ...prev, [field]: val }));
                }}
                onCampaignLoaded={(campaign) => {
                  setCampaigns(campaign);
                }}
              />
            </CampaignAccordion>
          )}
          {editedRow && (
            <CampaignAccordion
              expandIcon={<ExpandMore />}
              title="CAMPAIGN SELECTION"
              disabled={false}
            >
              <CampaignSelection
                campaigns={campaigns}
                row={editedRow}
                onAddBudget={handleAddToBudget}
                onRemoveCampaign={handleRemoveCampaign}
              />
            </CampaignAccordion>
          )}
          {!editedRow && (
            <CampaignAccordion
              title="SHARED BUDGET REPORT"
              disabled={false}
              expanded={true}
            >
              <SharedBudgetTable
                data={data?.shared_budget_list}
                onEditRow={(row) => {
                  setEditedRow(row);
                  setEditPayload((prev) => ({
                    ...prev,
                    shared_budget_name: row?.shared_budget_name,
                    shared_budget_id: row?.shared_budget_id,
                  }));
                }}
              />
            </CampaignAccordion>
          )}
        </Box>
      </Box>
      <CreateBudgetPanel
          isOpen={showCreateBudgetPanel}
          onClose={() => setShowCreateBudgetPanel(false)}
          data={data}
          payload={payload}
          setPayload={setPayload}
          onSave={handleSave}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
          toastType={toastType}
      />
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={7000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SharedBudgetPanel;
