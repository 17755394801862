import React, { useState } from "react";
import {Box, Grid} from "@mui/material";
import TopMarketsMap from "./TopMarketsMap";
import TopMarketsTable from "./TopMarketsTable";
import worldMap from "./Utils/features.json";
import "./TopMarketsComponent.scss";
import CustomDrawerComponent from "../Utils/CustomDrawerComponent";

function TopMarketsComponent({ selectedOption, handleDropdownChange, topMarketsMetrics = {}, errorMessage = null }) {
    const [marketOption, setMarketOption] = useState("clicks");
    const selectedMarkets = topMarketsMetrics[`top_markets_by_${marketOption}`] || [];
    const isLoading = Object.keys(topMarketsMetrics).length === 0;

    return (
        <Box
            className="top-markets-component"
            position="relative"
            style={{ overflow: "hidden" }}
        >
            <CustomDrawerComponent isLoading={isLoading} errorMessage={errorMessage} />
            <Grid container spacing={2} className={`top-markets-container ${Object.keys(topMarketsMetrics).length === 0 ? 'loading' : ''}`}>
                {/* Map Component */}
                <TopMarketsMap
                    selectedMarkets={selectedMarkets}
                    worldMap={worldMap}
                    selectedOption={`${marketOption}_kpi`}
                    currencySymbol={topMarketsMetrics.currency_symbol}
                />

                {/* Table Component */}
                <TopMarketsTable
                    marketOption={marketOption}
                    selectedMarkets={selectedMarkets}
                    topMarketsMetrics={topMarketsMetrics}
                    selectedOption={selectedOption}
                    handleMarketOptionChange={setMarketOption}
                    handleDropdownChange={handleDropdownChange}
                />
            </Grid>
        </Box>
    );
}

export default TopMarketsComponent;
