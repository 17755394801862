import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => {
  return {
    datePickerContainer: {
      position: "relative",
      height: "40px",
      cursor: "pointer",
    },
    datePicker: {
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center", // marginRight:20,
      marginLeft: "auto",

      width: 250,
      height: 40,
    },
    calender: {
      borderRadius: "10px 0px 10px 10px !important",
      boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.25)",
      position: "absolute",
      zIndex: 1000,
      top: "40px",
      right: "0px",
      "& .rdrDefinedRangesWrapper": {
        display: "none",
      },
      "& .rdrPprevButton": {
        backgroundColor: "transparent",
        border: "1px solid green",
        borderRadius: "50% !important",
        color: "green",
      },
      "& .rdrPprevButton i": {
        borderWidth: "4px 6px 4px 4px",
        borderColor: "transparent green transparent transparent",
      },
      "& .rdrNextButton": {
        backgroundColor: "transparent",
        border: "1px solid green",
        borderRadius: "50% !important",
        color: "green",
      },
      "& .rdrNextButton i": {
        borderWidth: "4px 4px 4px 6px",
        borderColor: "transparent transparent transparent green",
      },
      "& .rdrWeekDays": {
        borderBottom: "1px solid #CADEDD",
        borderWidth: "80%",
      },
      "& .rdrMonthName": {
        fontSize: 14,
        fontWeight: 700,
        color: "#373F41",
      },
      "& .rdrDateRangePickerWrapper": {},

      "& .date-range-picker-container": {
        display: "flex",
        width: 500,
        border: "1px solid #e0e0e0",
        borderRadius: 8,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      },
      "& .filters": {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        borderRight: "1px solid #e0e0e0",
        minWidth: 150,
      },
      "& .filters button": {
        background: "none",
        border: "none",
        padding: "10px 0",
        textAlign: "left",
        cursor: "pointer",
        fontSize: 14,
        color: "#333",
        "&.active": {
          color: "#007bff",
          fontWeight: "bold",
        },
      },
      "& .date-picker": {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        flexGrow: 1,
      },
      "& .date-picker-header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 10,
      },
      "& .compare-with-label": {
        fontSize: 14,
        color: "#333",
      },
      "& .toggle-switch": {
        marginLeft: 10,
      },
      "& .calendar-container": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& .custom-input": {
        background: "#f8f8f8",
        border: "1px solid #ccc",
        padding: 10,
        borderRadius: 5,
        cursor: "pointer",
        width: "100%",
        textAlign: "left",
      },
      "& .apply-cancel-buttons": {
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
        paddingTop: 10,
      },
      "& .apply-button, & .cancel-button": {
        padding: "10px 20px",
        border: "none",
        borderRadius: 5,
        cursor: "pointer",
      },
      "& .apply-button": {
        backgroundColor: "#007bff",
        color: "#fff",
      },
      "& .cancel-button": {
        backgroundColor: "#f8f8f8",
        color: "#333",
      },
      "& .react-datepicker": {
        fontFamily: "Arial, sans-serif",
      },
      "& .react-datepicker__header": {
        backgroundColor: "#fff",
        borderBottom: "1px solid #e6e6e6",
      },
      "& .react-datepicker__current-month": {
        fontSize: "1em",
        marginBottom: "0.5em",
      },
      "& .react-datepicker__day-name, & .react-datepicker__day": {
        width: "2.5em",
        lineHeight: "2.5em",
        margin: "0.166em",
      },
      "& .react-datepicker__day--selected, & .react-datepicker__day--in-range":
        {
          backgroundColor: "#007bff",
          color: "white",
        },
      "& .react-datepicker__day--in-range": {
        backgroundColor: "#85d7ff",
      },
      "& .react-datepicker__day--keyboard-selected": {
        backgroundColor: "#66bb6a",
      },
      "& .react-datepicker__day--today": {
        fontWeight: "bold",
        color: "#007bff",
      },
      "& .rdrDay": {
        height: "2.5em !important",
      },
      "& .rdrMonth": {
        width: "auto !important",
      },
      "& .rdrMonthAndYearWrapper": {
        height: "40px !important",
      },
      "& .rdrInfiniteMonths": {
        height: "396px !important",
        scrollbarWidth: "none",
        "& .rdrMonth": {
          height: "220px !important",
        },
      },
      "& ::-webkit-scrollbar": {
        width: "5px",
      },
      "& ::-webkit-scrollbar-track": {
        background: "rgba(0, 0, 0, 0.1)", 
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "10px", 
      },
    },
    customActions: {
      position: "relative",
      paddingLeft: 16,
      paddingTop: 13,
      height: 490,
      fontSize: 12,
      borderRight: "1px solid #CADEDD",
      paddingRight: 12,
      width: 170,
      backgroundColor: "white",
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    switch: {
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#B9F2DB",
        height: 12,
        width: 26,
      },
      "& .MuiSwitch-track": {
        height: 12,
        width: 26,
        background: "#F5F5F5",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#61D5A9",
        height: 14,
        width: 14,
        marginTop: "2px",
        boxShadow: "none",
      },
    },
    primaryButton: {
      backgroundColor: "#61D5A9",
      borderRadius: 20,
      fontSize: 12,
      fontWeight: 400,
      height: 21,
      color: "black",
      border: "none",
      textTransform: "none",
      marginRight: 10,
    },
    button: {
      borderRadius: 20,
      fontSize: 12,
      fontWeight: 400,
      height: 21,
      color: "black",
      textTransform: "none",
    },
    actionItem: {
      cursor: "pointer",
      fontSize: 12,
      marginBottom: 5,
      lineHeight: 2,
    },
  };
});
