import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  platform: [],
  segment_list: [],
};
export const metaSearchSlice = createSlice({
  name: "metaSearchSlice",
  initialState: initialState,
  reducers: {
    updatePlatform: (state, action) => {
      state.platform = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
    resetMetaSearch: () => initialState
  },
});

export const {
  updatePlatform,
  updateSegmentList,
  resetMetaSearch
} = metaSearchSlice.actions;
export default metaSearchSlice.reducer;
