import axios from "axios";
import { APP_GOOGLE_PMAX_API_ENDPOINT } from "../../../constants/api_urls";
import "./structures/apiResponseStructures";
import {getUserToken} from "../../../utils/functions/userToken";

/**
 * Extracts indexed or global error messages from the API response.
 *
 * @param {Object} errorObj - The error object returned from the API response.
 * @returns {CampaignErrorMessages} An object containing extracted error messages.
 */
export const extractErrorMessages = (errorObj) => {
    const errors = {};

    /**
     * Extracts errors by matching indexed paths in the response.
     *
     * @param {string} section - The section key from the response.
     * @param {string} basePath - The base path pattern to match indices.
     * @returns {Object|string|null} - Indexed errors or a global error message.
     */
    const extractIndexedErrorsByPath = (section, basePath) => {
        const sectionErrors = {};
        const sectionData = errorObj.campaign_ads_creative?.[section];

        if (sectionData?.length) {
            for (const err of sectionData) {
                const match = err.path.match(new RegExp(`${basePath}\\[(\\d+)\\]`));

                if (match) {
                    const index = `index-${match[1]}`;
                    if (!sectionErrors[index]) {
                        sectionErrors[index] = err.errorMessage;
                    }
                } else if (!Object.keys(sectionErrors).length) {
                    return err.errorMessage;
                }
            }
        }
        return Object.keys(sectionErrors).length ? sectionErrors : null;
    };

    /**
     * Extracts the first error message from sections containing lists.
     *
     * @param {string} section - The section key from the response.
     * @returns {Object|null} - Extracted errors by index or `null`.
     */
    const extractFirstErrorsByIndex = (section) => {
        const sectionErrors = {};
        if (errorObj.campaign_ads_creative?.[section]) {
            for (const [index, errList] of Object.entries(errorObj.campaign_ads_creative[section])) {
                if (errList.length) {
                    sectionErrors[index] = errList[0].errorMessage;
                }
            }
        }
        return Object.keys(sectionErrors).length ? sectionErrors : null;
    };

    if (errorObj.campaign_ads_creative?.business_name?.length) {
        errors.businessName = errorObj.campaign_ads_creative.business_name[0].errorMessage;
    }

    if (errorObj.campaign_ads_creative?.final_url?.length) {
        errors.finalUrl = errorObj.campaign_ads_creative.final_url[0].errorMessage;
    }

    errors.logoImage = extractIndexedErrorsByPath("logo_image", "assetsLogoImage");
    errors.landscapeImage = extractIndexedErrorsByPath("marketing_image", "assetsMarketingImage");
    errors.squareImage = extractIndexedErrorsByPath("square_marketing_image", "assetsSquareMarketingImage");
    errors.portraitImage = extractIndexedErrorsByPath("portrait_marketing_image", "assetsPortraitMarketingImage");
    errors.landscapeLogoImage = extractIndexedErrorsByPath("landscape_logo_image", "assetsLandscapeLogoImage");

    errors.headline = extractFirstErrorsByIndex("headline_list") || extractIndexedErrorsByPath("headline", "assetsHeadline");
    errors.description = extractFirstErrorsByIndex("description_list") || extractIndexedErrorsByPath("description", "assetsDescription");
    errors.longHeadline = extractFirstErrorsByIndex("long_headline_list") || extractIndexedErrorsByPath("long_headline", "assetsLongHeadline");

    return errors;
};

/**
 * Creates a new Performance Max campaign using the provided settings.
 *
 * @async
 * @function createPMaxCampaign
 * @param {Object} params - The parameters required to create the campaign.
 * @param {string} params.accountId - The account ID.
 * @param {string} params.hotelId - The hotel ID.
 * @param {Object} params.campaignSettings - The campaign settings configuration.
 * @param {Object} params.campaignAdsCreative - The campaign ads creative content.
 * @returns {Promise<CampaignResponse>} The result of the campaign creation process.
 */
export const createPMaxCampaign = async ({
    accountId,
    hotelId,
    campaignSettings,
    campaignAdsCreative,
}) => {
    const jwtToken = getUserToken();

    try {
        const response = await axios.put(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/add-campaign/`,
            {
                account_id: accountId,
                hotel_id: hotelId,
                campaign_settings: campaignSettings,
                campaign_ads_creative: campaignAdsCreative,
            },
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errors = extractErrorMessages(error?.response?.data || {});
        return { success: false, errors };
    }
};
