import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import "./CampaignAccordion.scss";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";

const CampaignAccordion = ({
  title,
  children,
  expanded,
  onChange,
  onNext = false,
  disabled,
  visited,
  isCreateButton = false,
  isSaveButton = false,
  onSave = false,
  isCancelButton = false,
  expandIcon,
  onCancel = () => {},
}) => {
  return (
    <Accordion
      className="search-ads-accordion"
      expanded={expanded}
      defaultExpanded
      onChange={!disabled ? onChange : null}
      sx={{
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <AccordionSummary
        className="accordion-summary"
        expandIcon={expandIcon ?? null}
        sx={{
          height: "42px",
          maxHeight: "42px",
          minHeight: "42px",
          backgroundColor: visited && !expanded ? "#61D5A9" : "inherit",
          borderBottom: expanded ? "1px solid #61D5A9" : "none",
          cursor: disabled ? "default" : "pointer",
          color: visited && !expanded ? "black" : "inherit",
          "&.Mui-expanded": {
            height: "42px",
            maxHeight: "42px",
            minHeight: "42px",
          },
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className="accordion-details"
        sx={{
          backgroundColor: "#FAFAFA",
        }}
      >
        {children}
        {
          <Box
            display="flex"
            gap="15px"
            justifyContent="flex-start"
            alignItems="center"
          >
            {onNext && (
              <CustomButtonWithIcon
                color="#b0acac"
                hoverColor="#12794F"
                titleColor="#373F41"
                hoverTitleColor={"white"}
                width={"120px"}
                height={"24px"}
                borderRadius="20px"
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "14.52px",
                  textAlign: "center",
                }}
                onClick={onNext}
              >
                {isCreateButton ? "Create" : "Next"}
              </CustomButtonWithIcon>
            )}
            {isSaveButton && (
              <CustomButtonWithIcon
                color="#b0acac"
                hoverColor="#12794F"
                titleColor="#373F41"
                hoverTitleColor={"white"}
                width={"120px"}
                height={"24px"}
                borderRadius="20px"
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "14.52px",
                  textAlign: "center",
                }}
                onClick={onSave}
              >
                Save
              </CustomButtonWithIcon>
            )}
            {isCancelButton && (
              <CustomButtonWithIcon
                color="#b0acac"
                hoverColor="#12794F"
                titleColor="#373F41"
                hoverTitleColor={"white"}
                width={"120px"}
                height={"24px"}
                borderRadius="20px"
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "14.52px",
                  textAlign: "center",
                }}
                onClick={onCancel}
              >
                Cancel
              </CustomButtonWithIcon>
            )}
          </Box>
        }
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignAccordion;
