import React, {useState} from 'react';
import SlidingPanel from "../SlidingPanel";
import "./index.scss";
import Icons from "components/DmpIcons/icons";
import TableDesigned from "./TableDesigned";
import DmpIcons from "components/DmpIcons/icons";

const HotelListPanel = ({ open, handleClose, hotels, groups, hotelColumns, groupColumns, backdropDisabled }) => {
    const [isHotelsOpen, setIsHotelsOpen] = useState(true);
    const [isGroupsOpen, setIsGroupsOpen] = useState(false);

    const toggleHotels = () => {
        setIsHotelsOpen(!isHotelsOpen);
    };

    const toggleGroups = () => {
        setIsGroupsOpen(!isGroupsOpen);
    };

    return (
        <SlidingPanel
            isOpen={open}
            size={35}
            onClose={handleClose}
            backdropDisabled={backdropDisabled}
        >
            <div className="bidding-panel-container">
                <div className="platform-container" style={{ width: '100%' }}>
                    <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div
                            onClick={handleClose}
                            className="close-panel-circle"
                        >
                            <DmpIcons.ActionsIcons.ClosePanelFillColorIcon className="close-panel-icon" />
                        </div>
                        <h2 style={{
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 400,
                            fontFamily: 'Quincy CF, serif',
                        }}>
                            Linked Hotels
                        </h2>
                    </div>
                    <div className="hotels-and-groups-list">
                        <div className="hotel-collapsible-section">
                            <div className={`hotel-collapsible-header ${isHotelsOpen ? 'expanded' : ''}`} onClick={toggleHotels}>
                                <div className="hotel-title">LINKED HOTELS</div>
                                <span className="arrow-icon">
                                        <img
                                            src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                            alt="Arrow Down"
                                            className={isHotelsOpen ? 'rotate-up' : 'rotate-down'}
                                        />
                                </span>
                            </div>
                            {isHotelsOpen && (
                                <div className="hotel-collapsible-content">
                                    <TableDesigned columns={hotelColumns} data={hotels} />
                                </div>
                            )}
                        </div>
                        <div className="hotel-collapsible-section">
                            <div className={`hotel-collapsible-header ${isGroupsOpen ? 'expanded' : ''}`} onClick={toggleGroups}>
                                <div className="hotel-title">LINKED GROUPS</div>
                                <span className="arrow-icon">
                                        <img
                                            src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                            alt="Arrow Down"
                                            className={isGroupsOpen ? 'rotate-up' : 'rotate-down'}
                                        />
                                </span>
                            </div>
                            {isGroupsOpen && (
                                <div className="hotel-collapsible-content">
                                    <TableDesigned columns={groupColumns} data={groups} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SlidingPanel>
    );
};

export default React.memo(HotelListPanel);
